import React, { useEffect } from "react";
import { Hero, Listings, Subscribe } from "../sections";
import { useDispatch } from "react-redux"; 
import {setSearchTerm} from "../store/slices/searchTerm"
import BlogSection from "../sections/BlogSection";

const Homepage = () => {
    const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchTerm(""))
  },[])

  return (
    <main>
      <section className="xl:padding-1 wide:padding-r padding-b">
        <Hero />
      </section>
      <section className="padding-x py-10">
        <Listings />
      </section>
      <section>
        <BlogSection />
      </section>
      <section className="padding-x py-16 sm:py-32  w-full">
        <Subscribe />
      </section>
    </main>
  );
};

export default Homepage;
