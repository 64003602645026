import React, { useEffect, useState } from "react";
import { ard_working } from "../assets/images";
import { Formik, Form, Field } from "formik";
import { defaultCountry } from "../constants";
import { format } from "date-fns";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createJobListing } from "../store/slices/jobListingSlice";
import {
  BorderlessTextInput,
  Select,
  CustomDatePicker,
  NumberFormatCustom,
  DropdownCheckbox,
  QuillEditor,
} from "../components/customFormikEle";
import { toast } from "react-toastify";
import useCloudinaryImageUpload from "../hooks/useImageUpload";
import { CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useNavigate } from "react-router";

const PostJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Terms & Condition
  const [isChecked, setIsChecked] = useState(false);

  // Handle Dropdown checkbox state
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [countryid, setCountryid] = useState(
    defaultCountry ? defaultCountry.id : 0
  );
  const [stateid, setstateid] = useState(0);
  const [countryObject, setCountryObject] = useState("");
  const [stateObject, setStateObject] = useState("");

  // ...
  const { uploadToCloudinary, url, fileName, setFileName } =
    useCloudinaryImageUpload();

  // ...
  const searchTerm = useSelector((state) => state.searchTerm);
  const state = useSelector((state) => state.jobListing);
  const company = useSelector((state) => state?.company?.company);

  // Remove the currency symbol (₦) and any commas
  function currencyStringToNumber(currencyString) {
    const numberString = currencyString.replace(/₦|,/g, "");
    return numberString;
  }

  const skillSetOptions = [
    { key: "Communication", value: "communication" },
    { key: "Leadership", value: "leadership" },
    { key: "Problem Solving", value: "problemSolving" },
    { key: "Teamwork", value: "teamwork" },
    { key: "Creativity", value: "creativity" },
    { key: "Adaptability", value: "adaptability" },
    { key: "Work Ethic", value: "workEthic" },
    { key: "Time Management", value: "timeManagement" },
    { key: "Attention to Detail", value: "attentionToDetail" },
    { key: "Interpersonal Skills", value: "interpersonalSkills" },
    { key: "Technical Knowledge", value: "technicalKnowledge" },
    { key: "Programming", value: "programming" },
    { key: "MS Office", value: "microsoft_office" },
    { key: "Graphics Design", value: "graphics_design" },
    { key: "Social Media Management", value: "social_media_management" },
  ];

  const filteredSkillSet = skillSetOptions.filter((skill) =>
    skill.key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Initial Formik values
  const initialValues = {
    company_id: "",
    job_title: "",
    max_salary_range: "",
    min_salary_range: "",
    description: "",
    image_url: "",
    education_level: "",
    position: "",
    experience: "",
    skillset: [],
    country: "",
    state: "",
    due_date: "",
  };

  const handleImageUpload = async () => {
    const transformation = [{ width: 280, height: 280, crop: "fill" }];
    await uploadToCloudinary(transformation);
  };

  // Validation Schema
  const validationSchema = Yup.object({
    job_title: Yup.string().required("Job title is required"),
    min_salary_range: Yup.string().required("Salary range is required"),
    description: Yup.string().required("Job description is required"),
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Navigate to Applications after posting a job
  function navigateToApplications() {
    navigate("/user/applications");
  }

  // Handle Form Submittion
  const onSubmit = async (value, { resetForm, setSubmitting }) => {
    if (!isChecked) {
      return toast.error("Please accept terms and conditions");
    }

    if (!stateObject) {
      return toast.error("Please select state");
    }
    const values = {
      ...value,
      min_salary_range: currencyStringToNumber(value.min_salary_range),
      max_salary_range: currencyStringToNumber(value.max_salary_range),
      due_date: format(value.due_date, "yyyy-MM-dd'T00:00:00.000Z'"),
      state: stateObject,
      country: countryObject,
      skillset: value.skillset.join(","),
      company_id: company?.company?.id,
      image_url: url ? url : company?.company?.logo, //image upload else company's logo
    };

    // check if there is internet connection
    if (!navigator.onLine) {
      toast.error("No internet connection!");
    }

    const callBack = {
      resetForm,
      navigateToApplications,
    };

    company.company.id && dispatch(createJobListing({ values, callBack }));

    setFileName(null);
    setSubmitting(false);
    setStateObject("");
    setstateid(0);
    setIsChecked(false);
  };

  useEffect(() => {}, [url, fileName]);

  return (
    <div className="w-full h-full flex items-start pt-32 max-sm:flex-col">
      <div className="relative w-1/2 h-screen max-sm:w-full max-sm:h-[100px]">
        <img src={ard_working} className="w-full h-full object-cover" alt="" />
      </div>

      <div className="w-1/2 h-full bg-veryLightGray flex flex-col p-20 justify-between items-center max-sm:p-4 max-sm:w-full">
        <div className="w-full flex flex-col max-w-[500px]">
          <div className="w-full flex flex-col mb-2">
            <h3 className="text-3xl font-semibold font-raleway mb-2">
              Post A job
            </h3>
            <p className="text-basetext-sm font-raleway text-slate-gray mb-2">
              Please complete the form below.
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-full flex flex-col">
                  <BorderlessTextInput
                    name="job_title"
                    placeholder="Job Title"
                  />
                  <div class="grid grid-cols-2 gap-5 mb-4">
                    <Field
                      name="min_salary_range"
                      component={NumberFormatCustom}
                      placeholder="Minimum Salary"
                      type="text"
                      className="border-b bg-transparent mt-2 border-gray-500 py-2 focus:outline-none"
                    />

                    <Field
                      name="max_salary_range"
                      component={NumberFormatCustom}
                      placeholder="Maximum Salary"
                      type="text"
                      className="border-b  bg-transparent mt-2 border-gray-500 py-2 focus:outline-none"
                    />
                  </div>

                  <QuillEditor name="description" label="Description of Job" />

                  <CustomDatePicker
                    name={"due_date"}
                    placeholder={"Application Deadline"}
                  />

                  <div className="flex gap-10 w-full justify justify-between my-3 mt-5">
                    <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3">
                      Vacancy Location:
                    </label>
                    <div className="w-full">
                      <CountrySelect
                        defaultValue={defaultCountry}
                        autoComplete="false"
                        inputClassName="focus:outline-none"
                        containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md "
                        onChange={(e) => {
                          setCountryid(e.id);
                          setCountryObject(e.name);
                        }}
                        placeHolder="Select Country"
                      />

                      <StateSelect
                        inputClassName="focus:outline-none"
                        containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md"
                        autoComplete="false"
                        countryid={countryid}
                        onChange={(e) => {
                          setstateid(e.id);
                          setStateObject(e.name);
                        }}
                        placeHolder="Select State"
                      />
                    </div>
                  </div>

                  <div className="flex gap-5 w-full justify justify-between my-5 items-center">
                    <label className="font-raleway mt-3 text-slate-gray font-semibold">
                      Preferred years
                      <br /> of Experience
                    </label>
                    <Select
                      name="experience"
                      defaultValue={
                        "Select the primary industry of your company"
                      }
                      className="border p-3 w-full"
                      required
                    >
                      <option value="">--Select one--</option>
                      <option value="entry">{"Entry Level (0-2 Years)"}</option>
                      <option value="mid">{"Mid-Level (3-5 Years)"}</option>
                      <option value="senior">
                        {"Senior Level (6+ Years)"}
                      </option>
                    </Select>
                  </div>

                  <div className="flex gap-5 w-full justify justify-between mb-5 items-center">
                    <label className="font-raleway mt-3 text-slate-gray font-semibold">
                      Preferred <br /> Education Level
                    </label>
                    <Select
                      className={"w-full"}
                      name="education_level"
                      defaultValue={
                        "Select the primary industry of your company"
                      }
                      required
                    >
                      <option value="">--Select one--</option>
                      <option className="mt-5" value="high_school">
                        High School
                      </option>
                      <option value="diploma">Diploma</option>
                      <option value="bachelors">Bachelor's Degree</option>
                      <option value="masters">Master's Degree</option>
                      <option value="doctorate">Doctorate Degree</option>
                    </Select>
                  </div>

                  <div className="flex gap-5 w-full justify justify-between mb-5">
                    <label className="font-raleway mt-3 text-slate-gray font-semibold">
                      Positions you hire
                    </label>
                    <Select
                      name="position"
                      defaultValue={
                        "Select the primary industry of your company"
                      }
                      className="border p-3 w-full"
                      id=""
                      required
                    >
                      <option value="">--Select one--</option>
                      <option className="mt-5" value="full_time">
                        Full-time
                      </option>
                      <option value="part_time">Part-time</option>
                      <option value="internship">Internship</option>
                      <option value="freelance">Freelance/Gig</option>
                      <option value="hybrid">{"Two or more types"}</option>
                    </Select>
                  </div>

                  <div className="flex gap-5 w-full justify justify-between mb-5">
                    <label className="font-raleway mt-3 text-slate-gray font-semibold">
                      Preferred Skillset
                    </label>
                    <div className="w-2/3 max-sm:w-full flex flex-col">
                      <div className="w-2/3  max-sm:w-full">
                        <DropdownCheckbox
                          label="Select Three (3) Options only"
                          name="skillset"
                          options={filteredSkillSet}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          toggleDropdown={toggleDropdown}
                          isOpen={isOpen}
                          search={true}
                        />
                      </div>
                    </div>
                  </div>

                  {/* ... */}
                  <div className="w-full flex justify-end mb-4">
                    {fileName ? (
                      <div className="w-2/3 text-slate-gray text-sm">
                        <p>Image succesfully uploaded</p>
                        <p className="p-4 rounded border bg-white  flex justify-between">
                          {fileName}
                          <span
                            onClick={() => setFileName(null)}
                            className="text-red-500 text-md cursor-pointer font-semibold"
                          >
                            x
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full items-end">
                        <button
                          type="button"
                          disabled={state.loading || isSubmitting}
                          onClick={handleImageUpload}
                          className={`w-2/3 max-sm:2/3 hover:bg-[#FCDC5A] text-white my-2 font-semibold font-raleway bg-gold
                        rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                        >
                          Upload Image
                        </button>

                        <span className="text-gray-400 text-sm">
                          NB: if image not provided, profile image will be used
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full flex items-center justify-between">
                  <div className="w-full flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 mr-2"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <p className="text-sm">I accept Terms and Conditions</p>
                  </div>
                </div>

                <div className="w-full flex flex-col my-4">
                  <button
                    disabled={state.loading || isSubmitting}
                    type="submit"
                    className={`w-full text-white my-2 font-semibold font-raleway ${
                      state.loading || isSubmitting
                        ? "bg-gray-300"
                        : "bg-[#060606]"
                    } rounded-md p-4 text-center hover:bg-[#706128] flex items-center justify-center cursor-pointer`}
                  >
                    {state.loading || isSubmitting ? "Submitting.." : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/*  </form>*/}

        {/*<div className="w-full flex items-center justify-center">
          <p className="text-sm font-raleway font-normal text-slate-gray">
            Dont't have an account?{" "}
            <span className="font-semibold underline underline-offset-2 cursor-pointer">
              Sign up for free!
            </span>
          </p>
                  </div>*/}
      </div>
    </div>
  );
};

export default PostJob;
