import React, { useEffect, useState } from "react";
import temp_Logo from "../assets/images/customer1.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { defaultCountry } from "../constants";
import { updateCompany } from "../store/slices/companies";
import { Formik, Form } from "formik";
import { BorderTextInput, Radio, Select, QuillEditor } from "./customFormikEle";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import useCloudinaryImageUpload from "../hooks/useImageUpload";
import { toast } from "react-toastify";

const CompanyProfileDetails = () => {
  const { uploadToCloudinary } = useCloudinaryImageUpload();
  const [countryid, setCountryid] = useState(
    defaultCountry ? defaultCountry.id : 0
  );
  const [stateid, setstateid] = useState(0);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.company?.loading);
  const companyState = useSelector((state) => state?.company?.company);
  let id,
    email,
    phone_numbers,
    website,
    state,
    country,
    city,
    description,
    industry,
    size,
    logo,
    instagram,
    linkedin,
    tiktok,
    twitter,
    other_industry;
  if (companyState && companyState.company) {
    ({
      email,
      id,
      phone_numbers,
      website,
      state,
      country,
      city,
      description,
      industry,
      size,
      logo,
      instagram,
      linkedin,
      tiktok,
      twitter,
      other_industry,
    } = companyState.company);
  }

  const initialCompanyValue = {
    phone_numbers,
    website,
    size,
    state,
    country,
    city,
    description,
    industry,
    logo,
    other_industry,
    instagram,
    linkedin,
    tiktok,
    twitter,
  };

  // setting the initial url of the logo from what we have in the database
  const [url, setUrl] = useState(logo);

  // handle profile image upload
  const handleImageUpload = async () => {
    const transformation = [{ width: 100, height: 100, crop: "fill" }];
    const url = await uploadToCloudinary(transformation);
    setUrl(url);
    dispatch(updateCompany({ value: { logo: url }, id }));
  };

  // initilaize the country, state and city objects
  const [countryObject, setCountryObject] = useState(country ? country : "");
  const [stateObject, setStateObject] = useState(state ? state : "");
  const [cityObject, setCityObject] = useState(city ? city : "");
  const onSubmit = (values) => {
    if (!countryObject) {
      setCountryObject(defaultCountry.name);
    }
    if (!stateObject || !cityObject) {
      toast.error("Please select a  state and city");
      return;
    }
    const value = {
      ...values,
      country: countryObject,
      state: stateObject,
      city: cityObject,
      logo: url,
      is_profile_complete: true,
    };

    dispatch(updateCompany({ value, id }));
  };
  useEffect(() => {}, [companyState?.company]);

  return (
    <Formik initialValues={initialCompanyValue} onSubmit={onSubmit}>
      {({ values }) => (
        <Form autoComplete="off" className="max-sm:flex-col">
          <div className="p-10 max-sm:p-2 max-sm:flex-col flex-col __joyride_update_profile relative">
            <div className="font-raleway flex gap-10 max-sm:flex-col justify-between">
              <div className="w-1/2 max-sm:w-full rounded-md bg-white p-10  max-sm:p-4">
                <div className="flex gap-5  max-sm:gap-2 max-sm:mb-4 w-full max-sm:flex-col justify justify-between mb-10">
                  <img
                    width={100}
                    height={100}
                    src={url ? url : temp_Logo}
                    className="rounded-full"
                    alt="userAvatar"
                  />
                  <span>
                    <button
                      onClick={handleImageUpload}
                      type="button"
                      className="p-4 my-6 rounded-md bg-gold hover:bg-gray-400 text-white"
                    >
                      Edit Company Logo
                    </button>
                  </span>
                </div>
                <div className="flex gap-5  max-sm:flex-col w-full mb-5">
                  <label className="font-raleway  text-slate-gray font-semibold w-1/3  max-sm:w-full">
                    Legal Business Name:
                  </label>
                  <p className="w-2/3  max-sm:w-full">
                    {companyState?.company?.company_name || ""}
                  </p>
                </div>
                <div className="flex gap-5 w-full  max-sm:flex-col justify justify-between mb-5">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3  max-sm:w-full">
                    Contact Information:
                  </label>
                  <div className="w-2/3  max-sm:w-full flex flex-col">
                    <p className="mt-3">{email}</p>

                    <BorderTextInput
                      required
                      type="phone"
                      placeholder="Phone Number"
                      name="phone_numbers"
                    />

                    <BorderTextInput
                      placeholder="Company Website"
                      name="website"
                    />
                  </div>
                </div>
                <div className="flex gap-5 w-full justify justify-between  max-sm:flex-col  mb-5">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3">
                    Industry
                  </label>
                  <Select
                    name="industry"
                    defaultValue={"Select the primary industry of your company"}
                    className=" border p-3 w-full"
                    id="select-industry"
                    required
                  >
                    <option value="">--Select one--</option>
                    <option className="mt-5" value="technology">
                      Technology
                    </option>
                    <option value="education">Education</option>
                    <option value="fashion">Fashion</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="finance">Finance</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="other">{"Other(Please specify)"}</option>
                  </Select>
                </div>
                <div className="flex w-full max-sm:flex-col justify-end">
                  <div className="w-2/3 max-sm:w-full">
                    {values.industry === "other" && (
                      <BorderTextInput
                        placeholder="Specify other industry"
                        name="other_industry"
                      />
                    )}
                  </div>
                </div>

                <div className="flex gap-5 w-full justify justify-between mb-5  max-sm:flex-col ">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold">
                    Company Size
                  </label>
                  <div className="w-2/3  max-sm:w-full justify-between flex-col">
                    <div className="m-3 w-full ml-3 flex">
                      <Radio
                        name="size"
                        type="radio"
                        value="small"
                        className="rounded-sm border p-3 mb-2 mr-4"
                        required
                      />
                      <label htmlFor="">Small (1 - 50 Employees) </label>
                    </div>
                    <div className="m-3 w-full ml-3 flex">
                      <Radio
                        name="size"
                        type="radio"
                        value="medium"
                        className="rounded-sm border p-3 mb-2 mr-4"
                      />
                      <label htmlFor="">Medium (51 - 500 Employees) </label>
                    </div>
                    <div className="m-3 w-full ml-3 flex">
                      <Radio
                        name="size"
                        type="radio"
                        value="large"
                        className="rounded-sm border p-3 mb-2 mr-4"
                      />
                      <label htmlFor="">Large (501+ Employees) </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2  max-sm:w-full max-sm:p-4 rounded-md bg-white p-10">
                <h1 className="text-lg font-bold mt-10 mb-5 font-raleway text-slate-gray">
                  About your company
                </h1>
                <div className="flex gap-5 w-full justify justify-between  max-sm:flex-col mb-5">
                  <div className="px-3 w-full">
                    <QuillEditor
                      name={"description"}
                      label={"Brief summary about your company/business:"}
                      required
                    />
                  </div>
                </div>

                <div className="flex gap-10 w-full  max-sm:flex-col justify justify-between mb-5">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3">
                    Company Location:
                  </label>
                  <div className="w-full">
                    <CountrySelect
                      autoComplete="false"
                      defaultValue={defaultCountry}
                      inputClassName="focus:outline-gold"
                      containerClassName="w-full text-gray-800 mb-4 rounded-md"
                      onChange={(e) => {
                        setCountryid(e.id);
                        setCountryObject(e.name);
                      }}
                      placeHolder={`${country ? country : "Select Country"}`}
                    />

                    <StateSelect
                      inputClassName="focus:outline-gold"
                      autoComplete="false"
                      containerClassName="w-full text-gray-800 mb-4 rounded-md"
                      countryid={countryid}
                      onChange={(e) => {
                        setstateid(e.id);
                        setStateObject(e.name);
                      }}
                      placeHolder={`${state ? state : "Select State"}`}
                    />

                    <CitySelect
                      autoComplete="false"
                      inputClassName="focus:outline-gold"
                      containerClassName="w-full text-gray-800 mb-4 rounded-md"
                      countryid={countryid}
                      stateid={stateid}
                      onChange={(e) => {
                        setCityObject(e.name);
                      }}
                      placeHolder={`${city ? city : "Select City"}`}
                    />
                  </div>
                </div>
                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5  max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3 max-sm:w-full">
                    Social Media Handles:
                  </label>
                  <div className="w-2/3 flex flex-col max-sm:w-full">
                    <BorderTextInput
                      placeholder="LinkedIn (Optional)"
                      name="linkedin"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Twitter (X) (Optional)"
                      name="twitter"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Instagram (Optional)"
                      name="instagram"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Tiktok (Optional)"
                      name="tiktok"
                      required={false}
                    />
                  </div>
                </div>
              </div>

              <div className="flex max-sm:flex-end lg:hidden md:hidden justify-between">
                {/*<h1 className="text-lg font-bold mb-10 font-raleway text-slate-gray"></h1>*/}
                <button
                  className=" __joyride_update_profile_btn border max-sm:flex-end p-3 mb-5 hover:bg-gray-400 hover:text-black text-white font-raleway bg-gold rounded-md"
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div className="max-sm:hidden absolute bottom-[-45px] right-0 px-10 ">
              <button
                className=" __joyride_update_profile_btn border max-sm:flex-end p-4 mb-5 hover:bg-gold hover:text-white font-raleway bg-gray-300 rounded-md mt-4 w-[400px]"
                type="submit"
              >
                {`${loading ? "Saving Changes..." : "Save Changes"}`}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyProfileDetails;
