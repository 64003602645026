import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail, requestNewToken } from "../store/slices/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import OTPInput from "react-otp-input";
import { FaExclamationTriangle } from "react-icons/fa";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const otpRef = useRef(null);
  const location = useLocation();
  const password = location.state.password;
  const email = location.state.email;
  // Countdown key state
  const [countdownKey, setCountdownKey] = useState(0);

  // Initialize the initialDate state with null
  const [initialDate, setInitialDate] = useState(null);

  // Function to request a new OTP when timer runs out
  const requestNewOtp = () => {
    const email_obj = { email: email };
    dispatch(requestNewToken(email_obj));
    setCountdownKey((prevKey) => prevKey + 1);
    // restart the count down
    setInitialDate(Date.now() + 60 * 1000);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <button
          className="text-blue-500 hover:text-blue-700"
          onClick={requestNewOtp}
        >
          request a new OTP
        </button>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  // count down memo
  const CountdownMemo = React.memo(({ countdownKey, renderer, date }) => (
    <Countdown date={date} renderer={renderer} key={countdownKey} />
  ));

  //   handle form submittion
  const handleFormSubmittion = (otp) => {
    const value = { otp, password };
    dispatch(verifyEmail(value));
  };

  // Set the initial date when the component is first mounted
  useEffect(() => {
    setInitialDate(Date.now() + 60 * 1000);
  }, []);

  // Navigate User to login page
  useEffect(() => {
    if (state.isAuthenticated) {
      navigate("/user");
    }
  }, [state.isAuthenticated]);

  return (
    <main className="py-24 px-2">
      <p className="mt-8 text-center text-2xl semibold">
        Kindly provide the One Time Password (OTP) sent to your mail
      </p>
      <div className="bg-gray-100 w-1/5 min-w-[360px] mx-auto p-4 rounded mt-12 h-64  shadow-lg flex flex-col justify-between">
        <OTPInput
          ref={otpRef}
          containerStyle={{
            marginTop: "1rem",
          }}
          value={otp}
          // onChange={setOtp}
          onChange={(otp) => {
            setOtp(otp);
            // setFieldValue("otp", otp);
            if (otp.length === 6) {
              // if the length of the otp is equal to the required number of inputs
              handleFormSubmittion(otp); // submit the form
            }
          }}
          numInputs={6}
          renderSeparator={<span>-</span>}
          inputStyle={{
            width: "2rem",
            height: "2rem",
            margin: "0 .5rem",
            fontSize: "1.5rem",
            borderRadius: "4px",
            border: "1px solid gray",
          }}
          renderInput={(props) => <input {...props} />}
        />

        <div className="text-3x text-center w-full">
          <CountdownMemo
            date={initialDate}
            renderer={renderer}
            key={countdownKey}
          />
        </div>

        <button
          disabled={otp.length < 6}
          onClick={() => handleFormSubmittion(otp)}
          type="submit"
          class={`w-full semi-bold ${
            otp.length < 6 || state.loading ? "bg-gray-300" : "bg-gold"
          } py-3 text-center text-white rounded-md`}
        >
          {`${state.loading ? "Verifying Email" : "Verify Email"}`}
        </button>
      </div>
      <p className="text-center mt-4">
        NB: <span>OTPs are valid for 60 seconds only</span>
      </p>
      <p className="text-center flex items-center justify-center">
        <FaExclamationTriangle className="text-yellow-500 mr-2" />
        Please don't refresh this page
      </p>
    </main>
  );
};

export default EmailVerification;
