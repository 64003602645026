import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { useNavigate } from "react-router";
import {
  fetchJobSeekerApplications,
  deleteJobSeekerApplication,
} from "../store/slices/jobSeeker";
import { FaTrash } from "react-icons/fa";
import { labelBackground } from "../utils/labelColors";

const JobSeekerApplications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const { applications, job_seeker } = useSelector((state) => state.jobSeeker);

  // Handle Navigation to company sumaary page/profile
  const handleCompanyProfile = (id) => {
    navigate(`/user/applications/company-profile/${id}`);
  };

  // handle delete application
  const handleDeleteApplication = (id) => {
    dispatch(
      deleteJobSeekerApplication({
        job_seeker_id: job_seeker.id,
        application_id: id,
      })
    );
  };

  // table rows
  const rows = applications
    ? applications
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((application) => {
          let label_bg = labelBackground(application.status);
          return (
            <Table.Tr
              key={application.id}
              className=" border-b  rounded-md"
              onClick={() => handleCompanyProfile(application.company.id)}
            >
              <Table.Td className="p-4">
                {application?.company?.company_name}
              </Table.Td>
              <Table.Td>{application?.listing?.job_title}</Table.Td>
              <Table.Td>{application.state}</Table.Td>
              <Table.Td className={`${label_bg} mt-2 inline-block`}>
                {application.status}
              </Table.Td>
              <Table.Td
                className=" border"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FaTrash
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteApplication(application.id);
                  }}
                />
              </Table.Td>
            </Table.Tr>
          );
        })
    : [];

  // fetch job seeker applications
  useEffect(() => {
    dispatch(fetchJobSeekerApplications(job_seeker.id));
  }, [dispatch, job_seeker.id]);

  return (
    <div className="p-8 max-sm:p-2 max-sm:w-full">
      <header className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-slate-gray">Applications</h1>
      </header>
      <section className="p-8 mt-8 max-sm:p-2 max-sm:mt-2 max-sm:overflow-hidden max-sm:overflow-x-scroll">
        <div className="w-full max-sm:overflow-hidden dynamic-table-width max-sm:overflow-x-scroll">
          <Table.ScrollContainer minWidth={200}>
            <Table
              className="w-full  bg-white rounded-lg border-red-600 max-sm:overflow-x-scroll"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-white bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                    Company Name
                  </Table.Th>
                  <Table.Th className=" font-semibold">Position</Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Due Date
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold ">Status</Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(applications?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </section>
    </div>
  );
};

export default JobSeekerApplications;
