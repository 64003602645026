import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  BorderTextInput,
  QuillEditor,
  Select,
  Radio,
} from "../../components/customFormikEle";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import * as Yup from "yup";
import "react-country-state-city/dist/react-country-state-city.css";
import { toast } from "react-toastify";
import useCloudinaryImageUpload from "../../hooks/useImageUpload";
import { defaultCountry } from "../../constants";
import { useDispatch } from "react-redux";
import { signupUser } from "../../store/slices/authSlice";

const CreateNewUser = () => {
  const dispatch = useDispatch();
  const { uploadToCloudinary } = useCloudinaryImageUpload();
  const [countryid, setCountryid] = useState(
    defaultCountry ? defaultCountry.id : 0
  );
  const [stateid, setstateid] = useState(0);

  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    company_name: "",
    phone_numbers: "",
    website: "",
    state: "",
    country: "",
    city: "",
    description: "",
    industry: "",
    size: "",
    logo: "",
    other_industry: "",
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()#+-={}])[A-Za-z\d@$!%*?&^()#+-={}]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  // initilaize the country, state and city objects
  const [countryObject, setCountryObject] = useState("");
  const [stateObject, setStateObject] = useState("");
  const [cityObject, setCityObject] = useState("");

  // setting the initial url of the logo from what we have in the database
  const [url, setUrl] = useState("");

  // handle profile image upload
  const handleImageUpload = async () => {
    const transformation = [{ width: 100, height: 100, crop: "fill" }];
    const url = await uploadToCloudinary(transformation);
    setUrl(url);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!countryObject) {
      setCountryObject(defaultCountry.name);
    }
    if (!stateObject || !cityObject) {
      toast.error("Please select a  state and city");
      return;
    }
    const value = {
      ...values,
      country: countryObject ? countryObject : defaultCountry.name,
      state: stateObject,
      city: cityObject,
      logo: url,
      is_profile_complete: true,
      is_admin: true,
      email_verified: true,
      has_run_tour: true,
      is_premium: true,
    };
    let userDetails;

    const { email, password, email_verified, ...rest } = value;

    const auth_info = {
      email,
      password,
      email_verified,
      user_type: "company",
    };

    const user_info = {
      ...rest,
    };

    userDetails = { auth_info, user_info };

    if (!navigator.onLine) {
      toast.error("No internet connection!");
    }

    dispatch(signupUser({ values: userDetails, resetForm }));
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="flex gap-5  max-sm:gap-2 max-sm:mb-4 w-full max-sm:flex-col  mb-10 ">
            <img
              width={100}
              height={100}
              src={url ? url : ""}
              className="rounded-full bg-gray-200"
              alt=""
            />
            <span>
              <button
                onClick={handleImageUpload}
                type="button"
                className="p-2 my-6 rounded-md bg-gold hover:bg-gray-400 text-white"
              >
                Upload Company Logo
              </button>
            </span>
          </div>
          <div className="flex gap-5">
            <BorderTextInput
              required
              type="email"
              placeholder="Company Email"
              name="email"
            />
            <BorderTextInput
              name="company_name"
              type="text"
              placeholder="Company Name"
            />
          </div>
          <div className="flex gap-5">
            <BorderTextInput
              required
              type="password"
              placeholder="Password"
              name="password"
            />
            <BorderTextInput
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
            />
          </div>
          <div className="flex gap-5">
            <BorderTextInput
              required
              type="phone"
              placeholder="Phone Number"
              name="phone_numbers"
            />

            <BorderTextInput placeholder="Company Website" name="website" />
          </div>

          <div className="flex gap-5">
            <div className="flex gap-2 w-full  flex-col">
              <label className="font-raleway mt-3 text-slate-gray font-semibold min-w-[130px]">
                Company Size
              </label>
              <div className="w-full  max-sm:w-full justify-between flex-col">
                <div className="m-3 w-full ml-3 flex">
                  <Radio
                    name="size"
                    type="radio"
                    value="small"
                    className="rounded-sm border p-3 mb-2 mr-4"
                    required
                  />
                  <label htmlFor="">Small (1 - 50 Employees) </label>
                </div>
                <div className="m-3 w-full ml-3 flex">
                  <Radio
                    name="size"
                    type="radio"
                    value="medium"
                    className="rounded-sm border p-3 mb-2 mr-4"
                  />
                  <label htmlFor="">Medium (51 - 500 Employees) </label>
                </div>
                <div className="m-3 w-full ml-3 flex">
                  <Radio
                    name="size"
                    type="radio"
                    value="large"
                    className="rounded-sm border p-3 mb-2 mr-4"
                  />
                  <label htmlFor="">Large (501+ Employees) </label>
                </div>
              </div>
              <div className="flex gap-2 flex-col  mb-5">
                <label className="font-raleway text-slate-gray font-semibold ">
                  Industry
                </label>
                <Select
                  name="industry"
                  defaultValue={"Select the primary industry of your company"}
                  className=" border p-3 w-full"
                  id="select-industry"
                  required
                >
                  <option value="">--Select one--</option>
                  <option className="mt-5" value="technology">
                    Technology
                  </option>
                  <option value="education">Education</option>
                  <option value="fashion">Fashion</option>
                  <option value="healthcare">Healthcare</option>
                  <option value="finance">Finance</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="other">{"Other(Please specify)"}</option>
                </Select>
              </div>
            </div>
            <div className="flex w-full  flex-col mb-5">
              <label className="font-raleway text-slate-gray font-semibold my-4">
                Company Location:
              </label>
              <div className="w-full">
                <CountrySelect
                  autoComplete="false"
                  defaultValue={defaultCountry}
                  inputClassName="focus:outline-gold"
                  containerClassName="w-full text-gray-800 mb-4 rounded-md"
                  onChange={(e) => {
                    setCountryid(e.id);
                    setCountryObject(e.name);
                  }}
                  placeHolder={`Select Country`}
                />

                <StateSelect
                  inputClassName="focus:outline-gold"
                  autoComplete="false"
                  containerClassName="w-full text-gray-800 mb-4 rounded-md"
                  countryid={countryid}
                  onChange={(e) => {
                    setstateid(e.id);
                    setStateObject(e.name);
                  }}
                  placeHolder={`Select State`}
                />

                <CitySelect
                  autoComplete="false"
                  inputClassName="focus:outline-gold"
                  containerClassName="w-full text-gray-800 mb-4 rounded-md"
                  countryid={countryid}
                  stateid={stateid}
                  onChange={(e) => {
                    setCityObject(e.name);
                  }}
                  placeHolder={`Select City`}
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 max-sm:w-full">
            {values.industry === "other" && (
              <BorderTextInput
                placeholder="Specify other industry"
                name="other_industry"
              />
            )}
          </div>
          <div className="flex gap-5">
            <div className="flex gap-5 w-full justify justify-between  flex-col mb-5">
              <label className="font-raleway mt-3 text-slate-gray font-semibold">
                Brief summary about your company/business:
              </label>
              <div className=" w-full">
                <QuillEditor name="description" label="" />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="text-white p-4 font-semibold font-raleway bg-gold
              rounded-md cursor-pointer min-w-[300px]"
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNewUser;
