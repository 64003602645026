import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAdminApi,
  fetchSingleAdminApi,
  updateAdminApi,
  createAdminApi,
  deleteAdminApi,
} from "../../api/adminApi";
import { toast } from "react-toastify";

const initialState = {
  admins: [],
  loading: false,
  error: null,
  admin: {},
};

export const fetchAdmin = createAsyncThunk(
  "admin/fetchAdmins",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAdminApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSingleAdmin = createAsyncThunk(
  "admin/fetchAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchSingleAdminApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createAdmin = createAsyncThunk(
  "admin/createAdmin",
  async ({ value, resetForm }, { rejectWithValue }) => {
    try {
      const response = await createAdminApi(value);
      if (response.status === 201) {
        toast.success("Admin User Created");
        resetForm();
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async ({ value, id }, { rejectWithValue }) => {
    try {
      const response = await updateAdminApi({ value, id });
      if (response.status === 200) {
        toast.success("User Details updated");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/deleteAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteAdminApi(id);
      if (response.status === 200) {
        toast.success("User deleted");
      }
      return id;
    } catch (error) {
      toast.error(error.response.data.detail)
      return rejectWithValue(error.message);
    }
  }
);

const adminslice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload;
        state.error = null;
      })
      .addCase(fetchAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admin = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
        const updatedAdminIndex = state.admins.findIndex(
          (admin) => admin.id === action.payload.id
        );
        if (updatedAdminIndex !== -1) {
          // Replace the old admin data with the updated one
          state.admins[updatedAdminIndex] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admins.push(action.payload);
        state.error = null;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.filter(
          (admin) => admin.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminslice.reducer;
