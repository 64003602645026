import { copyrightSign } from "../assets/icons"
import { gscWhite } from "../assets/images"
import { socialMedia } from "../constants"

const Footer = () => {
  return (
    <footer className="max-container">
      <div className="flex justify-betweeb items-start gap-20 flex-wrap max-lg:flex-col">
        <div className="flex flex-col items-start">
          <a href="/">
            <img 
              src={gscWhite} 
              alt="Logo" 
              height={150}
              width={150}
            />
          </a>
          <p className="mt-6 text-base leading-7 sm:max-w-sm text-white-400 font-raleway">Connecting businesses with their perfect matches, streamlining the search for strategic partners, investors, and growth opportunities.</p>

          <div className="flex items-center gap-5 mt-8">
            {socialMedia.map((social) => (
              <div className="flex justify-center items-center w-12 h-12 p-3 rounded-full bg-white">
                <a href={social.URL}><img src={social.src} alt={social.alt} width={24} height={24}/></a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-5 border-t border-gray-500 flex justify-between text-white-400 mt-24  max-sm:flex-col max-sm:items-center">

        <div className="flex flex-1 justify-start items-center gap-2 font-raleway cursor-pointer">
          <img src={copyrightSign} alt="copyright sign" width={20} height={20} />
              <p>Copyright 2023. All rights reserved.</p>
        </div>
        <div>
        <a href="/privacy"><p className="font-raleway cursor-pointer">Privacy Policy</p></a>
        <a href="/terms"><p className="font-raleway cursor-pointer">Terms & Conditions</p></a>
        </div>

      </div>
    </footer>
  )
}

export default Footer