import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";

const CompanySettings = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/user/settings/manage-subscription");
  }, []);
  return (
    <div>
      <nav className="bg-white max-sm:w-full">
        <ul className="flex justify-between max-sm:flex-col">
          <li className="text-center w-1/2 max-sm:w-full p-2">
            <NavLink
              className=" shadow border border-gray-300 w-full inline-block p-2"
              to="manage-subscription"
            >
              Manage Subscription
            </NavLink>
          </li>
          <li className="w-1/2 text-center max-sm:w-full p-2">
            <NavLink
              className="shadow border border-gray-300 w-full inline-block p-2"
              to="company-account"
            >
              Manage Account
            </NavLink>
          </li>
        </ul>
      </nav>
      <section className="p-4 max-sm:p-0">
        <Outlet />
      </section>
    </div>
  );
};

export default CompanySettings;
