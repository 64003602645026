import React from "react";
import SidebarLink from "./SidebarLink";
import { Link } from "react-router-dom";
import { gscMain } from "../../assets/images";
import {
  FaBars,
  FaBell,
  FaUserFriends,
  FaFileAlt,
  FaChartArea,
  FaPowerOff,
  FaCog,
  FaFolderPlus,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

function SideBar({ notificationCount }) {
  const dispatch = useDispatch();
  // logout user
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <section className="p-4 w-[320px]  min-h-screen bg-gray-900 flex flex-col">
      <Link to="/" className="w-80">
        <img src={gscMain} alt="logo" width={50} height={50} />
      </Link>

      <div className="flex-1 flex flex-col justify-between ">
        <ul>
          <SidebarLink path={"/admin"} Icon={FaBars} label={"Dashboard"} end />
          <SidebarLink
            path={"users"}
            Icon={FaUserFriends}
            label={"User Management"}
          />
          <SidebarLink
            path={"applications"}
            Icon={FaFileAlt}
            label={"Application Management"}
          />
          <SidebarLink
            path={"subscribers"}
            Icon={FaChartArea}
            label={"Subscriptions"}
          />
          <SidebarLink
            path={"post-jobs"}
            Icon={FaFolderPlus}
            label={"Post Jobs"}
          />
          <SidebarLink
            path={"notifications"}
            Icon={FaBell}
            label={"Notifications"}
            setNotificationCount={true}
            notificationCount={notificationCount} //unread notifications
          />
        </ul>
        <ul>
          {/* <SidebarLink path={"/admi"} Icon={FaLifeRing} label={"Support"} /> */}
          <SidebarLink path={"settings"} Icon={FaCog} label={"Settings"} />
          <li
            onClick={handleLogout}
            className="my-2 mx-auto cursor-pointer rounded-md text-gray-300"
          >
            <p
              className={
                "p-4 inline-block w-full rounded-md flex gap-4 items-center hover:bg-gray-800 transition-colors duration-300"
              }
            >
              <FaPowerOff />
              <span>Logout</span>
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default SideBar;
