import React, { useEffect, useState } from "react";
import UploadDocument from "./UploadDocument";
import useCloudinaryWidget from "../hooks/useCloudinary";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDocuments,
  deleteDocument,
  createDocument,
  updateDocument,
} from "../store/slices/document";
import { toast } from "react-toastify";


const CompanyDocuments = () => {
  const { uploadToCloudinary } = useCloudinaryWidget();
  const { documents } = useSelector((state) => state.document);
  const { token } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.company.company);

  const [loading, setLoading] = useState(null);

  const dispatch = useDispatch();

  const handleFileUpload = async (id, type) => {
    try {
      setLoading(id);
      // Upload the file to cloudinary
      const { url, fileName } = await uploadToCloudinary();

      // Create or update the document
      let values = {};
      if (id === undefined) {
        values = {
          company_id: company.id, //use token to get company or jobseeker
          document_name: fileName ? fileName : "File Name not found",
          document_type: type,
          document_url: url,
          document_owner: "company",
        };

        if (url) {
          dispatch(createDocument(values));
        }
      } else {
        values = {
          document_name: fileName ? fileName : "File Name not found",
        };
        if (url) {
          dispatch(updateDocument({ id, values }));
        }
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(null);
    }
  };

  // Delete a document
  const handleDelete = (id) => {
    dispatch(deleteDocument(id));
  };

  useEffect(() => {
    dispatch(fetchDocuments({ token, company_id: company?.id }));
  }, []);

  // separate the documents into their respective types
  const businessLicenseDocument = documents.find(
    (doc) => doc.document_type === "business_license"
  );
  const coiDocument = documents.find((doc) => doc.document_type === "coi"); //certificate of incoporation
  const otherDocument = documents.find((doc) => doc.document_type === "others");

  return (
    <div className="p-8 max-sm:p-2 max-sm:w-full">
      <h1 className="text-xl font-raleway font-semibold text-gray-700 mb-8">
        {" "}
        Required Company Documents
      </h1>

      {/* File Upload Section */}
      <section>
        <div className="block rounded-lg shadow-lg bg-white max-sm:p-4 p-8 __joyride_upload_docs">
          <UploadDocument
            loading={loading === businessLicenseDocument?.id}
            title={"Business Licenses"}
            fileName={businessLicenseDocument?.document_name}
            onClick={() =>
              handleFileUpload(businessLicenseDocument?.id, "business_license")
            }
            handleDelete={() => handleDelete(businessLicenseDocument?.id)}
          />
          <UploadDocument
            loading={loading === coiDocument?.id}
            title={"Certificate of Incorporation"}
            fileName={coiDocument?.document_name}
            onClick={() => handleFileUpload(coiDocument?.id, "coi")}
            handleDelete={() => handleDelete(coiDocument?.id)}
          />
          <UploadDocument
            loading={loading === otherDocument?.id}
            title={"Document"}
            fileName={otherDocument?.document_name}
            onClick={() => handleFileUpload(otherDocument?.id, "others")}
            handleDelete={() => handleDelete(otherDocument?.id)}
          />
        </div>
      </section>
    </div>
  );
};

export default CompanyDocuments;
