import React from "react";
import getUserType from "../utils/getUser";
import CompanyDashboard from "./CompanyDashboard";
import JobSeekerDashboard from "./JobSeekerDashboard";

const Dashboard = () => {
  const userType = getUserType();

  return (
    <>
      {userType === "company" ? <CompanyDashboard /> : <JobSeekerDashboard />}
    </>
  );
};

export default Dashboard;
