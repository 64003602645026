import api from "../api/interceptor";
import { BASE_URL } from "../constants";

// Fetch all companies
export const fetchCompanyApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/companies/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single company
export const fetchSingleCompanyApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/companies/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a company's profile
export const updateCompanyApi = async ({ value, id }) => {
  try {
    const response = await api.put(`${BASE_URL}/companies/${id}`, value, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
