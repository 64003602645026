import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { setSearchTerm } from "../../store/slices/searchTerm";
import { FaBan, FaSearch, FaCheckCircle } from "react-icons/fa";
import { format, set } from "date-fns";
import JobSeekerSummary from "./JobSeekerSummary";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { updateJobSeeker } from "../../store/slices/jobSeeker";
import { exportCsvApi } from "../../api/exportCsvApi";
import { toast } from "react-toastify";

const AllJobSeekers = () => {
  const searchTerm = useSelector((state) => state.searchTerm);
  const dispatch = useDispatch();

  const [opened, { open, close }] = useDisclosure(false); //control modal
  const [selectedJobseeker, setSelectedJobseeker] = useState(null);
  const [loading, setLoading] = useState(false);

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const applicants = useSelector((state) => state?.jobSeeker?.job_seekers);

  // show modal content
  // handle model content
  const showModalContent = (jobseeker) => {
    setSelectedJobseeker(jobseeker);
    open();
  };

  // csv file exclude columns
  const exclude_columns = [
    "id",
    "company_id",
    "created_at",
    "updated_at",
    "job_listing_id",
    "job_seeker_id",
    "user_id",
    "has_run_tour",
    "is_profile_complete",
    "how_did_you_hear_about_us",
    "country_code",
    "preffered_vacancy_state",
    "disabled",
    "__class__",
  ];

  // handle csv export
  const handleExportCsv = async () => {
    setLoading(true);
    try {
      exportCsvApi({
        outputFileName: `applicants-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "job_seekers",
        values: {
          exclude_columns: exclude_columns,
        },
      });
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const banOrUnbanEmployer = (applicant) => {
    let value;
    if (applicant.disabled) {
      value = { disabled: false };
    } else {
      value = { disabled: true };
    }
    dispatch(
      updateJobSeeker({
        value,
        id: applicant.id,
      })
    );
  };

  // search filter
  const filteredApplicants = applicants.filter((applicant) => {
    // Check if the applicant name matches the search term
    const matchesSearchTerm =
      applicant.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      applicant.last_name.toLowerCase().includes(searchTerm.toLowerCase());

    // Check if the applicant matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "high_school":
        matchesFilter = applicant.education_level === "high_school";
        break;
      case "diploma":
        matchesFilter = applicant.subscription_plan === "diploma";
        break;
      case "bachelors":
        matchesFilter = applicant.subscription_plan === "bachelors";
        break;
      case "masters":
        matchesFilter = applicant.subscription_plan === "masters";
        break;
      case "doctorate":
        matchesFilter = applicant.subscription_plan === "doctorate";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the applicant matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredApplicants
    ? filteredApplicants
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((applicant) => (
          <Table.Tr
            key={applicant?.id}
            className=" border-b  rounded-md"
            onClick={(event) => {
              if (!event.target.closest("[data-ban-icon]")) {
                showModalContent(applicant);
              }
            }}
          >
            <Table.Td className="p-4">{`${applicant?.first_name} ${applicant?.last_name}`}</Table.Td>
            <Table.Td>{applicant?.email}</Table.Td>
            <Table.Td>
              {format(new Date(applicant.created_at), "dd/MM/yyyy")}
            </Table.Td>
            <Table.Td>{`${applicant?.phone_numbers}`}</Table.Td>
            <Table.Td
              className="cursor-pointer"
              data-ban-icon="true"
              onMouseDown={(event) => {
                event.stopPropagation();
                banOrUnbanEmployer(applicant);
              }}
            >
              {applicant?.disabled ? (
                <FaCheckCircle title="enable user" />
              ) : (
                <FaBan title="disable user" />
              )}
            </Table.Td>
          </Table.Tr>
        ))
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);

  return (
    <>
      <div className="p-8">
        <section className="border bg-white rounded p-8">
          <div className="flex items-center justify-between mb-4">
            <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
              <FaSearch />
              <input
                onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                type="text"
                className="flex-1 h-full p-2 focus:outline-none bg-transparent"
                placeholder="search full name..."
              />
            </div>
            <div className="w-[400px] flex items-center gap-5">
              <div>
                Total: <span>{filteredApplicants.length}</span>
              </div>
              <select
                value={filter}
                onChange={handleFilterChange}
                name="filter"
                className="flex-1 p-2 border border-gray-600"
              >
                <option value="all">All</option>
                <option className="mt-5" value="high_school">
                  High School
                </option>
                <option value="diploma">Diploma</option>
                <option value="bachelors">Bachelor's Degree</option>
                <option value="masters">Master's Degree</option>
                <option value="doctorate">Doctorate Degree</option>
              </select>
              {applicants?.length > 0 && (
                <button
                  disabled={loading}
                  onClick={handleExportCsv}
                  className={`text-white p-2 px-4 font-semibold font-raleway ${
                    loading ? "bg-gray-300" : "bg-black"
                  }
              rounded-md cursor-pointer`}
                >
                  {loading ? "loading..." : "Export CSV"}
                </button>
              )}
            </div>
          </div>

          {/* Table */}
          <div>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg border-red-600"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                    Full Name
                  </Table.Th>
                  <Table.Th className=" font-semibold">Email</Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Date Registered
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Phone Number
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </div>
        </section>
        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(filteredApplicants?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title="Job Seeker Details"
        size={"70%"}
      >
        <JobSeekerSummary {...selectedJobseeker} />
      </Modal>
    </>
  );
};

export default AllJobSeekers;
