import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import profile_img from "../../assets/images/ard_working.jpg";
import { fetchSingleCompany } from "../../store/slices/companies";
import parse from "html-react-parser";
import { options } from "../../utils/ulParser";

const EmployerSummary = ({
  id,
  email,
  company_name,
  phone_numbers,
  website,
  state,
  country,
  linkedin,
  twitter,
  instagram,
  industry,
  description,
  logo,
}) => {
  const dispatch = useDispatch();
  const { listings, loading } = useSelector((state) => state?.company?.company);

  useEffect(() => {
    dispatch(fetchSingleCompany(id));
  }, [id]);

  if (loading) return <div>Loading..</div>;
  return (
    <div className="flex flex-col p-5 items-center">
      <div className="flex items-center w-2/3 justify-between">
        <div className="flex gap-5 w-full items-center">
          <img
            className="w-32 h-32 rounded-full mt-10 mr-8"
            src={logo ? logo : profile_img}
            alt="Profile"
          />
          <h1 className="text-2xl mt-5">{company_name}</h1>
        </div>
      </div>
      <div className="w-2/3 mt-5">
        <p className="font-bold text-xl">Description</p>
        <div className="border p-4 bg-gray-100 rounded mt-3">
          <p className="text-gray-500">{parse(description, options)}</p>
        </div>
      </div>
      <div className="w-2/3 mt-5">
        <p className="font-bold text-xl">Industry</p>
        <div className="border p-4 bg-gray-100 rounded mt-3">
          <p className="text-gray-500">{industry}</p>
        </div>
      </div>
      <div className="mt-5 w-2/3 ">
        <p className="font-bold text-xl">Contact</p>
        <div className="border p-4 bg-gray-100 rounded mt-3">
          <p>
            <span className="font-bold">Email:</span> {email}
          </p>
          <p>
            <span className="font-bold">Phone:</span> {phone_numbers}
          </p>
          <p>
            <span className="font-bold">Website :</span> {website}
          </p>
          <p>
            <span className="font-bold">Head Quarters:</span>{" "}
            {`${state}, ${country}`}
          </p>
        </div>
        <div>
          <p className="font-bold text-xl mt-5">Social Media</p>
          <div className="border p-4 bg-gray-100 rounded mt-3">
            <p>
              <span className="font-bold">LinkedIn:</span> {linkedin}
            </p>
            <p>
              <span className="font-bold">Twitter:</span> {twitter}
            </p>
            <p>
              <span className="font-bold">Instagram:</span> {instagram}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerSummary;
