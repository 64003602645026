import React, { useState } from "react";
import MembershipCardList from "./MembershipCardList";
import { FaAward } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  initializeSubscription,
  verifySubscription,
} from "../store/slices/subscriptions";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { currencyFormatter } from "../utils/numberFormatter";

const MembershipCard = ({ features = [], name, amount, plan_code }) => {
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const authorization = useSelector(
    (state) => state?.subscription?.authorizationUrl
  );
  const [loading, setLoading] = useState(false);

  const company = useSelector((state) => state?.company?.company?.company);
  const config = {
    email: company?.email,
    amount,
    plan: plan_code,
    currency: "NGN",
    company_id: company?.id,
  };

  // format title
  const titleWithoutBracket = name?.split(" (")[0];

  // handle payment
  const handlePayment = async (conf) => {
    setLoading(true);
    dispatch(initializeSubscription(conf)).then((res) => {
      if (res?.payload?.status) open();
      setLoading(false);
    });
  };

  // verify payment when the modal is closed
  // const onClose = () => {
  //   if (authorization && authorization?.data) {
  //     dispatch(
  //       verifySubscription({
  //         reference: authorization?.data?.reference,
  //         company_id: company?.id,
  //       })
  //     );
  //   }
  //   close();
  // };

  return (
    <>
      <div
        className={`px-10 py-14 max-sm:px-7 rounded-lg ${
          name.includes("Premium") ? "bg-pale-black" : "bg-light-gold"
        } shadow relative max-sm:w-full max-sm:flex-col`}
      >
        {name.includes("Premium") && (
          <FaAward className="text-4xl text-gold mx-auto right-4 top-4" />
        )}
        <div className="flex-col items-center justify-center">
          <h2
            className={`${
              name.includes("Premium") ? "text-white" : "text-raleway"
            } text-2xl text-center capitalize`}
          >
            {titleWithoutBracket}
          </h2>
          <span
            className={`text-center ${
              name.includes("Premium") ? "text-white" : "text-raleway"
            } text-3xl  w-full inline-block font-bold `}
          >
            {" "}
            {currencyFormatter.format(amount / 100)}
          </span>
          <ul className="text-raleway my-4 flex flex-col  mb-10">
            {Array.isArray(features) &&
              features.map((feature) => (
                <MembershipCardList
                  name={name}
                  title={feature?.title}
                  description={feature?.description}
                />
              ))}
          </ul>

          <div
            disabled={loading}
            onClick={() => handlePayment(config)}
            className="px-6 absolute mb-5 bottom-4 mx-auto w-3/4 flex justify-center items-center"
          >
            <button
              className={`cursor-pointer text-center flex justify-center ${
                name.includes("Premium") ? "bg-gold" : "bg-pale-black"
              } hover:bg-gray-400 p-3  pt-2 text-white  rounded-md baseline hover:bg-goldLight w-full`}
            >
              {`${loading ? "loading..." : "Subscribe"}`}
            </button>
          </div>
        </div>
      </div>
      <Modal opened={opened} onClose={close} title="Complete Payment" size={"100%"}>
        <iframe
          src={authorization?.data?.authorization_url}
          width="100%"
          className="h-[90vh]"
        ></iframe>
      </Modal>
    </>
  );
};

export default MembershipCard;
