import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubscriber,
  cancelSubscription,
  updateCard,
  fetchSubscriptionPlans,
} from "../store/slices/subscriptions";
import { format } from "date-fns";
import { FaExclamationTriangle } from "react-icons/fa";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import ChangeSubscriptionPlan from "./ChangeSubscriptionPlan";
import { currencyFormatter } from "../utils/numberFormatter";

const ManageSubscriptionSettings = () => {
  const [modalContent, setModalContent] = useState("update-card");
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const { subscription, loading, authorizationUrl } = useSelector(
    (state) => state?.subscription
  );
  const { company } = useSelector((state) => state?.company?.company);

  // cancel subscription
  const handleCancelSubscription = () => {
    dispatch(cancelSubscription({ company_id: company.id }));
  };

  // card update
  const handleUpdateCard = () => {
    setModalContent("update-card");
    const data = { company_id: company.id };
    dispatch(updateCard(data)).then((res) => {
      if (res?.type === "subscription/updateCard/fulfilled") open();
    });
  };

  // change plan
  const handleChangePlan = () => {
    setModalContent("change-plan");
    open();
  };

  // verify payment when the modal is closed
  const onClose = () => {
    dispatch(fetchSubscriber({ company_id: company.id, update: true }));
    close();
  };

  // Fetch subscriber details
  useEffect(() => {
    if (company && company.id) {
      dispatch(fetchSubscriber({ company_id: company.id }));
      dispatch(fetchSubscriptionPlans());
    }
  }, [company.id]);

  return (
    <>
      <div className="p-8  max-sm:p-2">
        <div className="bg-white p-4 rounded shadow text-gray-500">
          <div className="flex items-center justify-between max-sm:items-start max-sm:flex-col max-sm:w-full mb-4">
            <div className="">
              <p className="font-bold text-gray-800">Plan & Billing</p>
              <span className="font-semibold">
                Manage your plan and payments
              </span>
            </div>
            <div className="flex gap-8">
              <>
                {subscription?.status === "active" && (
                  <button
                    disabled={loading}
                    onClick={handleCancelSubscription}
                    className={`rounded-md p-2 px-4 text-center flex items-center justify-center cursor-pointer border border-gray-500 hover:bg-gray-100  ${
                      loading && "opacity-50"
                    }`}
                  >
                    Cancel Subscription
                  </button>
                )}{" "}
                {subscription?.status === "non-renewing" && (
                  <p className="flex items-center gap-2 bg-yellow-100 px-2 opacity-80">
                    {" "}
                    <FaExclamationTriangle className="text-yellow-500" />{" "}
                    Subscription is active but will not be auto-renewed
                  </p>
                )}
              </>
              <button
                disabled={loading}
                onClick={handleUpdateCard}
                className="rounded-md p-2 px-4 text-center flex items-center justify-center cursor-pointer border border-gray-500 hover:bg-gray-100"
              >
                Update Card
              </button>
            </div>
          </div>
          <section className="max-sm:pt-5">
            <div className="flex items-center justify-between font-semibold max-sm:w-full max-sm:flex-col max-sm:items-start">
              <p className="text-gray-800">Current Plan</p>
              <div>
                <button
                  onClick={handleChangePlan}
                  className="rounded-md p-2 px-4 text-center flex items-center justify-center cursor-pointer border border-gray-500 my-2 hover:bg-gray-100"
                >
                  Change Plan
                </button>
              </div>
            </div>
            <div className="flex gap-5 max-sm:flex-col items-start max-sm:w-full">
              <div className="flex border p-4 rounded w-1/2 max-sm:w-full flex-col">
                <div className="flex  w-full mb-2">
                  <p className="flex-1 capitalize">{`${subscription?.interval} Plan`}</p>
                  <div className="flex items-center gap-4">
                    <span className="bg-gray-200 p-1 rounded text-gray-800 font-semibold">
                      {subscription?.plan_name}
                    </span>
                    <span className="bg-green-200 p-1 rounded text-green-800 font-semibold px-2">
                      {subscription?.status}
                    </span>
                  </div>
                </div>
                <div className="text-gray-800 font-semibold">
                  {currencyFormatter.format(subscription?.plan_price)}/{" "}
                  <span>{subscription?.interval}</span>
                </div>
                <div className="text-gray-800 font-semibold">
                  {`Card on plan **** **** **** ${subscription?.last4}`}
                </div>
              </div>
              <div className="border p-4 w-1/2 max-sm:w-full rounded">
                <p className="text-gray-500 font-bold">Renew at</p>
                <p className="text-gray-800 font-bold">
                  {" "}
                  {`${
                    subscription.next_payment_date
                      ? format(
                          new Date(subscription.next_payment_date),
                          "dd/MM/yyyy"
                        )
                      : "N/A"
                  }`}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={modalContent === "update-card" ? close : onClose}
        title={
          modalContent === ("change-plan" || "complete-payment")
            ? "Change Plan"
            : "Update Card"
        }
        size={"80%"}
      >
        {modalContent === "change-plan" && (
          <ChangeSubscriptionPlan setModalContent={setModalContent} />
        )}
        {modalContent === "complete-payment" && (
          <iframe
            src={authorizationUrl?.data?.authorization_url}
            width="100%"
            className="h-[90vh]"
          ></iframe>
        )}
        {modalContent === "update-card" && (
          <iframe
            src={authorizationUrl}
            width="100%"
            className="h-[90vh]"
          ></iframe>
        )}
      </Modal>
    </>
  );
};

export default ManageSubscriptionSettings;
