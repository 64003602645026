import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchJobSeekersApi,
  fetchSingleJobSeekerApi,
  fetchJobSeekerApplicationsApi,
  updateJobSeekerApi,
  updateJobSeekerPreferencesApi,
  fetchJobSeekerPreferencesApi,
  deleteJobSeekerApplicationApi,
} from "../../api/jobSeekerApi";
import { toast } from "react-toastify";

const initialState = {
  job_seekers: [],
  loading: false,
  error: null,
  job_seeker: {},
  applications: [],
  preferences: [],
};

export const fetchJobSeekers = createAsyncThunk(
  "jobSeeker/fetchJobSeekers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchJobSeekersApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSingleJobSeeker = createAsyncThunk(
  "jobSeeker/fetchSingleJobSeeker",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchSingleJobSeekerApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchJobSeekerApplications = createAsyncThunk(
  "jobSeeker/fetchJobSeekerApplications",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchJobSeekerApplicationsApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateJobSeeker = createAsyncThunk(
  "jobSeeker/updateJobSeeker",
  async ({ value, id }, { rejectWithValue }) => {
    try {
      const response = await updateJobSeekerApi(value, id);
      if (response.status === 200) {
        toast.success("User Details updated");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateJobSeekerPreferences = createAsyncThunk(
  "jobSeeker/updateJobSeekerPreferences",
  async ({ value, id, resetForm }, { rejectWithValue }) => {
    try {
      const response = await updateJobSeekerPreferencesApi(value, id);
      if (response.status === 200) {
        toast.success("User Preferences updated");
        resetForm();
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchJobSeekerPreferences = createAsyncThunk(
  "jobSeeker/fetchJobSeekerPreferences",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchJobSeekerPreferencesApi(id);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteJobSeekerApplication = createAsyncThunk(
  "jobSeeker/deleteJobSeekerApplication",
  async ({ job_seeker_id, application_id }, { rejectWithValue }) => {
    try {
      const response = await deleteJobSeekerApplicationApi({
        job_seeker_id,
        application_id,
      });
      if (response.status === 200) {
        toast.success("Application Successfully Deleted");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobSeekerSlice = createSlice({
  name: "jobSeeker",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobSeekers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobSeekers.fulfilled, (state, action) => {
        state.loading = false;
        state.job_seekers = action.payload;
        state.error = null;
      })
      .addCase(fetchJobSeekers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.job_seekers = [];
      })
      .addCase(fetchSingleJobSeeker.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleJobSeeker.fulfilled, (state, action) => {
        state.loading = false;
        state.job_seeker = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleJobSeeker.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.job_seeker = {};
      })
      .addCase(fetchJobSeekerApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobSeekerApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload;
        state.error = null;
      })
      .addCase(fetchJobSeekerApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.applications = [];
      })
      .addCase(updateJobSeeker.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateJobSeeker.fulfilled, (state, action) => {
        state.job_seekers = state?.job_seekers?.map((job_seeker) =>
          job_seeker.id === action.payload.id ? action.payload : job_seeker
        );
        state.loading = false;
        state.error = null;
        state.job_seeker = action.payload;
      })
      .addCase(updateJobSeeker.rejected, (state, action) => {
        state.loading = false;
        state.job_seeker = {};
        console.log(action.payload);
        state.error = action.payload;
      })
      .addCase(updateJobSeekerPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateJobSeekerPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.preferences = action.payload;
      })
      .addCase(updateJobSeekerPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchJobSeekerPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobSeekerPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.preferences = action.payload;
      })
      .addCase(fetchJobSeekerPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteJobSeekerApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteJobSeekerApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.applications = state.applications.filter(
          (application) => application.id !== action.payload
        );
      })
      .addCase(deleteJobSeekerApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default jobSeekerSlice.reducer;
