import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, logout } from "../store/slices/authSlice";
import { toast } from "react-toastify";

function DeleteAccount({ closeModal }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const loading = useSelector((state) => state?.auth?.loading);
  const jobseeker = useSelector((state) => state?.jobSeeker?.job_seeker);
  const company = useSelector((state) => state?.company?.company?.company);
  const [email, setEmail] = useState("");
  const [emailFromToken, setEmailFromToken] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);

  const handleConfirmEmail = (e) => {
    e.preventDefault();
    const currentEmail = e.target.value;
    setEmail(currentEmail);
    if (currentEmail === emailFromToken) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  const cancelDeleteOperation = (closeModal) => {
    setEmail("");
    setEmailIsValid(false);
    closeModal();
  };

  const handleDeleteOperation = async () => {
    const decodedToken = jwtDecode(token);
    let id = "";

    if (decodedToken.user_type === "company") {
      id = company?.user_id;
    } else {
      id = jobseeker.user_id;
    }

    try {
      const res = await dispatch(deleteUser(id));

      if (res.type === "auth/deleteUser/fulfilled") {
        dispatch(logout());
      }
    } catch (error) {
      toast.error("An error occurred while deleting account");
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.user_type === "job_seeker") {
        setEmailFromToken(jobseeker?.email);
      } else {
        setEmailFromToken(company?.email);
      }
    }
  }, [token]);
  return (
    <div>
      <p>Are you sure you want to delete your account?</p>
      <p className="text-red-500">
        <span className="font-bold">NOTE:</span>This action cannot be undone.
      </p>
      <form action="" className="mt-4">
        <div>
          <input
            onChange={(e) => handleConfirmEmail(e)}
            type="email"
            placeholder={emailFromToken}
            className="w-full border rounded h-10 px-2"
          />
          <span className="text-sm text-gray-600">
            kindly input your registered email to confirm
          </span>
        </div>

        <div className="mt-4 flex justify-between">
          <button
            onClick={() => cancelDeleteOperation(closeModal)}
            type="reset"
            className=" w-[100px] text-white bg-black my-2 font-semibold font-raleway  rounded-md p-2 text-center flex items-center justify-center cursor-pointer"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteOperation}
            disabled={!emailIsValid || loading}
            type="button"
            className={`text-white ${
              emailIsValid && !loading ? "bg-red-500" : "bg-gray-300"
            } my-2 font-semibold font-raleway  rounded-md p-2 text-center flex items-center justify-center cursor-pointer`}
          >
            {loading ? "Deleting..." : "Delete Account"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeleteAccount;
