import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/components/sidebarLink.css";

const SidebarLink = ({
  path,
  label,
  Icon,
  notificationCount,
  setNotificationCount = false,
  end,
}) => {
  return (
    <li className="my-2 mx-auto cursor-pointer rounded-md text-gray-300">
      <NavLink
        end={end}
        to={path}
        className={
          "p-4 inline-block w-full rounded-md flex justify-between items-center hover:bg-gray-800 transition-colors duration-300"
        }
      >
        <div className="flex gap-4 items-center">
          <Icon />
          <span>{label}</span>
        </div>
        {setNotificationCount && (
          <span className="text-sm h-[25px] w-[25px] flex items-center justify-center rounded-full bg-gray-200 text-gray-800 font-bold">
            {notificationCount}
          </span>
        )}
      </NavLink>
    </li>
  );
};

export default SidebarLink;
