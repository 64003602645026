import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCompanyApi,
  fetchSingleCompanyApi,
  updateCompanyApi,
} from "../../api/companyApi";
import { toast } from "react-toastify";

const initialState = {
  companies: [],
  loading: false,
  error: null,
  company: {},
};

export const fetchCompany = createAsyncThunk(
  "company/fetchCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSingleCompany = createAsyncThunk(
  "company/fetchCompany",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchSingleCompanyApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async ({ value, id }, { rejectWithValue }) => {
    try {
      const response = await updateCompanyApi({ value, id });
      if (response.status === 200) {
        toast.success("User Details updated");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const companyslice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;
        state.error = null;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.companies = [];
      })
      .addCase(fetchSingleCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.company = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleCompany.rejected, (state, action) => {
        state.loading = false;
        state.company = {};
        state.error = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.companies = state?.companies?.map((company) =>
          company.id === action.payload.id ? action.payload : company
        );
        state.loading = false;
        state.error = null;
        state.company.company = action.payload;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default companyslice.reducer;
