import React from 'react'
import { ReactDOM } from 'react'

const BlogSection = () => {
  return (
    <div className='BlogSection'>
        <h3 className='font-raleway pl-[70px] max-sm:pl-10 pt-10 text-3xl font-bold'>Recent Articles</h3>
        <iframe src="https://blog.gscmatchpoint.com/recent-posts/" scroll="no" width="100%" height="680" style={{overflow:"hidden"}}></iframe>

    </div>
  )
}

export default BlogSection