import React, { useEffect } from "react";
import OtpModal from "./OtpModal";
import { Formik, Form } from "formik";
import { BorderlessTextInput } from "../components/customFormikEle";
import { useDispatch, useSelector } from "react-redux";
import { requestNewToken, updatePassword } from "../store/slices/authSlice";
import * as Yup from "yup";

const ForgotPassword = ({ onUpdatePasword }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  // Request New token
  const handleRequestOtp = (email) => {
    dispatch(requestNewToken({ email: email }));
  };

  // Formik Initial Values
  const initialValues = {
    email: "",
    password: "", //new password
    confirm_password: "",
    otp: "",
  };

  // Validation Schema
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()#+-={}])[A-Za-z\d@$!%*?&^()#+-={}]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(updatePassword({ values, resetForm }));
    setSubmitting(false);
  };

  //   this will close the modal automatically
  //  when password is updated successfully
  const handleReset = () => {
    onUpdatePasword();
  };

  useEffect(() => {}, [state.openModal]);

  return (
    <>
      <div>
        <p className="text-center my-4 font-bold text-xl">Forgot Password</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={handleReset}
          validateOnMount
        >
          {({ isSubmitting, isValid, handleSubmit, setFieldValue, values }) => (
            <Form className="w-[400px] mx-auto bg-white px-4 py-1 shadow rounded">
              {!state.openModal ? (
                <>
                  <div className="w-full flex flex-col">
                    <BorderlessTextInput
                      name="email"
                      placeholder="Enter Email Address"
                      type="email"
                    />

                    <BorderlessTextInput
                      name="password"
                      placeholder="Enter New Password"
                      type="password"
                    />

                    <BorderlessTextInput
                      name="confirm_password"
                      placeholder="Confirm New Password"
                      type="password"
                    />
                  </div>

                  <div className="w-full flex flex-col my-4">
                    <button
                      onClick={() => handleRequestOtp(values.email)}
                      disabled={!isValid || isSubmitting}
                      type="button"
                      className={`w-full text-white my-2 font-semibold font-raleway  ${
                        !isValid || isSubmitting ? "bg-gray-300" : "bg-gold"
                      } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                    >
                      {`${isSubmitting ? "Requesting OTP" : "Update Password"}`}
                    </button>
                  </div>
                </>
              ) : (
                <OtpModal
                  requestOtp={handleRequestOtp}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotPassword;
