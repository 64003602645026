// import api from "./interceptor";
import { BASE_URL } from "../constants";
import api from "./interceptor";

// Fetch all job applications
export const fetchJobApplicationApi = async ({ jobseeker_id = null }) => {
  try {
    const params = {};

    if (jobseeker_id) {
      params.jobseeker_id = jobseeker_id;
    }
    const response = await api.get(`${BASE_URL}/job_applications/`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single job listings
export const fetchSingleJobApplicationApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/job_applications/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a new job Application
export const createJobApplicationApi = async (values) => {
  try {
    const response = api.post(`${BASE_URL}/job_applications/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a job application
export const updateJobApplicationApi = async (id, values) => {
  try {
    const response = api.put(`${BASE_URL}/job_applications/${id}/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// delete a job application
export const deleteJobApplicationApi = async (id) => {
  try {
    const response = api.delete(`${BASE_URL}/job_applications/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
