import React, { useEffect } from "react";
import MembershipCard from "./MembershipCard";
import { membershipFeatures } from "../constants/index";
import {
  fetchSubscriber,
  cancelSubscription,
} from "../store/slices/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { currencyFormatter } from "../utils/numberFormatter";
import { useNavigate } from "react-router";
import { FaExclamationTriangle } from "react-icons/fa";

const OurMembership = () => {
  const navigate = useNavigate();
  const { standard, premium } = membershipFeatures;
  const dispatch = useDispatch();
  const { plans, subscription, loading } = useSelector(
    (state) => state?.subscription
  );
  const { company } = useSelector((state) => state?.company?.company);

  // cancel auto renewal
  const handleCancelAutoRenewal = () => {
    dispatch(cancelSubscription({ company_id: company.id }));
  };

  useEffect(() => {
    if (company && company.id) {
      dispatch(fetchSubscriber({ company_id: company.id }));
    }
  }, [company, dispatch]);

  useEffect(() => {
    //This effect will run whenever the subscription state changes
  }, [subscription]);

  return (
    <div className="w-full flex flex-col items-start __joyride_membership px-10">
      <div className="flex w-full  flex-col p-5 max-sm:p-2 flex-items items-center justify-center relative">
        <h1 className="text-raleway text-3xl max-sm:p-5 text-center max-sm:text-2xl">
          Membership Packages
        </h1>
      </div>
      <>
        {subscription &&
        Object.keys(subscription).length !== 0 &&
        subscription.message !== "Invalid key" ? (
          <div className="mx-auto ">
            <div className="flex items-end flex-col">
              <p className="mb-2 flex justify-end">
                STATUS:{" "}
                <span className="font-bold uppercase ml-2">
                  {subscription?.status}
                </span>
              </p>
              {subscription?.status === "non-renewing" && (
                <p className="flex items-center gap-2 bg-yellow-100 px-2 opacity-80 text-sm mb-2">
                  {" "}
                  <FaExclamationTriangle className="text-yellow-500" />{" "}
                  Subscription is active but will not auto-renew
                </p>
              )}
              {subscription?.status === "cancelled" && (
                <p className="flex items-center gap-2 bg-red-100 px-2 opacity-80 text-sm mb-2">
                  {" "}
                  <FaExclamationTriangle className="text-red-500" />{" "}
                  Subscription is cancelled... Kindly click{" "}
                  <code>manage subscriptions</code> link to reactivate or pick a
                  new plan
                </p>
              )}
            </div>

            <div className="bg-white rounded shadow p-4  flex flex-col items-start">
              <p className="text-raleway text-md text-center mb-2">
                You are subscribed to{" "}
                <span className="font-bold uppercase">
                  {" "}
                  {subscription?.plan_name}{" "}
                </span>{" "}
                plan
              </p>
              <p className="text-raleway text-md text-center mb-2">
                Plan Cost{" "}
                <span className="font-bold uppercase">
                  {" "}
                  {currencyFormatter.format(subscription?.plan_price)}{" "}
                </span>
              </p>
              <p className="text-raleway text-md text-center mb-2">
                Your subscription Started on: {"  "}
                <span className="font-bold uppercase">
                  {" "}
                  {`${
                    subscription.start_date
                      ? format(new Date(subscription.start_date), "dd/MM/yyyy")
                      : "N/A"
                  }`}
                </span>
              </p>
              <p className="text-raleway text-md text-center">
                Your Next Payment Date: {"  "}
                <span className="font-bold uppercase">
                  {" "}
                  {`${
                    subscription.next_payment_date
                      ? format(
                          new Date(subscription.next_payment_date),
                          "dd/MM/yyyy"
                        )
                      : "N/A"
                  }`}
                </span>
              </p>
              <p className="text-raleway text-md text-center mt-2">
                Card on Plan:{" "}
                <span className="font-bold">
                  {` ****  ****  ****  ${subscription?.last4}`}
                </span>
              </p>
            </div>
            <div className="flex gap-4 items-center">
              <p
                onClick={() => navigate("/user/settings")}
                className="text-blue-600 underline cursor-pointer mt-2"
              >
                Manage subscriptions
              </p>
              <button
                disabled={loading}
                onClick={handleCancelAutoRenewal}
                className={`rounded-md p-2 px-4 text-center ${
                  loading ? "bg-gray-300" : "bg-gold "
                }flex items-center justify-center cursor-pointer  my-2 hover:bg-gray-100 hover:border hover:border-gray-800 `}
              >
                {loading ? "loading..." : "Cancel Auto Renewal"}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full max-sm:w-full max-sm:px-2 flex flex-col p-5 flex-items align-center items-center justify-around mx-auto">
            <div>
              <p className="text-2xl text-gray-700 text-center font-bold upper-case max-sm:text-center mb-5">
                Standard Plans
              </p>
              <div className="gap-10 grid lg:grid-cols-3 max-sm:grid-cols-1 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
                {plans
                  .slice()
                  .filter((plan) => plan?.name?.includes("Standard"))
                  .sort((a, b) => a.amount - b.amount)
                  .map((plan) => {
                    let features;
                    if (plan?.name?.includes("Premium")) {
                      features = premium?.features;
                    } else features = standard?.features;

                    return <MembershipCard {...plan} features={features} />;
                  })}
              </div>
            </div>
            <div className="mt-8">
              <p className="text-2xl text-gray-700 text-center font-bold upper-case max-sm:text-center mt-10 mb-5">
                Premium Plans
              </p>
              <div className="gap-10 grid lg:grid-cols-3 max-sm:grid-cols-1 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
                {plans
                  .slice()
                  .sort((a, b) => a.amount - b.amount)
                  .filter((plan) => plan?.name?.includes("Premium"))
                  .map((plan) => {
                    let features;
                    if (plan?.name?.includes("Premium")) {
                      features = premium?.features;
                    } else features = standard?.features;

                    return <MembershipCard {...plan} features={features} />;
                  })}
              </div>
              <div>
                {plans
                  .slice()
                  .sort((a, b) => a.amount - b.amount)
                  .filter((plan) => plan?.name?.includes("Hourly"))
                  .map((plan) => {
                    let features;
                    if (plan?.name?.includes("Hourly")) {
                      features = premium?.features;
                    } else features = standard?.features;

                    return <MembershipCard {...plan} features={features} />;
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default OurMembership;
