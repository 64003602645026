import api from "../api/interceptor";
import { BASE_URL } from "../constants";

// Fetch all preferences
export const fetchPreferencesApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/preferences/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};



// Fetch single preference
export const fetchSinglePreferenceApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/preferences/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};