import React from "react";
import getUserType from "../utils/getUser";
import JobSeekerNotifications from "./JobSeekerNotifications";
import CompanyNotifications from "./CompanyNotifications";

const Notifications = () => {
  const userType = getUserType();

  return (
    <>
      {userType === "company" ? (
        <CompanyNotifications />
      ) : (
        <JobSeekerNotifications />
      )}
    </>
  );
};

export default Notifications;
