import { customer1, customer2 } from "../assets/images";
import {
  instagram,
  linkedIn,
  innovation,
  excellence,
  trust,
  collaboration,
  facebook,
} from "../assets/icons";
import {
  FaBars,
  FaBuffer,
  FaBoxOpen,
  FaBell,
  FaMarker,
  FaCog,
  FaPowerOff,
  FaHeadset,
  FaCubes,
} from "react-icons/fa";

export const navLinks = [
  { href: "#home", label: "Home", path: "/" },
  { href: "#about-us", label: "About Us", path: "/about" },
  { href: "#membership", label: "Membership", path: "/membership" },
  { href: "#contact-us", label: "Contact Us", path: "/contact" },
];

export const signIn = [
  { href: "#signup", label: "Sign In/Explore Now", path: "/signIn" },
];

export const reviews = [
  {
    imgURL: customer1,
    customerName: "Morich Brown",
    rating: 4.5,
    feedback:
      "The attention to detail and the quality of the product exceeded my expectations. Highly recommended!",
  },
  {
    imgURL: customer2,
    customerName: "Lota Mongeskar",
    rating: 4.5,
    feedback:
      "The product not only met but exceeded my expectations. I'll definitely be a returning customer!",
  },
  {
    imgURL: customer1,
    customerName: "Morich Brown",
    rating: 4.5,
    feedback:
      "The attention to detail and the quality of the product exceeded my expectations. Highly recommended!",
  },
];

export const our_values = [
  {
    iconURL: trust,
    label: "Integrity",
  },
  {
    iconURL: excellence,
    label: "Excellence",
  },
  {
    iconURL: collaboration,
    label: "Collaboration",
  },
  {
    iconURL: innovation,
    label: "Innovation",
  },
];

export const socialMedia = [
  //{ src: facebook, alt: "facebook logo", URL: ""},
  //{ src: twitter, alt: "twitter logo", URL: "" },
  { src: linkedIn,
    alt: "linkedIn logo",
    URL: "https://www.linkedin.com/company/gsc-matchpoint/", },
  {
    src: instagram,
    alt: "instagram logo",
    URL: "https://www.instagram.com/GSC_matchpoint",
  },
  {
    src: facebook,
    alt: "facebook logo",
    URL: "https://www.facebook.com/share/123rYYYqdEA/",
  },
];

export const ProfileNavbarLinks = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/user",
    icon: <FaBars />,
  },
  {
    key: "details",
    label: "Details",
    path: "/user/details",
    icon: <FaMarker />,
  },
  {
    key: "documents",
    label: "Documents",
    path: "/user/documents",
    icon: <FaBuffer />,
  },
  {
    key: "applications",
    label: "Applications",
    path: "/user/applications",
    icon: <FaBoxOpen />,
  },
  {
    key: "membership",
    label: "Membership",
    path: "/user/membership",
    icon: <FaCubes />,
  },
  {
    key: "notifications",
    label: "Notifications",
    path: "/user/notifications",
    icon: <FaBell />,
  },
];

export const ProfileNavbarBottomLinks = [
  {
    key: "settings",
    label: "Settings",
    path: "/user/settings",
    icon: <FaCog />,
  },
  {
    key: "support",
    label: "Support",
    path: "/user/support",
    icon: <FaHeadset />,
  },
  {
    key: "logout",
    label: "Sign Out",
    path: "/",
    icon: <FaPowerOff />,
  },

  //<FaGraduationCap />
];

export const ChartsData = [
  {
    name: "January",
    submitted: 300,
    accepted: 25,
  },
  {
    name: "February",
    submitted: 256,
    accepted: 40,
  },
  {
    name: "March",
    submitted: 564,
    accepted: 45,
  },
  {
    name: "April",
    submitted: 300,
    accepted: 23,
  },
  {
    name: "May",
    submitted: 765,
    accepted: 234,
  },
  {
    name: "June",
    submitted: 567,
    accepted: 87,
  },
  {
    name: "July",
    submitted: 345,
    accepted: 76,
  },
  {
    name: "August",
    submitted: 657,
    accepted: 43,
  },
  {
    name: "September",
    submitted: 765,
    accepted: 43,
  },
  {
    name: "October",
    submitted: 435,
    accepted: 189,
  },
  {
    name: "November",
    submitted: 450,
    accepted: 37,
  },
  {
    name: "December",
    submitted: 500,
    accepted: 34,
  },
];

export const membershipFeatures = {
  standard: {
    planType: "standard",
    features: [
      {
        title: "Job Postings",
        description: "Ability to post job openings on the platform",
      },
      {
        title: "Resume Database Access",
        description: "Ability to post job openings on the platform",
      },
      {
        title: "Advertising",
        description: "Standard exposure for job postings",
      },
      {
        title: "Platform Visibility",
        description: "Basic visibility to platform users",
      },
    ],
  },

  premium: {
    planType: "premium",
    features: [
      {
        title: "Job Postings and Featured Listings",
        description: "Priority placement for job postings",
      },
      {
        title: "Resume Database Access",
        description: "Enhanced access with advanced search filters",
      },
      {
        title: "Advertising",
        description: "Reduced or no third-party ads on job listings",
      },
      {
        title: "Data Insights and Analytics",
        description: "Premium data insights for better strategies.",
      },
      {
        title: "Priority Customer Support",
        description: "Expedited query resolution",
      },
      {
        title: "Exclusive Webinars and Training",
        description: "Industry trend sessions",
      },
      {
        title: "Branding Opportunities",
        description: "Company branding on job listings",
      },
      {
        title: "Social Media Promotion",
        description: "Automatic job ad promotion",
      },
    ],
  },
};

export const defaultCountry = {
  capital: "Abuja",
  currency: "NGN",
  currency_name: "Nigerian naira",
  currency_symbol: "₦",
  emoji: "🇳🇬",
  id: 161,
  iso2: "NG",
  iso3: "NGA",
  latitude: "10.00000000",
  longitude: "8.00000000",
  name: "Nigeria",
  native: "Nigeria",
  numeric_code: "566",
  phone_code: 234,
  region: "Africa",
  subregion: "Western Africa",
  tld: ".ng",
};

//export const BASE_URL = "http://localhost:8000";
export const BASE_URL = "https://official-clarie-lamzytech-19eb5489.koyeb.app";
// export const WEBSOCKET_URL = "ws://localhost:8765";
