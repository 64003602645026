import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListingCard from "../components/ListingCard";
import { fetchJobListings } from "../store/slices/jobListingSlice";
import { Link } from "react-router-dom";

const Listings = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.jobListing);
  const searchTerm = useSelector((state) => state.searchTerm);

  const listings = state?.listings;

  useEffect(() => {
    dispatch(fetchJobListings());
  }, []);

  useEffect(() => {}, [listings]);

  const filteredListings = listings.filter(
    (listing) =>
      listing.job_title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      listing.status === "approved" &&
      !listing.is_expired
  );

  return (
    <section id="listings" className="max-container max-sm:mt-12">
      <div className="flex flex-col justify-start gap-5">
        <h2 className="text-4xl font-raleway font-bold">
          Our
          <span className="text-[#ceac46]"> Popular</span> Listings
        </h2>
        <p className="lg:max-w-lg mt-2 font-raleway text-slate-gray">
          Take Your Career to New Heights
        </p>

        <div className="mt-5 grid lg:grid-cols-4 max-sm:grid-cols-2 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-14 max-sm:gap-2">
          {filteredListings.slice(0, 8).map((listing) => (
            <ListingCard key={listing.id} {...listing} />
          ))}
        </div>
        <div className="flex justify-start">
          <Link to={"/listings"}>
            <button className="font-semibold font-raleway px-10 py-4 rounded-md text-white bg-gold cursor-pointer">
              See More
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Listings;
