import React from "react";

const AdminDashBoardStatGrid = ({ title, Icon, numbers }) => {
  return (
    <div className="bg-neutral-100 p-4 rounded-sm flex-1 border border-gray-200 flex items-center">
      <div className="rounded-full bg-gold h-12 w-12 flex items-center justify-center">
        <Icon className="text-2xl text-white" />
      </div>
      <div className="pl-4">
        <span className="text-sm text-gray-500 font-light">{title}</span>
        <div className="flex items-center">
          <strong className="text-xl text-gray-700 font-semibold">
            {" "}
            {numbers}
          </strong>
          <span className="text-sm text-green-500 pl-2">+00</span>
        </div>
      </div>
    </div>
  );
};

export default AdminDashBoardStatGrid;
