import React from "react";
import { Formik, Form } from "formik";
import { Select } from "../components/customFormikEle";
import { updateJobSeeker } from "../store/slices/jobSeeker";
import { useDispatch, useSelector } from "react-redux";

const JobSeekerNotificationSettings = () => {
  const { preffered_communication, id } = useSelector(
    (state) => state?.jobSeeker?.job_seeker
  );

  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    const { preffered_communication } = values;
    if (id && preffered_communication)
      dispatch(updateJobSeeker({ value: { preffered_communication }, id }));
  };
  return (
    <Formik
      initialValues={{
        preffered_communication: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="flex flex-col items-center max-sm:w-full">
            <p className=" my-4 font-bold text-xl ">
              Preferred mode of communication
            </p>
            <div className="w-[400px]  max-sm:w-full">
              <Select className={"w-[400px]  max-sm:w-full"} name="preffered_communication">
                <option value="">--Select one--</option>
                <option className="mt-5" value="sms">
                  SMS
                </option>
                <option value="email">Email</option>
                <option value="call">Phone Call</option>
                <option value="social_media_dm">Social media DM</option>
              </Select>
              <div className="w-full flex flex-col my-4">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className={`w-full text-white my-2 font-semibold font-raleway ${
                    isSubmitting ? "bg-gray-300" : "bg-[#060606]"
                  } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                >
                  {isSubmitting ? "Submitting.." : "Submit"}
                </button>
              </div>
            </div>
            <p>
              Current Preffered mode of communication:{" "}
              {preffered_communication
                ? preffered_communication.toUpperCase()
                : "Not selected yet"}
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default JobSeekerNotificationSettings;
