import React from "react";
import getUserType from "../utils/getUser";
import CompanySettings from "./CompanySettings";
import JobSeekerSettings from "./JobSeekerSettings";

const Settings = () => {
  const userType = getUserType();

  return (
    <>{userType === "company" ? <CompanySettings /> : <JobSeekerSettings />}</>
  );
};

export default Settings;
