import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  signupUser,
  googleSignup,
  resetIsRegistered,
} from "../store/slices/authSlice";
import { toast } from "react-toastify";
import { work_city, googleLogo } from "../assets/images";
import { BorderlessTextInput } from "../components/customFormikEle";
import { useGoogleLogin } from "@react-oauth/google";

const SignUp = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("company");
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  const [password, setPassword] = useState(""); //This state will help to move the password to the verification page
  const [email, setEmail] = useState(""); //This state will help to move the email to the verification page
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Formik Initial Values
  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    company_name: "",
    first_name: "",
    last_name: "",
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()#+-={}])[A-Za-z\d@$!%*?&^()#+-={}]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    first_name:
      selectedOption === "job_seeker"
        ? Yup.string().required("First Name is required")
        : Yup.string(),
    last_name:
      selectedOption === "job_seeker"
        ? Yup.string().required("Last Name is required")
        : Yup.string(),
    company_name:
      selectedOption === "company"
        ? Yup.string().required("Company Name is required")
        : Yup.string(),
  });

  // Handle Form Submittion
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (!isChecked) {
      return toast.error("Please accept terms and conditions");
    }
    const { email, password, company_name, first_name, last_name } = values;
    // get a copy of the user's password
    setPassword(values.password);
    setEmail(values.email);
    let userDetails;
    const auth_info = {
      email,
      password,
      user_type: selectedOption,
    };
    let user_info;
    if (selectedOption === "company") {
      user_info = {
        company_name,
      };
    } else {
      user_info = {
        first_name,
        last_name,
      };
    }

    userDetails = { auth_info, user_info };

    if (!navigator.onLine) {
      toast.error("No internet connection!");
    }

    dispatch(signupUser({ values: userDetails, resetForm }));
    setSubmitting(false);
  };

  // Google Signup
  const employerGoogleSignup = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const access_token = codeResponse.access_token;
      dispatch(googleSignup({ value: { user_type: "company" }, access_token }));
    },
    onError: (error) => {
      console.log(error);
      toast.error("Failed to signup with google");
    },
  });

  // Google Signup
  const jobSeekerGoogleSignup = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const access_token = codeResponse.access_token;
      dispatch(
        googleSignup({ value: { user_type: "job_seeker" }, access_token })
      );
    },
    onError: (error) => toast.error("Failed to signup with google"),
  });

  // Navigate on success
  useEffect(() => {
    if (state.isRegistered === true && state.isAuthenticated === false) {
      dispatch(resetIsRegistered());
      setTimeout(() => {
        navigate("/verification", {
          state: { password: password, email: email },
        });
      }, 0);
    } else if (state.isAuthenticated) {
      navigate("/user");
    }
  }, [state.isRegistered, state.isAuthenticated]);

  return (
    <div
      class="min-h-screen py-40 max-sm:pt-20"
      style={{ backgroundImage: `linearGradient(115deg, #9F7AEA, #FEE2FE)` }}
    >
      <div class="container mx-auto">
        <div class="flex flex-col  max-sm:w-full lg:flex-row w-11/12 lg:w-8/12 bg-white mx-auto shadow-lg overflow-hidden">
          <div
            class="w-full lg:w-1/2 flex flex-col items-center p-12 bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: `url(${work_city})` }}
          >
            <h1 class="mt-8 max-w-md text-2xl font-raleway font-bold text-center md:text-5xl md:text-left text-white">
              Welcome
            </h1>
            <div>
              <p class="text-white font-raleway max-sm:text-center text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
                Your journey to finding top talent and perfect career
                opportunities starts here{" "}
                <a href="#" class="text-white underline font-semibold">
                  Learn More {">"}
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 py-16 max-sm:px-6 px-12">
            <h2 class="max-w-md text-4xl font-bold font-raleway text-center md:text-5xl md:text-left">
              Register
            </h2>
            <p class="font-raleway text-slate-gray max-sm:text-center pb-10 text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
              Create your account. It’s free and will only take a minute
            </p>

            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div class="mb-5">
                    <label className="py-10 pb-10 text-gray-700 text-raleway">
                      How do you intend to use your account?
                    </label>
                    <select
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      type="text"
                      placeholder="Select User Type"
                      class="w-full  text-slate-gray py-2 bg-transparent border-b  border-slate-gray outline-none focus:outline-none"
                    >
                      <option value="company">As an Employer</option>
                      <option value="job_seeker">As a Job-Seeker</option>
                    </select>
                  </div>
                  <>
                    {selectedOption === "job_seeker" ? (
                      <div class="grid grid-cols-2 gap-5">
                        <BorderlessTextInput
                          name={"first_name"}
                          type="text"
                          placeholder="Firstname"
                        />

                        <BorderlessTextInput
                          name={"last_name"}
                          type="text"
                          placeholder="Surname"
                        />
                      </div>
                    ) : (
                      <div class="mt-5">
                        <BorderlessTextInput
                          name="company_name"
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                    )}
                  </>
                  <div class="mt-5">
                    <BorderlessTextInput
                      name="email"
                      type="text"
                      placeholder="Email"
                    />
                  </div>

                  <div class="mt-5">
                    <BorderlessTextInput
                      type="password"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  <div class="mt-5">
                    <BorderlessTextInput
                      name="confirm_password"
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div class="mt-5">
                    <input
                      type="checkbox"
                      class="border border-gray-400"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <span className="font-raleway text-slate-gray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
                      I accept the{" "}
                      <a href="/terms" class="text-gold font-semibold">
                        Terms of Use
                      </a>{" "}
                      &{" "}
                      <a href="/privacy" class="text-gold font-semibold">
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                  <div class="mt-5">
                    <button
                      type="submit"
                      class={`w-full ${
                        state.loading || isSubmitting
                          ? "bg-gray-300"
                          : "bg-gold"
                      } py-3 text-center text-white rounded-md`}
                    >
                      {state.loading || isSubmitting
                        ? "Submitting.."
                        : "Register Now"}
                    </button>
                  </div>
                  <div className="w-full flex items-center justify-center relative py-2 mt-4">
                    <div className="w-full h-[1px] bg-black"> </div>
                    <p className="text-lg absolute text-black/80 bg-white">
                      OR
                    </p>
                  </div>
                  <div className="mt-2">
                    <select
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      type="text"
                      placeholder="Select User Type"
                      class="w-full  text-slate-gray py-2 bg-transparent border-b  border-slate-gray outline-none focus:outline-none"
                    >
                      <option value="company">Employer's Google Signup</option>
                      <option value="job_seeker">
                        Job-Seeker's Google Signup
                      </option>
                    </select>
                    <>
                      {selectedOption === "company" ? (
                        <div
                          onClick={employerGoogleSignup}
                          className="w-full text-[#060606] my-2 font-semibold bg-white border border-black/40 rounded-md p-4 mt-4 text-center flex items-center justify-center cursor-pointer"
                        >
                          <img src={googleLogo} className="h-6 mr-2" />
                          Sign up with Google (Employer)
                        </div>
                      ) : (
                        <div
                          onClick={jobSeekerGoogleSignup}
                          className="w-full text-[#060606] my-2 font-semibold bg-white border border-black/40 rounded-md p-4 mt-4 text-center flex items-center justify-center cursor-pointer"
                        >
                          <img src={googleLogo} className="h-6 mr-2" />
                          Sign up with Google (Job Seeker)
                        </div>
                      )}
                    </>
                  </div>

                  <div className="w-full flex items-center justify-center">
                    <p className="text-sm font-raleway font-normal text-slate-gray">
                      Already have an account?{" "}
                      <span className="font-semibold underline underline-offset-2 cursor-pointer">
                        <Link to="/signin">Sign In</Link>
                      </span>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
