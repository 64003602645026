import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTerm } from "../store/slices/searchTerm";
import { search } from "../assets/icons";
import { FaTimesCircle } from "react-icons/fa";
import { Pagination } from "@mantine/core";
import OtherListingsCard from "../components/OtherListingsCard";

const Apply = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.jobListing);
  const searchTerm = useSelector((state) => state?.searchTerm);
  const listings = state?.listings;

  // Handle searches
  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  // filter listing by name
  const filteredListings = listings?.filter(
    (listing) =>
      listing.job_title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      listing.status === "approved" && !listing.is_expired
  );

  //2. Other Listings
  const otherListings = filteredListings
    ? filteredListings
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map((listing) => <OtherListingsCard key={listing.id} {...listing} />)
    : [];

  useEffect(() => {
    // Handle searches
    dispatch(setSearchTerm(""));
  }, []);

  return (
    <main>
      <section
        id="About_hero"
        className="w-full flex xl:flex-row flex-col justify-center gap-10 h-1/4"
      >
        <div className="relative flex flex-col justify-center items-start w-full  max-xl:padding-x pt-10 pl-20">
          <div class="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row"></div>
        </div>
      </section>
      <section className="padding-x py-10 max-sm:py-4">
        <div className="lg:max-w-[40%] w-full flex justify-between items-center max-sm:flex-col mb-2 max-sm:p-0 max-sm:mb-0 gap-5 p-2.5 pl-25 sm:border sm:border-grey-200 rounded-md max-sm:rounded-md">
          <div className="flex items-center max-sm:full max-sm:w-full p-2 w-full">
            <input
              type="text"
              placeholder="Search e.g. Designer"
              className="input pl-10 text-gray-800"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {searchTerm === "" ? (
              <img
                src={search}
                alt=""
                height={24}
                width={24}
                className="flex flex-end max-sm:inline-block absolute max-sm:ml-3 max-sm:mr-3 "
              />
            ) : (
              <FaTimesCircle
                className="text-gray-600 cursor-pointer"
                onClick={() => dispatch(setSearchTerm(""))}
              />
            )}
          </div>
        </div>
      </section>
      <section className="lg:pl-8 py-2 max-sm:px-4">
        <div>
          <div className="mt-5 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-5">
            {otherListings && otherListings.length > 0 ? (
              otherListings
            ) : (
              <h1>No listings found</h1>
            )}
          </div>
          <div className="flex justify-center my-12">
            <Pagination
              total={Math.ceil(filteredListings?.length / itemsPerPage) || 1}
              value={currentPage}
              onChange={setCurrentPage}
              color="#333"
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Apply;
