import { BASE_URL } from "../constants";
import api from "./interceptor";

// Fetch all job seekers
export const fetchJobSeekersApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/job_seekers/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single job seeker
export const fetchSingleJobSeekerApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/job_seekers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch job seeker's application
export const fetchJobSeekerApplicationsApi = async (id) => {
  try {
    const response = await api.get(
      `${BASE_URL}/job_seekers/${id}/applications`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a jobseeker's profile
export const updateJobSeekerApi = async (value, id) => {
  try {
    const response = await api.put(`${BASE_URL}/job_seekers/${id}`, value, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// delete a jobseeker's application
export const deleteJobSeekerApplicationApi = async ({
  job_seeker_id,
  application_id,
}) => {
  try {
    const response = await api.delete(
      `${BASE_URL}/job_seekers/${job_seeker_id}/applications/${application_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Update jobseeker's prefrences
export const updateJobSeekerPreferencesApi = async (value, id) => {
  try {
    const response = await api.put(
      `${BASE_URL}/job_seekers/${id}/preferences`,
      value,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// fetch jobseeker's prefrences
export const fetchJobSeekerPreferencesApi = async (id) => {
  try {
    const response = await api.get(
      `${BASE_URL}/job_seekers/${id}/preferences`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
