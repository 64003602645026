import React from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import profile_img from "../assets/images/ard_working.jpg";
import { fetchSingleCompany } from "../store/slices/companies";
import parse from "html-react-parser";

const CompanySummaryPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { listings, loading, company } = useSelector(
    (state) => state?.company?.company
  );

  useEffect(() => {
    console.log(company);
    console.log(id);
    dispatch(fetchSingleCompany(id));
  }, [id]);
  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col p-5 items-center">
      <div className="flex items-center w-2/3 justify-between">
        <div className="flex gap-5 items-center">
          <img
            className="w-32 h-32 rounded-full mt-10 mr-8"
            src={company?.logo ? company?.logo : profile_img}
            alt="Profile"
          />
          <h1 className="text-2xl mt-5">{company?.company_name}</h1>
        </div>
      </div>
      <div className="w-2/3 mt-5">
        <p className="font-bold text-xl">Description</p>
        <div className="border p-4 bg-white rounded mt-3">
          <p className="text-gray-500">
            {typeof company?.description === "string"
              ? parse(company.description)
              : ""}
          </p>
        </div>
      </div>
      <div className="w-2/3 mt-5">
        <p className="font-bold text-xl">Industry</p>
        <div className="border p-4 bg-white rounded mt-3">
          <p className="text-gray-500">
            {company?.industry && company?.industry === "other"
              ? company?.other_industry
              : company?.industry}
          </p>
        </div>
      </div>
      <div className="mt-5 w-2/3 ">
        <p className="font-bold text-xl">Contact</p>
        <div className="border p-4 bg-white rounded mt-3">
          <p>
            <span className="font-bold">Email:</span> {company?.email}
          </p>
          <p>
            <span className="font-bold">Website :</span> {company?.website}
          </p>
          <p>
            <span className="font-bold">Head Quarters:</span>{" "}
            {`${company?.state}, ${company?.country}`}
          </p>
        </div>
        <div>
          <p className="font-bold text-xl mt-5">Social Media</p>
          <div className="border p-4 bg-white rounded mt-3">
            <p>
              <span className="font-bold">LinkedIn:</span> {company?.linkedin}
            </p>
            <p>
              <span className="font-bold">Twitter:</span> {company?.twitter}
            </p>
            <p>
              <span className="font-bold">Instagram:</span> {company?.instagram}
            </p>
          </div>
        </div>
        {/* applications */}
      </div>
    </div>
  );
};

export default CompanySummaryPage;
