import React from "react";
import { FaFile, FaTimes } from "react-icons/fa";

const UploadDocument = ({
  title,
  fileName,
  onClick,
  handleDelete,
  loading,
}) => {
  return (
    <div className="flex max-sm:flex-col mb-4 items-center gap-2 max-sm:w-[280px]">
      <div className=" w-full">
        <h1 className="mb-2 font-bold font-raleway">{title}</h1>
        <button
          onClick={onClick}
          disabled={loading}
          className={`${
            loading ? "bg-gray-300" : "bg-gold"
          } hover:bg-gray-500 text-white py-2 px-4 rounded-sm max-sm:w-full`}
        >
          {loading ? "loading..." : "Upload Document"}
        </button>
      </div>
      {fileName ? (
        <div className="flex items-center border h-16 w-1/2 max-sm:w-full max-sm:h-10 overflow-hidden rounded-md px-4 bg-gray-100">
          <FaFile />
          <p className="flex-1 mx-4">{fileName}</p>
          <FaTimes
            className="text-red-600 cursor-pointer"
            onClick={handleDelete}
          />
        </div>
      ) : (
        <div className="w-full text-slate-gray font-light font-raleway pl-3">
          No file uploaded
        </div>
      )}
    </div>
  );
};

export default UploadDocument;
