import { useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BorderTextInput } from "../../components/customFormikEle";
import { requestNewToken, updatePassword, closeModal } from "../../store/slices/authSlice";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import OtpModal from "../../components/OtpModal";
import * as Yup from "yup";

const UpdatePassword = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();
  const current_admin = useSelector((state) => state.admin.admin);
  const state = useSelector((state) => state.auth);
  const email = current_admin.email;

  const handleRequestOtp = () => {
    dispatch(requestNewToken({ email: email }));
  };
  // Formik Initial Values
  const initialValues = {
    password: "",
    confirm_password: "",
    otp: "",
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  //   Submit Form
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(updatePassword({ values, resetForm }));
    setSubmitting(false);
  };

  useEffect(() => {
    if (state.openModal) {
      open();
    } else if (!state.openModal) {
      close();
    }
  }, [state.openModal]);

  // Dispatch the closeModal action when the modal is closed
  // this will set the openModal state to false
  const handleClose = () => {
    dispatch(closeModal());
    close();
  };
  return (
    <div className="flex flex-col items-center">
      <div className="font-bold text-2xl mt-4">Update Password</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid, handleSubmit, setFieldValue }) => (
          <Form>
            <div className="w-[520px]">
              <BorderTextInput
                name="password"
                type="password"
                placeholder="Enter New Password"
              />
              <BorderTextInput
                name="confirm_password"
                type="password"
                placeholder="Confirm New Password"
              />
              <div className="w-full flex flex-col my-4">
                <button
                  onClick={handleRequestOtp}
                  disabled={!isValid || isSubmitting}
                  type="button"
                  className={`w-full text-white my-2 font-semibold font-raleway  ${
                    !isValid || isSubmitting ? "bg-gray-300" : "bg-gold"
                  } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                >
                  {`${isSubmitting ? "Requesting OTP" : "Update Password"}`}
                </button>
              </div>
            </div>
            <Modal
              opened={opened}
              onClose={handleClose}
              title="Update Password"
            >
              <OtpModal
                requestOtp={handleRequestOtp}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
              />
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePassword;
