import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateNotification,
  deleteNotification,
} from "../../store/slices/notifications";
import NotificationCard from "../../components/NotificationCard";

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );

  // handle read notifications
  const handleRead = (id) => {
    dispatch(updateNotification({ id, values: { is_read: true } }));
  };

  //handle Delete notification
  const handleDelete = (id) => {
    dispatch(deleteNotification(id));
  };
  return (
    <section className="p-8 max-h-[100vh] overflow-auto">
      <section>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              customClass={"bg-gray-300"}
              {...notification}
              onRead={() => handleRead(notification.id)}
              onDelete={() => handleDelete(notification.id)}
            />
          ))
        ) : (
          <div className="text-3xl font-bold">Notifications Box is Empty</div>
        )}
      </section>
    </section>
  );
};

export default Notification;
