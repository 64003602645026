export const labelBackground = (event) => {
  switch (event) {
    case "job_application_rejected":
    case "job_listing_disapproved":
    case "rejected":
    case "disapproved":
      return "bg-red-200 text-red-800";
    case "job_application_accepted":
    case "job_listing_approved":
    case "accepted":
    case "approved":
      return "bg-green-200 text-green-800";
    case "pending":
      return "bg-yellow-200 text-yellow-800";
    default:
      return "bg-green-200";
  }
};
