import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BorderlessTextInput } from "../components/customFormikEle";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import {
  requestNewToken,
  closeModal,
  updatePassword,
} from "../store/slices/authSlice";
import OtpModal from "../components/OtpModal";
import DeleteAccount from "./DeleteAccount";

const CompanyAccountSettings = () => {
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [opened, { open, close }] = useDisclosure(false); // for the modal
  const state = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state?.company?.company?.company);

  const handleRequestOtp = () => {
    setDeleteAccountModal(false);
    dispatch(requestNewToken({ email: email }));
  };

  // Formik Initial Values
  const initialValues = {
    password: "", //new password
    confirm_password: "",
    otp: "",
  };
  // Validation Schema
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()#+-={}])[A-Za-z\d@$!%*?&^()#+-={}]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(updatePassword({ values, resetForm }));
    setSubmitting(false);
  };

  const handleDeleteAccountModal = () => {
    open();
    setDeleteAccountModal(true);
  };

  useEffect(() => {
    if (state.openModal) {
      open();
    } else if (!state.openModal) {
      close();
    }
  }, [state.openModal]);

  // Dispatch the closeModal action when the modal is closed
  // this will set the openModal state to false
  const handleClose = () => {
    dispatch(closeModal());
    close();
  };
  return (
    <>
      <div>
        <p className="text-center my-4 font-bold text-xl">Change Password</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({ isSubmitting, isValid, handleSubmit, setFieldValue }) => (
            <Form className="w-[400px] mx-auto bg-white px-4 py-1 shadow rounded">
              <div className="w-full flex flex-col">
                <BorderlessTextInput
                  name="password"
                  placeholder="Enter New Password"
                  type="password"
                />

                <BorderlessTextInput
                  name="confirm_password"
                  placeholder="Confirm New Password"
                  type="password"
                />
              </div>

              <div className="w-full flex flex-col my-4">
                <button
                  onClick={handleRequestOtp}
                  disabled={!isValid || isSubmitting}
                  type="button"
                  className={`w-full text-white my-2 font-semibold font-raleway  ${
                    !isValid || isSubmitting ? "bg-gray-300" : "bg-gold"
                  } rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
                >
                  {`${isSubmitting ? "Requesting OTP" : "Update Password"}`}
                </button>
              </div>
              <Modal
                opened={opened}
                onClose={handleClose}
                title={
                  deleteAccountModal ? "Delete Account" : "Update Password"
                }
              >
                {deleteAccountModal ? (
                  <DeleteAccount closeModal={close} />
                ) : (
                  <OtpModal
                    requestOtp={handleRequestOtp}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                  />
                )}
              </Modal>
            </Form>
          )}
        </Formik>
        <div className="mt-8 flex justify-center">
          <button
            onClick={handleDeleteAccountModal}
            type="button"
            className={`w-[200px] text-white bg-red-500 my-2 font-semibold font-raleway  rounded-md p-4 text-center flex items-center justify-center cursor-pointer`}
          >
            Delete Account
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanyAccountSettings;
