import React from "react";
import ApplicationForm from "../components/ApplicationForm";

const ApplicationPage = () => {
  return (
    <main>
      <section className="padding-x max-sm:p-4 py-5">
        <ApplicationForm />
      </section>
    </main>
  );
};

export default ApplicationPage;
