import React from "react";
import DashboardStatsGrid from "./DashboardStatsGrid";
import TransactionCharts from "./TransactionCharts";
import ApplicantProfileChart from "./ApplicantProfileChart";
import {
  FaGem,
  FaHourglassStart,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { useFetchCompanyData } from "../hooks/useChartData";

const CompanyDashboard = () => {
  const { listings } = useSelector((state) => state?.company?.company);

  const chartData = useFetchCompanyData(listings);

  const listingsAccepted = listings?.filter(
    (listing) => listing.status === "approved"
  );

  const listingsRejected = listings?.filter(
    (listing) => listing.status === "disapproved"
  );

  const listingsPending = listings?.filter(
    (listing) => listing.status === "pending"
  );

  // // Total Applicants
  // const totalApplicationCount = listings.reduce(
  //   (sum, listing) => sum + listing.application_count,
  //   0
  // );

  let applicationStatusCounts = { pending: 0, accepted: 0, rejected: 0 };

  listings?.forEach((listing) => {
    if (listing.status === "pending") {
      applicationStatusCounts.pending += 1;
    } else if (listing.status === "approved") {
      applicationStatusCounts.accepted += 1;
    } else if (listing.status === "disapproved") {
      applicationStatusCounts.rejected += 1;
    }
  });


  // Total Job Postings
  const data = [
    {
      title: "Total listings",
      Icon: FaGem,
      numbers: listings?.length,
    },
    {
      title: "Total listing Accepted",
      Icon: FaCheckCircle,
      numbers: listingsAccepted?.length,
    },
    {
      title: "Total listing Rejected",
      Icon: FaTimesCircle,
      numbers: listingsRejected?.length,
    },
    {
      title: "Total listing Pending",
      Icon: FaHourglassStart,
      numbers: listingsPending?.length,
    },
  ];

  // pie-chart data
  const pieChartData = [
    { name: "Accepted Applicants", value: applicationStatusCounts.accepted },
    { name: "Rejected Applicants", value: applicationStatusCounts.rejected },
    { name: "Pending", value: applicationStatusCounts.pending },
    ,
  ];

  return (
    <div className="p-5 flex gap-4 flex-col max-sm:p-1 max-sm:w-full">
      <DashboardStatsGrid data={data} />
      <div className="flex flex-row max-sm:flex-col gap-4 w-full">
        <TransactionCharts chartsData={chartData} />
        <ApplicantProfileChart pieChartData={pieChartData} />
      </div>
    </div>
  );
};

export default CompanyDashboard;
