import { BASE_URL } from "../constants";
import api from "./interceptor";

// Fetch all subscriptions
export const fetchSubscriptionsApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/subscriptions/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch subscriber or subscription plans
export const fetchSubscriptionApi = async ({
  company_id = null,
  update = false,
}) => {
  try {
    const params = {};
    if (company_id) {
      params.company_id = company_id;
    }
    if (update) {
      params.update = update;
    }
    const response = await api.put(
      `${BASE_URL}/subscriptions/subscription-status`,
      {},
      {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Initialize Subscription
export const initializeSubscriptionApi = async (data) => {
  try {
    const response = await api.post(`${BASE_URL}/subscriptions`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Verify Subscription
export const verifySubscriptionApi = async (data) => {
  try {
    const response = await api.post(
      `${BASE_URL}/subscriptions/verify-payment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Cancel Subscription
export const cancelSubscriptionApi = async (data) => {
  try {
    const response = await api.put(`${BASE_URL}/subscriptions/cancel`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// update card on a subscription
export const updateCardApi = async (data) => {
  try {
    const response = await api.post(
      `${BASE_URL}/subscriptions/update-card`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// get all subscription plans
export const fetchSubscriptionPlansApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/subscriptions/plans`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

//  update subscription plan
export const updateSubscriptionPlanApi = async (data) => {
  try {
    const response = await api.put(
      `${BASE_URL}/subscriptions/update-plan`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
