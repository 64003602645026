import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const MembershipCardList = ({ title, description, name }) => {
  return (
    <li key={title} className="my-3 flex items-center">
      <FaCheckCircle
        className={`${
          name.includes("Premium") ? "text-gold" : "text-default-700"
        } mr-4`}
      />
      <div>
        <strong
          className={`${
            name.includes("Premium") ? "text-white" : "text-default-700"
          }`}
        >{`${title}: `}</strong>
        <p
          className={`${
            name.includes("Premium") ? "text-gray-300" : "text-gray-800"
          }`}
        >
          {description}
        </p>
      </div>
    </li>
  );
};

export default MembershipCardList;
