import { format } from "date-fns";
import React, { useState } from "react";
import { Field, useField } from "formik";
import { FaExclamationCircle } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setSearchTerm } from "../store/slices/searchTerm";
import { useDispatch } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const BorderlessTextInput = ({
  name,
  label,
  type = "text",
  placeholder,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-group">
      <Field name={name}>
        {({ field, meta }) => (
          <>
            <div className="relative">
              <input
                {...props}
                {...field}
                autoComplete="off"
                type={showPassword ? "text" : type}
                className={`w-full text-slate-gray py-2 my-2 bg-transparent border-b  outline-none focus:outline-none ${
                  meta.touched && meta.error
                    ? "border-red-400"
                    : "border-slate-gray"
                }`}
                placeholder={placeholder}
              />
              {type === "password" && (
                <span
                  className="text-gray-500 absolute right-0 cursor-pointer transform -translate-y-1/2 top-1/2"
                  onClick={handleShowPassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              )}
            </div>
            {meta.touched && meta.error && (
              <div className="text-red-500 flex items-center">
                <FaExclamationCircle className="mr-4" />
                <span>{meta.error}</span>
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

// Border Text Input
export const BorderTextInput = ({
  name,
  label,
  type = "text",
  placeholder,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <>
          <div className="relative w-full">
            <input
              autoComplete="off"
              {...props}
              {...field}
              type={showPassword ? "text" : type}
              className={` border border-gray-400  max-sm:w-full rounded-sm p-3 w-full my-2 focus:outline-gold ${
                meta.touched && meta.error
                  ? "border-red-400"
                  : "border-gray-200"
              }`}
              placeholder={placeholder}
            />
            {type === "password" && (
              <span
                className="text-gray-500 absolute right-2 cursor-pointer transform -translate-y-1/2 top-1/2"
                onClick={handleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            )}
          </div>
          {meta.touched && meta.error && (
            <div className="text-red-500 flex items-center">
              <FaExclamationCircle className="mr-4" />
              <span>{meta.error}</span>
            </div>
          )}
        </>
      )}
    </Field>
  );
};

// Text Area
export const BorderlessTextArea = ({ name, label, placeholder, ...props }) => {
  return (
    <div className="form-group">
      <Field name={name} as="textarea">
        {({ field, meta }) => (
          <>
            <textarea
              {...props}
              {...field}
              className={`w-full text-slate-gray py-2 my-2 bg-transparent border-b  outline-none focus:outline-none ${
                meta.touched && meta.error
                  ? "border-red-400"
                  : "border-gray-400"
              }`}
              placeholder={placeholder}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500 flex items-center">
                <FaExclamationCircle className="mr-4" />
                <span>{meta.error}</span>
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

// Select Option
export const Select = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="w-2/3  max-sm:w-full">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        className={`border border-gray-400 p-3 w-2/3 focus:outline-gold ${className}`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

// Radio Btn
export const Radio = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "radio" });
  return (
    <div className="radio-btn">
      <label className="radio-input">
        <span>{children}</span>
        <input type="radio" {...field} {...props} />
      </label>
      {/* {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null} */}
    </div>
  );
};

// Custom input component
export const NumberFormatCustom = ({ field, form, ...props }) => {
  return (
    <NumericFormat
      {...field}
      {...props}
      prefix="₦"
      decimalScale={2}
      fixedDecimalScale
      // customInput={BorderlessTextInput}
      thousandSeparator
      onValueChange={({ formattedValue, value }) => {
        form.setFieldValue(field.name, formattedValue);
      }}
    />
  );
};

export const CustomDatePicker = ({
  name,
  placeholder,
  className,
  ...props
}) => {
  return (
    <div className="">
      <Field name={name}>
        {({ field, form, meta }) => (
          <>
            <DatePicker
              {...props}
              {...field}
              selected={(field.value && new Date(field.value)) || null}
              onChange={(val) => {
                const formattedDate = format(val, "yyyy-MM-dd"); // adjust the format string as needed
                form.setFieldValue(name, formattedDate);
              }}
              className={`${className} w-full text-slate-gray py-2 my-2 bg-transparent border-b  outline-none focus:outline-none ${
                meta.touched && meta.error
                  ? "border-red-400"
                  : "border-gray-400"
              }`}
              placeholderText={placeholder}
              showMonthDropdown
              showYearDropdown
              dateFormat={"yyyy-MM-dd"}
              scrollableYearDropdown
              dropdownMode="select"
              minDate={new Date("1900-01-01")} // allow dates from 1900
              maxDate={new Date("2099-12-31")}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500 flex items-center">
                <FaExclamationCircle className="mr-4" />
                <span>{meta.error}</span>
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
// Check Box
export const CheckboxGroup = ({ label, name, options, ...rest }) => {
  return (
    <div className="flex">
      <label className="w-1/3">{label}</label>
      <Field name={name} {...rest} className="flex-1">
        {({ field, meta, form }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  className="mr-2 ml-4"
                  type="checkbox"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
    </div>
  );
};

export const DropdownCheckbox = ({
  label,
  options,
  name,
  isOpen,
  toggleDropdown,
  search = false,
}) => {
  const dispatch = useDispatch();
  const [field, meta, helpers] = useField(name);

  const toggleSelect = (option) => {
    const newSelectedOptions = field.value.includes(option)
      ? field.value.filter((selectedOption) => selectedOption !== option)
      : [...field.value, option];

    helpers.setValue(newSelectedOptions);
  };

  return (
    <div>
      <button
        className="border w-full flex p-2 border-gray-400 mb-2"
        type="button"
        onClick={toggleDropdown}
      >
        {label}
      </button>
      {isOpen && (
        <div className="dropdown-menu w-full">
          {search && (
            <input
              onChange={(e) => dispatch(setSearchTerm(e.target.value))}
              type="text"
              className="border border-gray-500 w-full focus:outline-none focus:border-gold p-2 mb-2"
              placeholder="Search..."
            />
          )}
          {options.map((option) => (
            <div key={option.value}>
              <label>
                <input
                  className="mr-2"
                  type="checkbox"
                  value={option.value}
                  checked={field.value.includes(option.value)}
                  onChange={() => toggleSelect(option.value)}
                />
                {option.key}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const QuillEditor = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <ReactQuill
        {...field}
        {...props}
        onChange={(newValue, delta, source) => {
          if (source === "user") {
            helpers.setValue(newValue);
          }
        }}
        onBlur={(range, source, quill) => {
          helpers.setTouched(true);
        }}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
