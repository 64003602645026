import React, { useEffect, useState } from "react";
import temp_Logo from "../assets/images/customer1.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { defaultCountry } from "../constants";
import { updateJobSeeker } from "../store/slices/jobSeeker";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import {
  BorderTextInput,
  Select,
  DropdownCheckbox,
  CustomDatePicker,
  QuillEditor,
} from "./customFormikEle";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import useCloudinaryImageUpload from "../hooks/useImageUpload";
import { format } from "date-fns";

const JobSeekerProfileDetails = () => {
  // Handle Dropdown checkbox state
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const [selectedOptions, setSelectedOptions] = useState([]); // for the dropdown checkbox
  const { uploadToCloudinary } = useCloudinaryImageUpload();
  const [countryid, setCountryid] = useState(
    defaultCountry ? defaultCountry.id : 0
  );
  const [stateid, setstateid] = useState(0);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.jobSeeker.loading);
  const jobSeekerState = useSelector((state) => state.jobSeeker.job_seeker);
  const {
    id,
    phone_numbers,
    portfolio_website,
    state,
    country,
    city,
    email,
    description,
    profile_picture,
    education_level,
    gender,
    other_gender,
    date_of_birth,
    education_grade,
    other_grade,
    preffered_work_environment,
    instagram,
    linkedin,
    tiktok,
    twitter,
  } = jobSeekerState;

  const initialJobSeekerValue = {
    id,
    phone_numbers,
    preffered_work_environment,
    education_grade,
    other_grade,
    portfolio_website,
    state,
    gender,
    date_of_birth: new Date(date_of_birth),
    other_gender,
    country,
    city,
    email,
    description,
    profile_picture,
    education_level,
    skillset: [],
    instagram,
    linkedin,
    tiktok,
    twitter,
  };

  // setting the initial url of the profile picture from what we have in the database
  const [url, setUrl] = useState(profile_picture);

  // handle profile image upload
  const handleImageUpload = async () => {
    const transformation = [{ width: 100, height: 100, crop: "fill" }];
    const url = await uploadToCloudinary(transformation);
    setUrl(url);
    dispatch(updateJobSeeker({ value: { profile_picture: url }, id }));
  };

  // initilaize the country, state and city objects
  const [countryObject, setCountryObject] = useState(country ? country : "");
  const [stateObject, setStateObject] = useState(state ? state : "");
  const [cityObject, setCityObject] = useState(city ? city : "");
  const onSubmit = (values) => {
    if (!countryObject) {
      setCountryObject(defaultCountry.name);
    }
    if (!stateObject || !cityObject) {
      toast.error("Please select a  state and city");
      return;
    }
    const value = {
      ...values,
      date_of_birth: format(
        new Date(values.date_of_birth),
        "yyyy-MM-dd'T00:00:00.000Z'"
      ),
      country: countryObject,
      state: stateObject,
      city: cityObject,
      profile_picture: url,
      is_profile_complete: true,
      skillset: values.skillset.join(","),
    };

    dispatch(updateJobSeeker({ value, id }));
  };

  const skillSetOptions = [
    { key: "Communication", value: "communication" },
    { key: "Leadership", value: "leadership" },
    { key: "Problem Solving", value: "problemSolving" },
    { key: "Teamwork", value: "teamwork" },
    { key: "Creativity", value: "creativity" },
    { key: "Adaptability", value: "adaptability" },
    { key: "Work Ethic", value: "workEthic" },
    { key: "Time Management", value: "timeManagement" },
    { key: "Attention to Detail", value: "attentionToDetail" },
    { key: "Interpersonal Skills", value: "interpersonalSkills" },
    { key: "Technical Knowledge", value: "technicalKnowledge" },
    { key: "Programming", value: "programming" },
    { key: "MS Office", value: "microsoft_office" },
    { key: "Graphics Design", value: "graphics_design" },
    { key: "Social Media Management", value: "social_media_management" },
  ];

  useEffect(() => {}, [jobSeekerState]);

  return (
    <Formik initialValues={initialJobSeekerValue} onSubmit={onSubmit}>
      {({ values }) => (
        <Form autoComplete="off" className="max-sm:flex-col">
          <div className="p-10 max-sm:p-2 max-sm:flex-col flex-col __joyride_update_profile relative">
            <div className="font-raleway flex gap-10 justify-between  max-sm:flex-col">
              <div className="w-1/2 rounded-md bg-white p-10 max-sm:w-full max-sm:p-2">
                <div className="flex gap-5  max-sm:gap-2 max-sm:mb-4 w-full max-sm:flex-col justify justify-between mb-10">
                  <img
                    width={100}
                    height={100}
                    src={url ? url : temp_Logo}
                    className="rounded-full"
                    alt="userAvatar"
                  />
                  <span>
                    <button
                      onClick={handleImageUpload}
                      type="button"
                      className="p-4 my-6 rounded-md bg-gold hover:bg-gray-400 text-white"
                    >
                      Edit Profile Picture
                    </button>
                  </span>
                </div>
                <div className="flex gap-5 max-sm:gap-2 w-full mb-5  max-sm:flex-col">
                  <label className="font-raleway  text-slate-gray font-semibold w-1/3 max-sm:w-full">
                    Full Name:
                  </label>
                  <p className="w-2/3">{`${jobSeekerState?.first_name}  ${jobSeekerState?.last_name}`}</p>
                </div>
                <div className="flex items-center max-sm:items-start gap-4  max-sm:gap-0 max-sm:flex-col">
                  <label className="font-raleway  text-slate-gray font-semibold w-1/3 max-sm:w-full">
                    Date of Birth:
                  </label>
                  <CustomDatePicker
                    name={"date_of_birth"}
                    placeholder={"Date of Birth"}
                    className={"border border-gray-400 px-2 max-sm:w-full"}
                  />
                </div>
                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 max-sm:flex-col">
                  <label className="max-sm:w-full font-raleway mt-3 text-slate-gray font-semibold w-1/3">
                    Contact Information:
                  </label>
                  <div className="w-2/3 flex flex-col  max-sm:flex-col max-sm:w-full">
                    <p className="mt-3 max-sm:mt-0 ">{email}</p>

                    <BorderTextInput
                      required
                      type="phone"
                      placeholder="Phone Number"
                      name="phone_numbers"
                    />

                    <BorderTextInput
                      placeholder="Portfolio Website (Optional)"
                      name="portfolio_website"
                      required={false}
                    />
                  </div>
                </div>

                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5  max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3 max-sm:w-full">
                    Social Media Handles:
                  </label>
                  <div className="w-2/3 flex flex-col max-sm:w-full">
                    <BorderTextInput
                      placeholder="LinkedIn (Optional)"
                      name="linkedin"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Twitter (X) (Optional)"
                      name="twitter"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Instagram (Optional)"
                      name="instagram"
                      required={false}
                    />

                    <BorderTextInput
                      placeholder="Tiktok (Optional)"
                      name="tiktok"
                      required={false}
                    />
                  </div>
                </div>

                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 items-center max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold max-sm:w-full">
                    Gender
                  </label>
                  <Select
                    className={"w-full"}
                    name="gender"
                    defaultValue={"Select the primary industry of your company"}
                    required
                  >
                    <option value="">--Select one--</option>
                    <option className="mt-5" value="male">
                      Male
                    </option>
                    <option value="female">Female</option>
                    <option value="non_binary">Non Binary</option>
                    <option value="prefer_not_to_say">Prefer not to say</option>
                    <option value="other_gender">Others</option>
                  </Select>
                </div>

                <div className="flex w-full justify-end max-sm:flex-col">
                  <div className="w-2/3 max-sm:w-full">
                    {values.gender === "other_gender" && (
                      <BorderTextInput
                        placeholder="Specify other gender"
                        name="other_gender"
                      />
                    )}
                  </div>
                </div>

                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 items-center max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold max-sm:w-full">
                    Highest <br /> Education Level
                  </label>
                  <Select
                    className={"w-full"}
                    name="education_level"
                    defaultValue={"Select the primary industry of your company"}
                    required
                  >
                    <option value="">--Select one--</option>
                    <option className="mt-5" value="high_school">
                      High School
                    </option>
                    <option value="diploma">Diploma</option>
                    <option value="bachelors">Bachelor's Degree</option>
                    <option value="masters">Master's Degree</option>
                    <option value="doctorate">Doctorate Degree</option>
                  </Select>
                </div>
                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 items-center max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold max-sm:w-full">
                    Education Grade
                  </label>
                  <Select
                    className={"w-full"}
                    name="education_grade"
                    defaultValue={"Select the primary industry of your company"}
                    required
                  >
                    <option value="">--Select one--</option>
                    <option className="mt-5" value="first_class">
                      First Class
                    </option>
                    <option value="second_class_upper">
                      Second Class Upper
                    </option>
                    <option value="second_class_lower">
                      Second Class Lower
                    </option>
                    <option value="third_class">Third Class</option>
                    <option value="other_grades">
                      Others (Please Specify)
                    </option>
                  </Select>
                </div>

                <div className="flex w-full justify-end">
                  <div className="w-2/3">
                    {values.education_grade === "other_grades" && (
                      <BorderTextInput
                        placeholder="Specify other grade"
                        name="other_grade"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/*  */}
              <div className="w-1/2 rounded-md bg-white p-10 max-sm:p-2 max-sm:w-full">
                <h1 className="text-lg font-bold mt-10 mb-5 font-raleway text-slate-gray">
                  About Yourself
                </h1>
                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 max-sm:flex-col max-sm:mb-2">
                  <div className=" px-3 w-full">
                    <div className="px-3 w-full">
                      <QuillEditor
                        name={"description"}
                        label={"Brief summary about yourself"}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-10 max-sm:gap-2 w-full justify justify-between mb-5 max-sm:flex-col max-sm:mb-2">
                  <label className="font-raleway mt-3  text-slate-gray font-semibold w-1/3 max-sm:w-full max-sm:mt-1 ">
                    Residential Location:
                  </label>
                  <div className="w-full ">
                    <CountrySelect
                      defaultValue={defaultCountry}
                      inputClassName="focus:outline-gold"
                      autoComplete="false"
                      containerClassName="w-full text-gray-800 mb-4  border border-gray-400 rounded-md"
                      onChange={(e) => {
                        setCountryid(e.id);
                        setCountryObject(e.name);
                      }}
                      placeHolder={`${country ? country : "Select Country"}`}
                    />

                    <StateSelect
                      inputClassName="focus:outline-gold"
                      containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md"
                      autoComplete="false"
                      countryid={countryid}
                      onChange={(e) => {
                        setstateid(e.id);
                        setStateObject(e.name);
                      }}
                      placeHolder={`${state ? state : "Select State"}`}
                    />

                    <CitySelect
                      inputClassName="focus:outline-gold"
                      containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md"
                      countryid={countryid}
                      stateid={stateid}
                      autoComplete="false"
                      onChange={(e) => {
                        setCityObject(e.name);
                      }}
                      placeHolder={`${city ? city : "Select City"}`}
                    />
                  </div>
                </div>
                <div className="flex gap-10 max-sm:gap-2 w-full justify justify-between mb-5 max-sm:flex-col max-sm:mb-2">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold w-1/3 max-sm:w-full max-sm:mt-1">
                    Skills:
                  </label>
                  <div className="w-2/3 max-sm:w-full">
                    <DropdownCheckbox
                      label="Select Three (3) Options only"
                      name="skillset"
                      options={skillSetOptions}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      toggleDropdown={toggleDropdown}
                      isOpen={isOpen}
                    />
                  </div>
                </div>

                <div className="flex gap-5 max-sm:gap-2 w-full justify justify-between mb-5 items-center max-sm:flex-col">
                  <label className="font-raleway mt-3 text-slate-gray font-semibold max-sm:w-full">
                    Preferred Work Environment
                  </label>
                  <Select
                    className={"w-full"}
                    name="preffered_work_environment"
                    required
                  >
                    <option value="">--Select one--</option>
                    <option className="mt-5" value="office">
                      Onsite
                    </option>
                    <option value="remote">Remote</option>
                    <option value="hybrid">Hybrid</option>
                  </Select>
                </div>
                <div className="flex max-sm:flex-end lg:hidden md:hidden justify-between">
                  {/*<h1 className="text-lg font-bold mb-10 font-raleway text-slate-gray"></h1>*/}
                  <button
                    className=" __joyride_update_profile_btn border max-sm:flex-end p-3 mb-5 hover:bg-gray-400 hover:text-black text-white font-raleway bg-gold rounded-md"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <div className="max-sm:hidden absolute bottom-[-45px] right-0 px-10">
                <button
                  disabled={loading}
                  className="__joyride_update_profile_btn  p-3 mb-5 hover:bg-gold hover:text-white test-raleway bg-gray-300 rounded-md w-[400px]"
                  type="submit"
                >
                  {`${loading ? "Saving Changes..." : "Save Changes"}`}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default JobSeekerProfileDetails;
