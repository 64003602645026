import React from 'react'
import { WomanServices } from '../assets/images'

const OurServices = () => {
  return (
    
    <div className='flex max-sm:flex-col'>
        <div className='w-1/2 rounded-lg overflow-hidden max-sm:w-full'>
            <img src={WomanServices} alt='' />
        </div>
        <div className='w-1/2 flex-col gap-0 p-10 max-sm:p-2 max-sm:w-full'>
            <div className='text-4xl font-bold font-raleway pb-8 max-sm:pb-4 max-sm:pt-4 max-sm:w-full'>Our Services</div>
            <div className='h-[100px] font-raleway overflow-hidden pb-4  max-sm:mb-6'>
                <h2 className='text-2xl font-bold text-gray-700'>Matchmaking Services</h2>
                <p>Personalized matchmaking to connect businesses with partners, investors, and opportunities.</p>
            </div>
            <div className='h-[100px] font-raleway overflow-hidden pb-4 max-sm:mb-6'>
                <h2 className='text-2xl font-bold text-gray-700'>Networking events</h2>
                <p>Facilitating connections and knowledge sharing through organized events and conferences.</p>
            </div>
            <div className='h-[100px] font-raleway overflow-hidden pb-4'>
                <h2 className='text-2xl font-bold text-gray-700'>Advisory Services</h2>
                <p>Strategic guidance from experts to navigate and accelerate business growth.</p>
            </div>
        </div>
    </div>
  )
}

export default OurServices