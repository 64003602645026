import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import getUserType from "../utils/getUser";

const AdminRoutes = () => {
  const userType = getUserType();
  const admin = userType === "admin";

  const { isAuthenticated: user } = useSelector((state) => state.auth);

  return user && admin ? <Outlet /> : <Navigate to={"/"} replace />;
};

export default AdminRoutes;
