import { useEffect, useState } from "react";

export const useFetchCompanyData = (data) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!data) return;
    // Group data by month
    const groupedData = data?.reduce((acc, item) => {
      const month = new Date(item.created_at).toLocaleString("default", {
        month: "long",
      }); // replace 'month' with the actual property name

      if (!acc[month]) {
        acc[month] = { total: 0, approved: 0, rejected: 0, pending: 0 };
      }

      acc[month].total++;
      if (item.status === "approved") {
        // replace 'status' and 'approved' with the actual property name and value
        acc[month].approved++;
      } else if (item.status === "rejected") {
        acc[month].rejected++;
      } else if (item.status === "pending") {
        acc[month].pending++;
      }

      return acc;
    }, {});

    // Convert grouped data to array
    const processedData = Object.entries(groupedData).map(
      ([name, { total, approved, rejected, pending }]) => ({
        name,
        total,
        approved,
        rejected,
        pending,
      })
    );

    setChartData(processedData);
  }, [data]);

  return chartData;
};

const useFetchChartData = (data) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!data) return;
    // Group data by month
    const groupedData = data.reduce((acc, item) => {
      const month = new Date(item.created_at).toLocaleString("default", {
        month: "long",
      }); // replace 'created_at' with the actual property name

      if (!acc[month]) {
        acc[month] = { submitted: 0, accepted: 0 };
      }

      acc[month].submitted++;
      if (item.status === "accepted") {
        // replace 'status' and 'accepted' with the actual property name and value
        acc[month].accepted++;
      }

      return acc;
    }, {});

    // Convert grouped data to array
    const processedData = Object.entries(groupedData).map(
      ([name, { submitted, accepted }]) => ({
        name,
        submitted,
        accepted,
      })
    );

    setChartData(processedData);
  }, [data]);

  return chartData;
};

export default useFetchChartData;
