import api from "../api/interceptor";
import { BASE_URL } from "../constants";

// Fetch all notifications
export const fetchNotificationApi = async ({
  company_id = null,
  jobseeker_id = null,
}) => {
  try {
    const params = {};
    if (company_id) {
      params.company_id = company_id;
    }
    if (jobseeker_id) {
      params.jobseeker_id = jobseeker_id;
    }
    const response = await api.get(`${BASE_URL}/notifications/`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single notification
export const fetchSingleNotificationApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/notifications/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a new notification
export const createNotificationApi = async (values) => {
  try {
    const response = api.post(`${BASE_URL}/notifications/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a notification
export const updateNotificationApi = async (id, values) => {
  try {
    const response = api.put(`${BASE_URL}/notifications/${id}`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Delete a notification
export const deleteNotificationApi = async (id) => {
  try {
    const response = api.delete(`${BASE_URL}/notifications/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
