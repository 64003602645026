import { jwtDecode } from "jwt-decode";

function getUserType() {
  // Get the token from local storage
  const token = localStorage.getItem("access_token");

  // Decode the token and extract the user type
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_type;
  }

  // Return undefined if there's no token
  return undefined;
}

export default getUserType;
