import axios from "axios";
import { BASE_URL } from "../constants";
import api from "./interceptor";

// Fetch all job listings
export const fetchJobListingsApi = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/listings/`);
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single job listings
export const fetchSingleJobListingsApi = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/listings/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a new job listing
export const createJobListingApi = async (values) => {
  try {
    const response = api.post(`${BASE_URL}/listings/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a job listing
export const updateJobListingApi = async ({ value, id }) => {
  try {
    const response = api.put(`${BASE_URL}/listings/${id}/`, value, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Delete a job listing
export const deleteJobListingApi = async (id) => {
  try {
    const response = api.delete(`${BASE_URL}/listings/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
