import { domToReact } from "html-react-parser";

// Parse Ul and Li tags
export const options = {
  replace: ({ name, children }) => {
    if (name === "ul") {
      return (
        <ul className="list-disc list-inside pl-4">
          {domToReact(children, options)}
        </ul>
      );
    }

    if (name === "li") {
      return <li className="list-disc">{domToReact(children, options)}</li>;
    }
  },
};
