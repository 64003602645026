import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { membershipFeatures } from "../constants/index";
import MembershipCardList from "../pages/MembershipCardList";
import { FaAward } from "react-icons/fa";
import { updateSubscriptionPlan } from "../store/slices/subscriptions";

const MembershipCard = ({
  features = [],
  name,
  amount,
  plan_code,
  setModalContent,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.subscription);
  const { company } = useSelector((state) => state?.company?.company);

  const config = {
    email: company?.email,
    amount,
    plan: plan_code,
    currency: "NGN",
    company_id: company?.id,
    metadata: JSON.stringify({
      company_id: company?.id,
    }),
  };

  // format title
  const titleWithoutBracket = name?.split(" (")[0];

  // handle change of plan payment
  const handlePayment = async (conf) => {
    dispatch(updateSubscriptionPlan(conf)).then((res) => {
      if (res?.payload?.status) {
        setModalContent("complete-payment");
      }
    });
  };

  return (
    <div
      className={`px-10 py-14 max-sm:px-7 rounded-lg ${
        name.includes("Premium") ? "bg-pale-black" : "bg-light-gold"
      } shadow relative max-sm:w-full max-sm:flex-col`}
    >
      {name.includes("Premium") && (
        <FaAward className="text-4xl text-gold mx-auto right-4 top-4" />
      )}
      <div className="flex-col items-center justify-center">
        <h2
          className={`${
            name.includes("Premium") ? "text-white" : "text-raleway"
          } text-2xl text-center capitalize`}
        >
          {titleWithoutBracket}
        </h2>
        <span
          className={`text-center ${
            name.includes("Premium") ? "text-white" : "text-raleway"
          } text-3xl  w-full inline-block font-bold `}
        >
          {" "}
          Monthly Plan:{" "}
          {new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
          }).format(amount / 100)}
        </span>
        <ul className="text-raleway my-8 flex flex-col border-b">
          {Array.isArray(features) &&
            features.map((feature) => (
              <MembershipCardList
                title={feature?.title}
                description={feature?.description}
                name={name}
              />
            ))}
        </ul>

        <div
          disabled={loading}
          onClick={() => handlePayment(config)}
          className="px-6 absolute left-0 bottom-4 w-full flex justify-center items-center"
        >
          <button className="cursor-pointer text-center flex justify-center bg-gray-500 p-3  pt-2 text-white  rounded-md baseline hover:bg-goldLight w-full">
            {`${loading ? "loading..." : "Subscribe"}`}
          </button>
        </div>
      </div>
    </div>
  );
};

const ChangeSubscriptionPlan = ({ setModalContent }) => {
  const { plans, subscription } = useSelector((state) => state?.subscription);
  const { standard, premium } = membershipFeatures;

  return (
    <div className="w-full flex p-5 flex-items align-center items-center justify-around mx-auto">
      <div className="flex gap-10 flex-wrap">
        {plans.map((plan) => {
          let features;
          if (plan?.name === "premium") {
            features = premium?.features;
          } else features = standard?.features;

          return (
            <MembershipCard
              {...plan}
              features={features}
              setModalContent={setModalContent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChangeSubscriptionPlan;
