import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

const UserLayout = () => {
  return (
    <div>
      <ul className="flex justify-between">
        <li className="text-center w-1/2 p-2">
          <NavLink
            className=" shadow border border-gray-300 w-full inline-block p-2"
            to="employers"
          >
            Employers
          </NavLink>
        </li>
        <li className="text-center w-1/2 p-2">
          <NavLink
            className=" shadow border border-gray-300 w-full inline-block p-2"
            to="applicants"
          >
            Job Seekers
          </NavLink>
        </li>
      </ul>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default UserLayout;
