import React from 'react'

const GetStarted = () => {
  return (
    <section
      id="get_started"
      className="max-container max-sm:mt-12 font-raleway"
    >
      <div
        className="flex flex-col justify-start gap-5"
      >
        <div
        class="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row"
      >
        {/* What's differnt */}
        <div class="flex flex-col space-y-12 md:w-1/2">
          <h2 class="max-w-md text-4xl font-bold text-center text-white md:text-left">
            What's different about GSC Matchpoint?
          </h2>
          <p class="font-raleway         text-veryLightGray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
          At GSCmatchpoint, we believe in connecting businesses with their perfect matches. Our platform is designed to streamline the process of finding strategic partners, investors, and opportunities to accelerate growth and success.
          </p>
        </div>

         {/* Numbered Lists */}
        <div class="flex flex-col space-y-8 md:w-1/2">
           {/* list items 1*/}
          <div
            class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row"
          >
             {/* Heading */}
            <div class="rounded-l-full bg-goldSupLight md:bg-transparent">
              <div class="flex items-center space-x-2">
                <div
                  class="px-4 py-2 text-white rounded-full md:py-1 bg-gold"
                >
                  01
                </div>
                <h3 class="text-base font-bold md:mb-4 md:hidden">
                  Our Mission
                </h3>
              </div>
            </div>

            <div>
              <h3 class="hidden mb-4 text-lg font-bold md:block text-white">
                Our Mission
              </h3>
              <p class="font-raleway         text-veryLightGray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
              At GSCmatchpoint, our mission is to empower businesses worldwide. We provide the tools and resources necessary for thriving in today's competitive landscape. Through meaningful connections, we drive positive outcomes for our clients.
              </p>
            </div>
          </div>

           {/* List item2 */}
          <div
            class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row"
          >
             {/* Heading */}
            <div class="rounded-l-full bg-gold SupLight md:bg-transparent">
              <div class="flex items-center space-x-2">
                <div
                  class="px-4 py-2 text-white rounded-full md:py-1 bg-gold"
                >
                  02
                </div>
                <h3 class="text-base font-bold md:mb-4 md:hidden">
                  Our Vision
                </h3>
              </div>
            </div>

            <div>
              <h3 class="hidden mb-4 text-lg font-bold md:block text-white">
                Our Vision
              </h3>
              <p class="font-raleway         text-veryLightGray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
              We envision a future where every business achieves its full potential through strategic partnerships and collaborations. By creating a global network of opportunities, we aim to reshape the business landscape and drive innovation.
              </p>
            </div>
          </div>

          {/* List item3 */}
         {/*  <div
            class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row"
          >
           
            <div class="rounded-l-full bg-goldSupLight md:bg-transparent">
              <div class="flex items-center space-x-2">
                <div
                  class="px-4 py-2 text-white rounded-full md:py-1 bg-gold"
                >
                  03
                </div>
                <h3 class="text-base font-bold md:mb-4 md:hidden">
                  Our Values
                </h3>
              </div>
            </div>

            <div>
              <h3 class="hidden mb-4 text-lg font-bold md:block text-white">
                Everything you need in one place
              </h3>
              <p class="font-raleway         text-veryLightGray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
                Stop jumping from one service to another to communicate, store
                files, track tasks and share documents. Manage offers an
                all-in-one team productivity solution.
              </p>
            </div>
          </div> */}
        </div>
      </div>

      </div>
    </section>
  )
}

export default GetStarted