import React from "react";
// import { ChartsData } from '../constants'
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const TransactionCharts = ({ chartsData }) => {
  return (
    <div className="h-[23rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
      <strong className="text-gray-700 font-medium">Applications</strong>
      <div className="w-full mt-3 flex-1 text-xs">
        <ResponsiveContainer width={"100%"} height={300}>
          {/* <BarChart width={500} height={300} data={chartsData} margin={{top:20, right:10, left:-10, bottom:0}}>
                <CartesianGrid strokeDasharray='3 3 0 0' vertical={false}/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="submitted" fill="#0ea5e9"/>
                <Bar dataKey="accepted" fill="#ea580c"/>
            </BarChart> */}
          <LineChart width={500} height={300} data={chartsData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="approved" stroke="#82ca9d" />
            <Line type="monotone" dataKey="disapproved" stroke="#ff0000" />
            <Line type="monotone" dataKey="pending" stroke="#ffc658" />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TransactionCharts;
