import React from 'react'
import { skyscraper } from '../assets/images';

const AboutHero = () => {
  return (
    <section id="hero" className="w-full flex xl:flex-row flex-col justify-center min-h-screen gap-10 max-container">
    <div className="relative flex flex-col justify-center items-start w-full  max-xl:padding-x pt-28 pl-20">
        
        <div
        class="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row"
        >
        
        <div class="flex font-raleway flex-col mb-32 space-y-12 md:w-1/2">
            <h1
            class="max-w-md text-4xl text-center md:text-5xl md:text-left"
            >
            Welcome to <span className='font-bold'> GSCmatchpoint</span>
            </h1>
            <p class="font-raleway max-sm:text-center text-slate-gray text-lg leading-8 mt-6 mb-5 max-sm:mb-2 sm:max-w-sm">
            The premier platform connecting businesses with their perfect matches. Whether you're seeking strategic partners, investors, or growth opportunities, we're here to streamline your journey towards success.
            </p>
            <div class="flex justify-center md:justify-start">
            <a
                href="#"
                class="p-3 px-6 pt-2 text-white bg-gold rounded-md baseline hover:bg-goldLight"
                >Get Started</a
            >
            </div>
        </div>
        
        <div class="md:w-1/2">
            <img src={skyscraper} alt="" />
        </div>
        </div>
        
    </div>
    
  </section>
  )
}

export default AboutHero