import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import { useSelector} from "react-redux";
import { exportCsvApi } from "../../api/exportCsvApi";
import { toast } from "react-toastify";

const Nav = ({ newNotification = false }) => {
  const admin = useSelector((state) => state?.admin?.admin);
  const [loading, setLoading] = useState(false);

  // csv file exclude columns
  const exclude_columns = ["id", "user_id", "updated_at", "__class__"];

  const handleExportEmailsToCsv = async (event) => {
    try {
      setLoading(true);
      exportCsvApi({
        outputFileName: `newsletter-emails-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "newsletters",
        values: {
          exclude_columns: exclude_columns,
        },
      });
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <nav className="flex justify-between shadow h-[70px] items-center px-8">
      <div className="bg-gray-800 text-white p-2 rounded px-4 shadow cursor-pointer">
        <button onClick={handleExportEmailsToCsv}>
          {loading ? "loading..." : "Download Newsletter Emails"}
        </button>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-5">
          <div className=" relative">
            {newNotification && (
              <span className="absolute top-0 right-0 h-[8px] w-[8px] rounded-full bg-red-300"></span>
            )}
            <FaBell className="text-xl" />
          </div>
          <div className="flex items-center ">
            <span className="mr-4 w-10 h-10 rounded-full bg-gray-400 inline-block"></span>
            <span>{`${admin?.first_name} ${admin?.last_name}`}</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
