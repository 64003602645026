import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const Accordion = () => {
  const [items, setItems] = useState([
    { title: "Item 1", content: "Content for item 1", isOpen: false },
    { title: "Item 2", content: "Content for item 2", isOpen: false },
    { title: "Item 3", content: "Content for item 3", isOpen: false },
    { title: "Item 4", content: "Content for item 4", isOpen: false },
    { title: "Item 5", content: "Content for item 5", isOpen: false },
  ]);

  const toggleItem = (index) => {
    setItems(
      items.map((item, i) => ({
        ...item,
        isOpen: i === index ? !item.isOpen : false,
      }))
    );
  };

  return (
    <div className="p-8 w-1/2 max-sm:w-full max-sm:p-0">
      {items.map((item, index) => (
        <div
          key={index}
          className="w-full max-w-md  bg-white shadow-md rounded mb-4"
        >
          <div
            className="cursor-pointer flex justify-between items-center px-6 py-4 border-b"
            onClick={() => toggleItem(index)}
          >
            <h2 className="font-bold text-lg">{item.title}</h2>

            <FaAngleDown
              className={`h-6 w-6 transform transition-transform duration-200 ${
                item.isOpen ? "rotate-180" : ""
              }`}
            />
          </div>
          <div
            className={`transition-all duration-200 overflow-hidden ${
              item.isOpen ? "max-h-full" : "max-h-0"
            }`}
          >
            <div className="px-6 py-4">
              <p>{item.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
