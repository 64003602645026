import { createSlice } from "@reduxjs/toolkit";

const joyRideSlice = createSlice({
  name: "joyRide",
  initialState: {
    runTour: false,
    stepIndex: 0,
  },
  reducers: {
    setRunTour: (state, action) => {
      state.runTour = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
  },
});

export const { setRunTour, setStepIndex } = joyRideSlice.actions;

export default joyRideSlice.reducer;
