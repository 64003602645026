import React from 'react'
import { our_values } from '../constants'
import ValuesCard from './ValuesCard'

const OurValues = () => {
  return (
    <section className="max-container">
      <h3 className="font-raleway text-center text-4xl font-bold">
        Our
        <span className="text-[#ceac46]"> Values</span>
      </h3>
      <p className="text-2xl font-raleway m-auto mt-4 max-w-lg text-center">Passionate About What Matters</p>

      <div className="mt-24 flex flex-1 justify-evenly items-center max-lg:flex-col gap-14">
        {our_values.map((our_value) =>(
          <ValuesCard 
            key={our_value.label}
            imgURL={our_value.iconURL}
            customerName={our_value.label}
          />
        ))}
      </div>

    </section>
  )
}

export default OurValues