import React, { useEffect } from "react";
import Nav from "../components/Nav";
import SideBar from "../components/SideBar";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobListings } from "../../store/slices/jobListingSlice";
import { fetchNotifications } from "../../store/slices/notifications";
import { fetchCompany } from "../../store/slices/companies";
import { fetchJobSeekers } from "../../store/slices/jobSeeker";
import { fetchSubscriptions } from "../../store/slices/subscriptions";
import { fetchAdmin, fetchSingleAdmin } from "../../store/slices/admin";
import { jwtDecode } from "jwt-decode";

const AdminLayout = () => {
  const { token } = useSelector((state) => state.auth);
  const decodedToken = token && jwtDecode(token);
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );

  // Unread notifications
  const unReadNotifications = notifications?.filter(
    (notification) => !notification.is_read
  );

  const hasUnreadNotification = notifications.some(
    (notification) => notification.is_read === false
  );

  useEffect(() => {}, [notifications]);

  useEffect(() => {
    dispatch(fetchSingleAdmin(decodedToken.user_id));
    dispatch(fetchAdmin());
    dispatch(fetchNotifications({}));
    dispatch(fetchJobListings());
    dispatch(fetchCompany());
    dispatch(fetchJobSeekers());
    dispatch(fetchSubscriptions());
  }, []);
  return (
    <div className="min-h-screen flex __admin_sidebar font-raleway">
      <SideBar notificationCount={unReadNotifications.length} />
      <section className="border border-gray-800 flex-1 __admin_layout bg-gray-200">
        <Nav newNotification={hasUnreadNotification} />
        <Outlet />
      </section>
    </div>
  );
};

export default AdminLayout;
