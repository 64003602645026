import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./slices/authSlice";
import jobListingReducer from "./slices/jobListingSlice";
import companyReducer from "./slices/companies";
import jobApplicationReducer from "./slices/jobApplication";
import jobSeekerReducer from "./slices/jobSeeker";
import documentReducer from "./slices/document";
import subscriptionReducer from "./slices/subscriptions";
import searchTermReducer from "./slices/searchTerm";
import notificationReducer from "./slices/notifications";
import preferenceReducer from "./slices/preferences";
import joyRideReducer from "./slices/joyride";
import adminReducer from "./slices/admin";

// retrieve token from local storage
const token = localStorage.getItem("access_token");

// Helper function to calculate token expiration time
const getTokenExpirationTime = (token) => {
  if (!token) return null;
  const tokenParts = token.split(".");
  if (tokenParts.length < 2) return null;
  const payload = JSON.parse(atob(tokenParts[1]));
  if (!payload || !payload.exp) return null;
  return payload.exp * 1000; // Convert to milliseconds
};

// Check if token has expired on app load
export const isTokenExpired = () => {
  const expirationTime = getTokenExpirationTime(token);
  const isExpired = expirationTime && expirationTime < Date.now();

  if (isExpired) {
    // Remove the token from localStorage if it's expired
    localStorage.removeItem("access_token");
    // Remove the persisted state from localStorage
    localStorage.removeItem("persist:root");
  }
  return isExpired;
};

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["auth"], // auth will not be persisted
};

const appReducer = combineReducers({
  auth: authReducer,
  jobListing: jobListingReducer,
  company: companyReducer,
  jobApplication: jobApplicationReducer,
  jobSeeker: jobSeekerReducer,
  document: documentReducer,
  subscription: subscriptionReducer,
  searchTerm: searchTermReducer,
  notification: notificationReducer,
  preferences: preferenceReducer,
  joyRide: joyRideReducer,
  admin: adminReducer,
});

// RESET redux state on logout
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Redux Store
export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: {
    auth: {
      isAuthenticated: !!token && !isTokenExpired() ? true : false,
      token: token,
      error: null,
      loading: false,
    },
  },
});

export const persistor = persistStore(store);
