import axios from "axios";
import { BASE_URL } from "../constants";
import { refreshToken } from "../store/slices/authSlice";

let store;
const api = axios.create({
  baseURL: BASE_URL,
});

export const injectStore = (_store) => {
  store = _store;
};

api.interceptors.response.use(
  (response) => response, // Simply return the response if it was successful
  async (error) => {
    const originalRequest = error.config;

    // If the server responded with a 401 Unauthorized and this is not a retry request
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark this request as a retry

      // Try to refresh the token
      const refresh_token = {
        refresh_token: localStorage.getItem("refresh_token"),
      };

      const refreshResponse = await store.dispatch(refreshToken(refresh_token));

      if (refreshResponse.type === "auth/refreshToken/fulfilled") {
        // If the refresh was successful, store the new tokens
        localStorage.setItem(
          "access_token",
          refreshResponse.payload.access_token
        );
        localStorage.setItem(
          "refresh_token",
          refreshResponse.payload.refresh_token
        );

        // And retry the original request with the new token
        originalRequest.headers["Authorization"] =
          "Bearer " + refreshResponse.payload.access_token;
        return api(originalRequest);
      }
    }

    // If the refresh failed, or if the error was not a 401, just reject the promise
    return Promise.reject(error);
  }
);

export default api;
