import React from 'react'

const TermsOfUse = () => {
  return (
    <div className="w-full flex flex-col items-start">
    <div className="flex w-full  flex-col p-5 max-sm:p-2 flex-items items-center justify-center  mt-[100px]">
      <h1 className="font-raleway text-4xl max-sm:p-5 text-center font-semibold max-sm:text-2xl">
       Terms of Use
      </h1>
      <p className="font-raleway text-slate-gray p-10 w-2/3 max-sm:w-full max-sm:p-2">
      Welcome to GSCmatchpoint! Before you begin using our platform, we kindly ask you to read and understand the following terms and conditions. By accessing or using GSCmatchpoint, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.<br/><br/>
<strong>1. Use of Platform:</strong><br/>
o GSCmatchpoint provides a platform for businesses, employers, job seekers, investors, and partners to connect and collaborate.<br/>
o Users must be at least 18 years old to use our platform.<br/>
<strong>2. Information Accuracy:</strong><br/>
o Users are responsible for the accuracy and truthfulness of the information provided on their profiles.<br/>
o GSCmatchpoint does not guarantee the accuracy of information provided by users.<br/>
<strong>3. Networking and Partnerships:</strong><br/>
o GSCmatchpoint facilitates networking, partnerships, and collaborations but does not guarantee any specific outcomes or results.
o Users are responsible for conducting their due diligence before entering into any partnerships or agreements.<br/>
<strong>4. Confidentiality:</strong><br/>
o Users agree to maintain the confidentiality of any sensitive information shared on the platform.
o GSCmatchpoint is not liable for any breaches of confidentiality by users.<br/>
<strong>5. Content and Communications:</strong><br/>
o Users must not post any unlawful, offensive, or inappropriate content on the platform.
o GSCmatchpoint reserves the right to remove any content that violates our guidelines.<br/>
 
<strong>6. Third-Party Links:</strong><br/>
o Our platform may contain links to third-party websites or services. GSCmatchpoint is not responsible for the content or privacy practices of these third parties.<br/>
<strong>7. Intellectual Property:</strong><br/>
o All content, logos, and trademarks on GSCmatchpoint are the property of GSCmatchpoint and may not be used without permission.<br/>
<strong>8. Limitation of Liability:</strong><br/>
o GSCmatchpoint is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our platform.<br/>
<strong>9. Modification of Terms:</strong><br/>
o GSCmatchpoint reserves the right to modify these terms and conditions at any time. Users will be notified of any changes.<br/>
<strong>10. Contact Us:</strong><br/>
o If you have any questions or concerns about these terms and conditions, please contact us at admin@gscmatchpoint.com
By using GSCmatchpoint, you acknowledge that you have read, understood, and agreed to these terms and conditions. Thank you for choosing GSCmatchpoint for your networking and partnership needs!
      </p>
    </div>
    
  </div>
  )
}

export default TermsOfUse