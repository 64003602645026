import api from "../api/interceptor";
import { BASE_URL } from "../constants";


// Fetch all admins
export const fetchAdminApi = async () => {
  try {
    const response = await api.get(`${BASE_URL}/admins/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// Fetch single admin
export const fetchSingleAdminApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/admins/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// Create an admin
export const createAdminApi = async (value) => {
  try {
    const response = await api.post(`${BASE_URL}/admins/`, value, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update an admin's profile
export const updateAdminApi = async ({ value, id }) => {
  try {
    const response = await api.put(`${BASE_URL}/admins/${id}`, value, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};



// Delete an admin
export const deleteAdminApi = async (id) => {
  try {
    const response = await api.delete(`${BASE_URL}/admins/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};