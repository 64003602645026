import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchNotifications,
  updateNotification,
  deleteNotification,
} from "../store/slices/notifications";
import NotificationCard from "./NotificationCard";

const CompanyNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state?.notification?.notifications
  );

  const { company } = useSelector((state) => state?.company?.company);

  // handle read notifications
  const handleRead = (id) => {
    dispatch(updateNotification({ id, values: { is_read: true } }));
  };

  //handle Delete notification
  const handleDelete = (id) => {
    dispatch(deleteNotification(id));
  };

  useEffect(() => {
    dispatch(fetchNotifications({ company_id: company.id }));
  }, [dispatch]);
  return (
        <section className="px-8 max-h-[100vh] max-sm:px-2 overflow-auto">
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              {...notification}
              onRead={() => handleRead(notification.id)}
              onDelete={() => handleDelete(notification.id)}
            />
          ))
        ) : (
          <div className="text-3xl max-sm:text-2xl font-raleway font-bold">No Notifications available.</div>
        )}
    </section>
 
  );
};

export default CompanyNotifications;
