import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobSeekerApplications } from "../../store/slices/jobSeeker";
import profile_img from "../../assets/images/ard_working.jpg";
import parse from "html-react-parser";
import { options } from "../../utils/ulParser";

const JobSeekerSummary = ({
  id,
  first_name,
  last_name,
  profile_picture,
  email,
  phone_numbers,
  city,
  state,
  country,
  description,
}) => {
  const dispatch = useDispatch();

  const { applications, loading } = useSelector((state) => state?.jobSeeker);

  useEffect(() => {
    dispatch(fetchJobSeekerApplications(id));
  }, [id]);
  return (
    <>
      <div className="flex flex-col p-5 items-center">
        <div className="flex items-center w-2/3 justify-between">
          <div className="flex gap-5 w-full items-center">
            <img
              className="w-32 h-32 rounded-full mt-10 mr-8"
              src={profile_picture ? profile_picture : profile_img}
              alt="Profile"
            />
            <h1 className="text-2xl mt-5">{`${first_name} ${last_name}`}</h1>
          </div>
        </div>
        <div className="w-2/3 mt-5">
          <p className="font-bold text-xl">Description</p>
          <div className=" p-4 bg-gray-100 rounded mt-3">
            <p className="text-gray-500">{parse(description, options)}</p>
          </div>
        </div>
        <div className="mt-5 w-2/3 ">
          <p className="font-bold text-xl">Bio</p>
          <div className="border p-4 bg-gray-100 rounded mt-3">
            <p>
              <span className="font-bold">Email:</span> {email}
            </p>
            <p>
              <span className="font-bold">Phone:</span> {phone_numbers}
            </p>
            <p>
              <span className="font-bold">Location:</span>{" "}
              {`${city}, ${state}, ${country}`}
            </p>
          </div>
          {/* applications */}
          <div className="mt-5 w-full">
            <p className="font-bold text-xl">Applications</p>
            <ul className="border p-4 mt-2 rounded bg-gray-100">
              {applications && applications.length > 0 ? (
                applications.map((application) => (
                  <li className="flex gap-4 text-sm" key={application.id}>
                    <p>{`${application.listing.job_title}      ----  `}</p>
                    <p>{`${application.company.company_name}`}</p>
                  </li>
                ))
              ) : (
                <p>No applications</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSeekerSummary;
