import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchNotificationApi,
  fetchSingleNotificationApi,
  createNotificationApi,
  updateNotificationApi,
  deleteNotificationApi,
} from "../../api/notificationApi";
import { toast } from "react-toastify";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
  singleNotification: {},
};

// fetch all notifications
export const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async ({ ...params }, thunkApi) => {
    try {
      const response = await fetchNotificationApi({ ...params });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// fetch single notification
export const fetchSingleNotification = createAsyncThunk(
  "notification/fetchSingleNotification",
  async (id, thunkApi) => {
    try {
      const response = await fetchSingleNotificationApi(id);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Create a new notification
export const createNotification = createAsyncThunk(
  "notification/createNotification",
  async (values, { rejectWithValue }) => {
    try {
      const response = await createNotificationApi(values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update a notification
export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const response = await updateNotificationApi(id, values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete a notification
export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteNotificationApi(id);
      if (response.status === 200) {
        toast.success("Notification Deleted");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
        state.notifications.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        state.error = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.singleNotification = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // state.notifications.push(action.payload);
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        state.notifications = state.notifications.map((notification) =>
          notification.id === action.payload.id ? action.payload : notification
        );
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== action.payload
        );
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
