import React from "react";
import Button from "./Button";
import { arrowRight } from "../assets/icons";
import { useNavigate } from "react-router";
import parse from "html-react-parser";

const OtherListingsCard = ({ job_title, description, id }) => {
  const navigate = useNavigate();

  // Naviagate to application page
  const navigateToApplication = () => {
    navigate(`/apply/${id}`);
  };

  return (
    <div className="flex-1 sm:w-[350px] sm:min-w-[350px] w-full rounded-[10px] shadow-3xl max-sm:p-4 px-10 py-10">
      <form>
     
        <h3 className="mt-5 font-raleway text-3xl max-sm:text-2xl leading-normal font-bold">
          {job_title}
        </h3>
        <p className="mt-3 mb-5 break-words font-raleway text-lg leading-normal text-slate-gray line-clamp-3">
          {parse(description)}
        </p>
        <Button
          label="Apply"
          iconURL={arrowRight}
          onClick={navigateToApplication}
        />
      </form>
    </div>
  );
};

export default OtherListingsCard;
