import React, { useState } from "react";
import { BASE_URL } from "../constants";
import api from "../api/interceptor";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleContactUs = async (event) => {
    // setLoading(true);
    event.preventDefault();
    try {
      const response = await api.post(`${BASE_URL}/contact-us`, {
        email,
        subject,
        message,
      });
      if (response.status >= 200 || response.status < 300) {
        toast.success("Message sent successfully");
        setEmail("");
        setSubject("");
        setMessage("");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        // If the server returned a detailed error message, show it
        toast.error(error.response.data.detail);
      } else {
        // Otherwise, show a generic error message
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }

    console.log({ email, subject, message });
  };

  return (
    <section class="bg-white">
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 class="mt-[55px] mb-4 text-4xl tracking-tight font-raleway font-bold text-center text-gray-900">
          Contact Us
        </h2>
        <p class="mb-8 lg:mb-16 font-medium text-center text-gray-500 font-raleway dark:text-gray-400 sm:text-xl">
          Have questions or want to learn more? Get in touch with us today
        </p>
        <form onSubmit={handleContactUs} class="space-y-8">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-raleway font-medium text-gray-700"
            >
              Your email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <label
              for="subject"
              class="block mb-2 text-sm font-raleway font-medium text-gray-700"
            >
              Subject
            </label>
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              type="text"
              id="subject"
              class="block p-3 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
              placeholder="Let us know how we can help you"
              required
            />
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block mb-2 text-sm font-raleway font-medium text-gray-700"
            >
              Your message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
              rows="6"
              class="block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Leave a comment..."
              required
            ></textarea>
          </div>
          <button
            // disabled={loading}
            onClick={handleContactUs}
            type="submit"
            class={`py-3 px-5 text-sm font-raleway font-medium text-center text-white rounded-full ${
              loading ? "bg-gray-400" : "bg-gold"
            }
            sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300`}
          >
            {loading ? "Sending message..." : "Send message"}
          </button>
        </form>

        <div className="w-full flex flex-col items-start">
          <div className="flex w-full  flex-col p-5 max-sm:p-2 flex-items items-center justify-center">
            <h1 className="font-raleway text-2xl max-sm:p-5 text-center font-semibold max-sm:text-2xl">
              Contact Details
            </h1>
            <p className="font-raleway text-slate-gray text-center p-10 w-full max-sm:w-full max-sm:p-2">
              <strong>Phone number</strong>
              <br />
              08032463451
              <br />
              <br />
              <strong>Email</strong>
              <br />
              support@gscmatchpoint.com, info@gscmatchpoint.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
