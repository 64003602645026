import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { FaSearch, FaTrash } from "react-icons/fa";
import { labelBackground } from "../utils/labelColors";
import { setSearchTerm } from "../store/slices/searchTerm";
import { exportCsvApi } from "../api/exportCsvApi";
import { toast } from "react-toastify";
import { deleteJobListing } from "../store/slices/jobListingSlice";

const CompanyListings = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const dispatch = useDispatch();
  const { listings, company } = useSelector((state) => state.company.company);
  const searchTerm = useSelector((state) => state.searchTerm);

  // Handle Navigation to list of applications of a listing
  const handleListOfApplications = (id) => {
    navigate(`/user/applications/${id}`);
  };

  // handle delete listing
  const handleDeleteListing = (listing) => {
    dispatch(deleteJobListing(listing.id));
  };

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // handle csv export
  const handleExportCsv = async () => {
    try {
      exportCsvApi({
        outputFileName: `${company.company_name}-company_listings-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "companies",
        values: {
          company_id: company.id,
          exclude_columns: [
            "id",
            "company_id",
            "created_at",
            "updated_at",
            "image_url",
            "__class__",
          ],
        },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  // search filter
  const filteredListings = listings.filter((listing) => {
    // Check if the listing name matches the search term
    const matchesSearchTerm = listing.job_title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Check if the listing matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "pending":
        matchesFilter = listing.status === "pending";
        break;
      case "approved":
        matchesFilter = listing.status === "approved";
        break;
      case "disapproved":
        matchesFilter = listing.status === "disapproved";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the listing matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredListings
    ? filteredListings
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((listing) => {
          let label_bg = labelBackground(listing?.status);
          return (
            <Table.Tr
              key={listing?.id}
              className=" border-b  rounded-md"
              onClick={(event) => {
                if (!event.target.closest("[data-delete-icon]")) {
                  handleListOfApplications(listing?.id);
                }
              }}
            >
              <Table.Td className="p-4">{listing?.job_title}</Table.Td>
              <Table.Td
                className={`${label_bg} font-semibold inline-block mt-2`}
              >
                {listing?.status}
              </Table.Td>
              <Table.Td>
                {format(new Date(listing?.due_date), "dd/MM/yyyy")}
              </Table.Td>
              <Table.Td>{listing?.application_count}</Table.Td>
              <Table.Td>
                {
                  <div className="flex items-center gap-4">
                    <button
                      className="text-white p-2 px-4 font-semibold font-raleway bg-gold
                        rounded-md cursor-pointer"
                      onClick={() => handleListOfApplications(listing?.id)}
                    >
                      View
                    </button>
                    <FaTrash
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        handleDeleteListing(listing);
                      }}
                      data-delete-icon="true"
                      className="text-xl text-gray-800 cursor-pointer"
                    />
                  </div>
                }
              </Table.Td>
            </Table.Tr>
          );
        })
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);

  return (
    <div className="p-8 max-sm:p-2 max-sm:flex-col max-sm:w-full">
      <header className="flex items-center w-full">
        <h1 className="text-xl font-raleway max-sm:pl-2 font-semibold text-gray-700">
          Applications
        </h1>
      </header>
      <section className="p-8 mt-8 max-sm:p-2 max-sm:mt-2 max-sm:w-full">
        <div className="flex items-center justify-between mb-4 max-sm:flex-col">
          <div className="pl-2 border w-[420px] max-sm:w-full bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
            <FaSearch />
            <input
              onChange={(e) => dispatch(setSearchTerm(e.target.value))}
              type="text"
              className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
              placeholder="search company name, job title..."
            />
          </div>
          <div className=" flex items-center gap-5 max-sm:w-full justify-between max-sm:mt-2">
            <div className="max-sm:hidden">
              Total: <span>{filteredListings?.length}</span>
            </div>
            <select
              value={filter}
              onChange={handleFilterChange}
              name="status"
              className="flex-1 p-2 border border-gray-600"
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="disapproved">Declined</option>
            </select>
            {listings.length > 0 && (
              <button
                onClick={handleExportCsv}
                className="text-white p-2 px-4 font-semibold font-raleway bg-black
              rounded-md cursor-pointer"
              >
                Export CSV
              </button>
            )}
          </div>
        </div>

        <div className="w-full max-sm:overflow-hidden dynamic-table-width max-sm:overflow-x-scroll">
          <Table.ScrollContainer minWidth={200}>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg max-sm:overflow-hidden"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 text-white font-raleway rounded-tl-lg">
                    Position
                  </Table.Th>
                  <Table.Th className=" font-semibold text-white font-raleway">
                    Status
                  </Table.Th>
                  <Table.Th className=" font-semibold text-white font-raleway">
                    Due Date
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold text-white font-raleway">
                    Applicants
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg text-white font-raleway">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody className="font-raleway">{rows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </div>
        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(filteredListings?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </section>
    </div>
  );
};

export default CompanyListings;
