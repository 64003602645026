import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleJobListing } from "../store/slices/jobListingSlice";
import { Table, Pagination } from "@mantine/core";
import { useParams, useNavigate } from "react-router";
import { FaSearch } from "react-icons/fa";
import { setSearchTerm } from "../store/slices/searchTerm";
import { exportCsvApi } from "../api/exportCsvApi";
import { toast } from "react-toastify";

const CompanyApplicantLists = () => {
  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const searchTerm = useSelector((state) => state.searchTerm);

  const { id } = useParams(); //job listing id
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listing, applications, loading } = useSelector(
    (state) => state?.jobListing?.singleListing
  );

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // handle csv export
  const handleExportCsv = async () => {
    try {
      exportCsvApi({
        outputFileName: `${listing.job_title}-applicant_list-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "listings",
        values: {
          listing_id: listing.id,
          exclude_columns: [
            "id",
            "company_id",
            "created_at",
            "updated_at",
            "job_listing_id",
            "job_seeker_id",
            "user_id",
            "has_run_tour",
            "is_profile_complete",
            "how_did_you_hear_about_us",
            "country_code",
            "preffered_vacancy_state",
            "disabled",
            "__class__",
          ],
        },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  // Handle Navigation to list of applications of a listing
  const handleApplicantProfile = (id) => {
    navigate(`/user/applications/applicant-profile/${id}`);
  };

  // search filter
  const filteredApplications = applications?.filter((application) => {
    // Check if the application name matches the search term
    const matchesSearchTerm =
      application.job_seeker.first_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      application.job_seeker.last_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    // Check if the application matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "pending":
        matchesFilter = application.status === "pending";
        break;
      case "accepted":
        matchesFilter = application.status === "accepted";
        break;
      case "rejected":
        matchesFilter = application.status === "rejected";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the listing matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredApplications
    ? filteredApplications
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((application) => (
          <Table.Tr
            key={application.id}
            className=" border-b  rounded-md"
            onClick={() => handleApplicantProfile(application?.job_seeker_id)}
          >
            <Table.Td className="p-4">
              {application.job_seeker?.first_name}
            </Table.Td>
            <Table.Td>{application.job_seeker?.last_name}</Table.Td>
            <Table.Td>{"Yet to be decided"}</Table.Td>
            <Table.Td>{application?.status}</Table.Td>
            <Table.Td>
              {
                <button
                  className="text-white p-2 px-4 font-semibold font-raleway bg-gold
                        rounded-md cursor-pointer"
                  onClick={() =>
                    handleApplicantProfile(application?.job_seeker_id)
                  }
                >
                  View
                </button>
              }
            </Table.Td>
          </Table.Tr>
        ))
    : [];

  useEffect(() => {
    dispatch(fetchSingleJobListing(id));
  }, [id]);

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-4 max-sm:p-2">
      <header className="flex justify-between items-center mb-2">
        <h1 className="text-2xl font-semibold text-gray-700">
          {listing?.job_title}
        </h1>
      </header>

      <section className="">
        <div className="flex items-center justify-between mb-4 max-sm:flex-col">
          <div className="pl-2 border w-[420px] max-sm:w-full bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
            <FaSearch />
            <input
              onChange={(e) => dispatch(setSearchTerm(e.target.value))}
              type="text"
              className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
              placeholder="search company name, job title..."
            />
          </div>
          <div className="w-[400px] flex items-center gap-5 max-sm:w-full max-sm:mt-2">
            <div className="max-sm:hidden">
              Total: <span>{filteredApplications?.length}</span>
            </div>
            <select
              value={filter}
              onChange={handleFilterChange}
              name="status"
              className="flex-1 p-2 border border-gray-600"
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="accepted">Approved</option>
              <option value="rejected">Declined</option>
            </select>
            {applications?.length > 0 && (
              <button
                onClick={handleExportCsv}
                className="text-white p-2 px-4 font-semibold font-raleway bg-black
              rounded-md cursor-pointer"
              >
                Export CSV
              </button>
            )}
          </div>
        </div>

        <div className="w-full max-sm:overflow-hidden dynamic-table-width max-sm:overflow-x-scroll">
          <Table.ScrollContainer minWidth={200}>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg border-red-600"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg text-white">
                    First Name
                  </Table.Th>
                  <Table.Th className=" font-semibold text-white">
                    Last Name
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold text-white">
                    Experience
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold text-white">
                    Status
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg text-white">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </div>
        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(filteredApplications?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </section>
    </div>
  );
};

export default CompanyApplicantLists;
