import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { setSearchTerm } from "../../store/slices/searchTerm";
import { updateAdmin, deleteAdmin } from "../../store/slices/admin";
import { FaSearch, FaTrash } from "react-icons/fa";
import { format } from "date-fns";

const ManageAdminUsers = () => {
  const searchTerm = useSelector((state) => state.searchTerm);
  const dispatch = useDispatch();

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const admins = useSelector((state) => state.admin.admins);
  const current_admin = useSelector((state) => state.admin.admin);

  // update admin user role
  const handleAdminRole = (admin) => {
    let type = admin.type === "super" ? "normal" : "super";
    let value = { type };

    dispatch(updateAdmin({ id: admin.id, value }));
  };

  // delete admin user
  const handleDeleteAdmin = (admin) => {
    dispatch(deleteAdmin(admin.id));
  };

  // filter by admin role
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // search filter
  const filteredAdmins = admins?.filter((admin) => {
    // Check if the company name matches the search term
    const matchesSearchTerm =
      admin.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admin.last_name.toLowerCase().includes(searchTerm.toLowerCase());

    // Check if the company matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "super":
        matchesFilter = admin.type === "super";
        break;
      case "normal":
        matchesFilter = admin.type === "normal";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the admin matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredAdmins
    ? filteredAdmins
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((admin) => (
          <Table.Tr key={admin?.id} className=" border-b  rounded-md">
            <Table.Td className="p-4">{`${admin?.first_name} ${admin?.last_name}`}</Table.Td>
            <Table.Td>{admin?.email}</Table.Td>
            <Table.Td>
              {" "}
              {format(new Date(admin?.created_at), "dd/MM/yyyy")}
            </Table.Td>
            <Table.Td>{`${
              admin?.type === "super" ? "Super" : "Normal"
            }`}</Table.Td>
            {current_admin.type === "super" && (
              <Table.Td>
                <div className="flex items-center justify-start gap-5 w-full">
                  <button
                    onClick={() => handleAdminRole(admin)}
                    className={`text-white p-2 px-4 font-semibold font-raleway ${
                      admin?.type === "super" ? "bg-gold" : "bg-black"
                    } rounded-md cursor-pointe `}
                  >
                    {`${
                      admin?.type === "super"
                        ? "Make Normal Admin"
                        : "Make Super Admin"
                    }`}
                  </button>
                  <FaTrash
                    className="text-xl cursor-pointer"
                    title="delete admin"
                    onClick={() => handleDeleteAdmin(admin)}
                  />
                </div>
              </Table.Td>
            )}
          </Table.Tr>
        ))
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);
  return (
    <div>
      {" "}
      <div className="p-8">
        <section className="border bg-white rounded p-8">
          <div className="flex items-center justify-between mb-4">
            <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
              <FaSearch />
              <input
                onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                type="text"
                className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
                placeholder="search company name..."
              />
            </div>
            <div className="w-[400px] flex items-center gap-5">
              <div>
                Total: <span>{filteredAdmins?.length}</span>
              </div>
              <select
                value={filter}
                onChange={handleFilterChange}
                name="filter"
                className="flex-1 p-2 border border-gray-600"
              >
                <option value="all">All</option>
                <option value="super">Super Admin</option>
                <option value="normal">Normal Admin</option>
              </select>
            </div>
          </div>

          {/* Table */}
          <div>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg border-red-600"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                    Full Name
                  </Table.Th>
                  <Table.Th className=" font-semibold">Email</Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Date Added
                  </Table.Th>
                  <Table.Th
                    className={`text-sm font-semibold ${
                      current_admin.type !== "super" && "rounded-tr-lg"
                    }`}
                  >
                    Admin Type
                  </Table.Th>
                  {current_admin.type === "super" && (
                    <Table.Th className="text-sm font-semibold rounded-tr-lg">
                      Actions
                    </Table.Th>
                  )}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </div>
        </section>
        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(filteredAdmins?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </div>
    </div>
  );
};

export default ManageAdminUsers;
