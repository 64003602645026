import React, { useState, useEffect } from "react";
import { FaTimes, FaCheck } from "react-icons/fa";
import { format } from "date-fns";
import parse from "html-react-parser";
import { options } from "../../utils/ulParser";

const ApproveApplication = ({
  approveJobListing,
  rejectListing,
  disApprovalReason,
  setDisApprovalReason,
  job_title,
  max_salary_range,
  min_salary_range,
  country,
  state,
  city,
  due_date,
  position,
  experience,
  education_level,
  description,
  skillset,
  company_name,
  loading,
}) => {
  const [rejection, setRejection] = useState(false);

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  return (
    <div className="">
      {/* Form Button Area */}
      {rejection && (
        <div>
          <textarea
            onChange={(e) => setDisApprovalReason(e.target.value)}
            value={disApprovalReason}
            name=""
            id=""
            placeholder="Reason for rejection"
            className="border border-gray-500 w-[700px] p-2 focus:outline-gold"
          ></textarea>
        </div>
      )}
      <div className="flex items-center gap-5 my-4">
        {rejection ? (
          <>
            <button
              type="reset"
              onClick={() => setRejection(false)}
              className="flex items-center bg-red-500 text-white font-bold py-2 px-4 rounded "
            >
              <FaTimes className="mr-2" />
              Cancel
            </button>
            <button
              disabled={loading === "reject"}
              onClick={rejectListing}
              className={`flex items-center ${
                loading === "reject" ? "bg-gray-300" : "bg-gold"
              } text-white font-bold py-2 px-4 rounded `}
            >
              <FaCheck className="mr-2" />
              {loading === "reject" ? "loading..." : "Submit Rejection"}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setRejection(true)}
              className="flex items-center bg-red-500 text-white font-bold py-2 px-4 rounded "
            >
              <FaTimes className="mr-2" />
              Reject Application
            </button>
            <button
              disabled={loading === "approve"}
              onClick={approveJobListing}
              className={`flex items-center ${
                loading === "approve" ? "bg-gray-300" : "bg-gold"
              } text-white font-bold py-2 px-4 rounded `}
            >
              <FaCheck className="mr-2" />
              {loading === "approve" ? "loading..." : "Approve application"}
            </button>
          </>
        )}
      </div>

      {/* Form */}
      <div>
        <div className="p-4 my-2 font-semibold">
          Company Name:{" "}
          <span className="text-sm text-gray-500">{company_name}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Title: <span className="text-sm text-gray-500">{job_title}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Description:{" "}
          <span className="text-sm text-gray-500">
            {parse(description, options)}
          </span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Position: <span className="text-sm text-gray-500">{position}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Job Location:{" "}
          <span className="text-sm text-gray-500">{`${city} ${state}, ${country}`}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Compensation
          <div className="flex gap-5">
            <p>
              Max:{" "}
              <span className="text-sm text-gray-500">{max_salary_range}</span>
            </p>
            <p>
              Min:{" "}
              <span className="text-sm text-gray-500">{min_salary_range}</span>
            </p>
          </div>
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Experience:{" "}
          <span className="text-sm text-gray-500">{experience}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Education Level:{" "}
          <span className="text-sm text-gray-500">{education_level}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Skill Set: <span className="text-sm text-gray-500">{skillset}</span>{" "}
        </div>
        <div className="shadow rounded p-4 my-2 font-semibold">
          Expiration Date:{" "}
          <span className="text-sm text-gray-500">
            {" "}
            {format(new Date(due_date), "dd/MM/yyyy")}
          </span>{" "}
        </div>
      </div>

      {/*  */}
    </div>
  );
};

export default ApproveApplication;
