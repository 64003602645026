// import api from "./interceptor";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import api from "./interceptor";

// export csv
export const exportCsvApi = async ({
  resource,
  values,
  outputFileName = "download.csv",
}) => {
  try {
    const response = await api.post(
      `${BASE_URL}/${resource}/export-csv/`,
      values,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: "blob",
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    if (error.response) {
      throw error.message;
    }
    if (error.request) {
      // The request was made but no response was received
      throw "No response received for the request.";
    } else {
      throw "An error occurred. Please try again later.";
    }
  }
};
