import React from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BorderTextInput } from "../../components/customFormikEle";
import { FaExclamationTriangle } from "react-icons/fa";
import { createAdmin } from "../../store/slices/admin";
import * as Yup from "yup";

const CreateAdminUser = () => {
  const dispatch = useDispatch();
  // Formik Initial Values
  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
  });

  //   Submit Form
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const value = { ...values, password: "@GSCMatch123" };
    dispatch(createAdmin({ value, resetForm }));
  };
  return (
    <div className="flex flex-col items-center">
      <div className="font-bold text-2xl mt-4">Create Admin User</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="w-[520px]">
              <BorderTextInput name="email" type="email" placeholder="Email" />
              <BorderTextInput name="first_name" placeholder="First Name" />
              <BorderTextInput name="last_name" placeholder="Last Name" />
              <div class="mt-5">
                <button
                  type="submit"
                  class={`w-full bg-gold
                  } py-3 text-center text-white rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-4 mt-4">
              <FaExclamationTriangle className="text-gold text-2xl" />
              <span>
                Admin users will be created with a default password of{" "}
                <span className="text-gray-800 text-lg font-bold bg-gray-100 px-2">
                  @GSCMatch123
                </span>
                <br />
                Please notify the user to change the password after login
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateAdminUser;
