import getUserType from "../utils/getUser";
import JobSeekerSupport from "./JobSeekerSupport";
import CompanySupport from "./CompanySupport";

const Support = () => {
  const userType = getUserType();

  return (
    <>{userType === "company" ? <CompanySupport /> : <JobSeekerSupport />}</>
  );
};

export default Support;
