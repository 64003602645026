import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchDocumentApi,
  fetchSingleDocumentApi,
  createDocumentApi,
  deleteDocumentApi,
  updateDocumentApi,
} from "../../api/documentApi";

const initialState = {
  documents: [],
  loading: false,
  error: null,
  document: {},
};

export const fetchDocuments = createAsyncThunk(
  "document/fetchDocuments",
  async ({ token, ...params }, thunkApi) => {
    try {
      const response = await fetchDocumentApi({ token, ...params });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const fetchSingleDocument = createAsyncThunk(
  "document/fetchSingleDocument",
  async (id, thunkApi) => {
    try {
      const response = await fetchSingleDocumentApi(id);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createDocument = createAsyncThunk(
  "document/createDocument",
  async (values, { rejectWithValue }) => {
    try {
      const response = await createDocumentApi(values);
      if (response.status >= 200 && response.status < 300) {
        toast.success("New Document Successfully Created");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateDocument = createAsyncThunk(
  "document/updateDocument",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const response = await updateDocumentApi(id, values);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Document Successfully Updated");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "document/deleteDocument",
  async (id, thunkApi) => {
    try {
      const response = await deleteDocumentApi(id);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Document Successfully Deleted");
      }
      return { id };
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
        state.error = null;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.documents = [];
      })
      .addCase(fetchSingleDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.document = action.payload;
        state.error = null;

      })
      .addCase(fetchSingleDocument.rejected, (state, action) => {
        state.loading = false;
        state.document = {};
        state.error = action.payload;
      })
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents.push(action.payload);
        state.error = null;

      })
      .addCase(createDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documents = state.documents.map((document) =>
          document.id === action.payload.id ? action.payload : document
        );
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        state.documents = state.documents.filter(
          (document) => document.id !== action.payload.id
        );
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default documentSlice.reducer;