import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

function SettingsLayout() {
  return (
    <div>
      <ul className="flex justify-between">
        <li className="text-center w-1/2 p-2">
          <NavLink
            className=" shadow border border-gray-300 w-full inline-block p-2"
            to="create-admin-user"
          >
            Create New Admin User
          </NavLink>
        </li>
        <li className="text-center w-1/2 p-2">
          <NavLink
            className=" shadow border border-gray-300 w-full inline-block p-2"
            to="update-password"
          >
            Update Password
          </NavLink>
        </li>
        <li className="text-center w-1/2 p-2">
          <NavLink
            className=" shadow border border-gray-300 w-full inline-block p-2"
            to="manage-admin-users"
          >
            Manage Admin Users
          </NavLink>
        </li>
      </ul>
      <section>
        <Outlet />
      </section>
    </div>
  );
}

export default SettingsLayout;
