import React from "react";
import Accordion from "./Accordion";

const CompanySupport = () => {
  return (
    <div className="p-8 w-4/5 border mx-auto max-sm:w-full max-sm:p-2">
      <h1 className="text-4xl text-center mb-8 max-sm:text-2xl font-semibold">Supports & FAQs</h1>

      <div className="flex justify-between lg:items-center max-sm:flex-col">
        {/* Accordion */}
        <Accordion />
        <div>
          <h2 className="text-2xl">Contact Us</h2>
          <p className="text-lg">Email: support@gscmatchpoint.com<br/>
              info@gscmatchpoint.com</p>
          <p className="text-lg">Phone: 08032463451</p>
          <ul className="flex">
            <span>social media handles</span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanySupport;
