import React, { useState } from "react";
import Button from "../components/Button";
import { BASE_URL } from "../constants";
import api from "../api/interceptor";
import { toast } from "react-toastify";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubscription = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await api.post(`${BASE_URL}/newsletters`, { email });
      if (response.status >= 200 || response.status < 300) {
        toast.success("Subscription to news letter successful");
        setEmail("");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        // If the server returned a detailed error message, show it
        toast.error(error.response.data.detail);
      } else {
        // Otherwise, show a generic error message
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <section
      id="contact-us"
      className="max-container flex justify-between items-center max-lg:flex-col gap-10"
    >
      <h3 className="max-sm:text-center text-4xl leading-[68px] lg:max-w-md font-raleway font-bold">
        Sign up
        <span className="text-[#ceac46]"> for updates </span> and Newsletters
      </h3>

      <div className="lg:max-w-[40%] w-full flex items-center max-sm:flex-col gap-5 p-2.5 sm:border sm:border-slate-gray rounded-md">
        <input
          type="text"
          placeholder="subscribe@example.com"
          className="input text-black"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="flex max-sm:justify-center items-center max-sm:full max-sm:w-full ">
          {loading ? (
            <div>Loading</div>
          ) : (
            <Button label="Sign Up" onClick={handleSubscription} />
          )}
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
