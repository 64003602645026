import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Routes } from "./router/routes";
import { ToastContainer } from "react-toastify";
import { MantineProvider } from "@mantine/core";
import "react-toastify/dist/ReactToastify.css";

const App = () => {

  return (
    <>
      <MantineProvider>
        <RouterProvider router={Routes} />
        <ToastContainer />
      </MantineProvider>
    </>
  );
};

export default App;
