import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginUserApi,
  googleLoginApi,
  signupUserApi,
  verifyEmailApi,
  requestNewTokenApi,
  refreshAccessTokenApi,
  updatePasswordApi,
  googleSignupApi,
  deleteUserApi,
} from "../../api/authApi";
import { toast } from "react-toastify";

const initialState = {
  isAuthenticated: false,
  token: null,
  error: null,
  loading: false,
  isRegistered: false,
  openModal: false,
};

// Generate pending, fulfilled and rejected action types
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (cred, { rejectWithValue }) => {
    try {
      const response = await loginUserApi(cred);
      const data = response.data;
      const token = data.access_token;
      const refresh_token = data.refresh_token;

      if (response.status >= 200 && response.status < 300) {
        toast.success("Login Successful");
      }
      // save token and userdetails to local storage
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refresh_token);
      return data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Google Login
export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (access_token, { rejectWithValue }) => {
    try {
      const response = await googleLoginApi(access_token);
      const data = response.data;
      const token = data.access_token;
      const refresh_token = data.refresh_token;
      if (response.status >= 200 && response.status < 300) {
        toast.success("Login Successful");
      }
      // save token and userdetails to local storage
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refresh_token);
      return data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Sign up a new user
export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async ({ values, resetForm }, { rejectWithValue }) => {
    try {
      const response = await signupUserApi(values);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Registration Successful");
        resetForm();
      }

      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Google Signup
export const googleSignup = createAsyncThunk(
  "auth/googleSignup",
  async ({ value, access_token }, { rejectWithValue }) => {
    try {
      const response = await googleSignupApi({ value, access_token });
      const data = response.data;
      const token = data.access_token;
      const refresh_token = data.refresh_token;
      if (response.status >= 200 && response.status < 300) {
        toast.success("Registration Successful");
      }
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refresh_token);
      return data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Verify Email
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (otp, { rejectWithValue }) => {
    try {
      const response = await verifyEmailApi(otp);
      const data = response.data;
      const token = data.access_token;
      const refresh_token = data.refresh_token;
      if (response.status >= 200 && response.status < 300) {
        toast.success("Email Verification Successful");
      }
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refresh_token);
      return data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Request new token
export const requestNewToken = createAsyncThunk(
  "auth/requestNewToken",
  async (email, { rejectWithValue }) => {
    try {
      const response = await requestNewTokenApi(email);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Token Request Successful");
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// New Access Token from Refresh Token
export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (refresh_token, { rejectWithValue }) => {
    try {
      const response = await refreshAccessTokenApi(refresh_token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update Password
export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ values, resetForm }, { rejectWithValue }) => {
    try {
      const response = await updatePasswordApi(values);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Password Update Successful");
        resetForm();
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

// Delete User
export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteUserApi(id);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Account Successfully Deleted");
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      localStorage.removeItem("persist:root");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
    closeModal: (state) => {
      state.openModal = false;
    },
    resetIsRegistered: (state) => {
      state.isRegistered = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
        state.error = null;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.token = null;
        state.isAuthenticated = false;

        state.loading = false;
        state.error = action.payload;
      })
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
        state.error = null;
        state.loading = false;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.token = null;
        state.isAuthenticated = false;

        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(signupUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.isRegistered = true;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isRegistered = false;
        state.isAuthenticated = false;
      })
      .addCase(googleSignup.pending, (state) => {
        state.loading = true;
      })
      .addCase(googleSignup.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
      })
      .addCase(googleSignup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
        state.error = null;
        state.loading = false;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.token = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(requestNewToken.pending, (state) => {
        state.loading = true;
        state.openModal = false;
      })
      .addCase(requestNewToken.fulfilled, (state) => {
        state.loading = true;
        state.openModal = true;
      })
      .addCase(requestNewToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
        state.error = null;

        state.loading = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.openModal = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.openModal = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, closeModal, resetIsRegistered } = authSlice.actions;

export default authSlice.reducer;
