import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { setSearchTerm } from "../../store/slices/searchTerm";
import { labelBackground } from "../../utils/labelColors";
import { FaSearch } from "react-icons/fa";
import { currencyFormatter } from "../../utils/numberFormatter";
import { format } from "date-fns";

const Subscribers = () => {
  const searchTerm = useSelector((state) => state.searchTerm);

  const { subscriptions } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // search filter
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    // Check if the subscription name matches the search term
    const matchesSearchTerm =
      // subscription.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      subscription.interval.toLowerCase().includes(searchTerm.toLowerCase());

    // Check if the subscription matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "pending":
        matchesFilter = subscription.interval === "pending";
        break;
      case "approved":
        matchesFilter = subscription.interval === "approved";
        break;
      case "disapproved":
        matchesFilter = subscription.interval === "disapproved";
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the subscription matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredSubscriptions
    ? filteredSubscriptions
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((subscriber) => {
          let label_bg = labelBackground(subscriber.status);
          return (
            <Table.Tr key={subscriber?.id} className=" border-b  rounded-md">
              <Table.Td className="p-4">{subscriber?.company_name}</Table.Td>
              <Table.Td>{subscriber?.plan_name}</Table.Td>
              <Table.Td>
                {" "}
                {`${
                  subscriber.next_payment_date
                    ? format(new Date(subscriber.start_date), "dd/MM/yyyy")
                    : "N/A"
                }`}
              </Table.Td>
              <Table.Td>
                {" "}
                {`${
                  subscriber.next_payment_date
                    ? format(
                        new Date(subscriber.next_payment_date),
                        "dd/MM/yyyy"
                      )
                    : "N/A"
                }`}
              </Table.Td>
              <Table.Td>{subscriber?.status}</Table.Td>
              <Table.Td>
                {currencyFormatter.format(subscriber?.plan_price)}
              </Table.Td>
            </Table.Tr>
          );
        })
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);

  return (
    <>
      <div>
        <h1 className="px-8 text-2xl font-bold mt-4">All Subscribers</h1>
        <div className="p-8">
          <section className="border bg-white rounded p-8">
            <div className="flex items-center justify-between mb-4">
              <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
                <FaSearch />
                <input
                  onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                  type="text"
                  className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
                  placeholder="search company name, job title..."
                />
              </div>
              <div className="w-[400px] flex items-center gap-5">
                <div>
                  Total: <span>{filteredSubscriptions?.length}</span>
                </div>
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  name="status"
                  className="flex-1 p-2 border border-gray-600"
                >
                  <option value="">All</option>
                  <option value="pending">Monthly</option>
                  <option value="approved">Quarterly</option>
                  <option value="disapproved">Bi Annually</option>
                  <option value="disapproved">Yearly</option>
                </select>
                {subscriptions.length > 0 && (
                  <button
                    // onClick={handleExportCsv}
                    className="text-white p-2 px-4 font-semibold font-raleway bg-black
              rounded-md cursor-pointer"
                  >
                    Export CSV
                  </button>
                )}
              </div>
            </div>

            {/* Table */}
            <div>
              <Table
                // verticalSpacing={"md"}
                className="w-full  bg-white rounded-lg border-red-600"
                striped
                highlightOnHover
              >
                <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                  <Table.Tr className="text-left rounded-lg bg-gold">
                    <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                      Company Name
                    </Table.Th>
                    <Table.Th className=" font-semibold">Plan Type</Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      Start Date
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      Next Payment
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold">
                      Status
                    </Table.Th>
                    <Table.Th className="text-sm font-semibold rounded-tr-lg">
                      Plan Price
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </div>
          </section>
          {/* Pagination */}
          <div className="flex justify-center mt-6">
            <Pagination
              total={
                Math.ceil(filteredSubscriptions?.length / rowsPerPage) || 1
              }
              value={currentPage}
              onChange={setCurrentPage}
              color="#333"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
