import { star } from "../assets/icons";
import img from "../assets/images/ard_working.jpg";
import { useNavigate } from "react-router";
import { currencyFormatter } from "../utils/numberFormatter";

const ListingCard = ({ job_title, min_salary_range, id, image_url }) => {
  const navigate = useNavigate();

  // Naviagate to application page
  const navigateToApplication = () => {
    navigate(`/apply/${id}`);
  };

  return (
    <div
      onClick={navigateToApplication}
      className="flex flex-1 flex-col w-full max-sm:w-full cursor-pointer group mb-8 max-sm:mb-2 max-sm:w-1/2 max-sm:h-[210px]"
    >
      <div className="relative overflow-hidden w-[280px] h-[180px] max-sm:w-[140px]">
        <img
          src={image_url || img}
          alt={image_url || "Job Image"}
          className="w-[280px] h-[180px] rounded-md max-sm:w-full  max-sm:h-[140px]"
        />
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-200 rounded-xl  max-sm:w-full  max-sm:h-[140px]"></div>
      </div>
      {/* <div className="mt-2 flex justify-start gap-2.5">
        <img
          className="max-sm:h-[18px]"
          src={star}
          alt="Rating"
          width={24}
          height={24}
        />
        <p className="font-raleway text-xl  max-sm:text-sm max-sm:text-sm leading-normal text-slate-gray">
          (4.5)
        </p>
      </div> */}
      <h3 className="mt-2 text-xl leading-normal  max-sm:text-sm font-semibold font-raleway line-clamp-none">
        {job_title}
      </h3>
      <p className="font-semibold font-raleway text-[#ceac46]  max-sm:text-sm text-2xl leading-small">
        {currencyFormatter.format(min_salary_range)}
      </p>
    </div>
  );
};

export default ListingCard;
