import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchJobListingsApi,
  fetchSingleJobListingsApi,
  createJobListingApi,
  updateJobListingApi,
  deleteJobListingApi,
} from "../../api/jobListingApi";

const initialState = {
  listings: [],
  loading: false,
  error: null,
  singleListing: {},
};

// fetch all listings
export const fetchJobListings = createAsyncThunk(
  "jobListing/fetchJobListings",
  async (_, thunkApi) => {
    try {
      const response = await fetchJobListingsApi();
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// fetch single listing
export const fetchSingleJobListing = createAsyncThunk(
  "jobListing/fetchSingleJobListing",
  async (id, thunkApi) => {
    try {
      const response = await fetchSingleJobListingsApi(id);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Create a new job listing
export const createJobListing = createAsyncThunk(
  "jobListing/createJobListing",
  async ({ values, callBack }, { rejectWithValue }) => {
    const { resetForm, navigateToApplications } = callBack;

    try {
      const response = await createJobListingApi(values);
      if (response.status >= 200 && response.status < 300) {
        toast.success("New Listing Successfully Created");
        resetForm();
        navigateToApplications();
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.detail);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Update a job listing
export const updateJobListing = createAsyncThunk(
  "jobListing/updateJobListing",
  async ({ value, id }, { rejectWithValue }) => {
    try {
      const response = await updateJobListingApi({ value, id });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Listing Successfully Updated");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete a job listing
export const deleteJobListing = createAsyncThunk(
  "jobListing/deleteJobListing",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteJobListingApi(id);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Listing Successfully Deleted");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobListingSlice = createSlice({
  name: "jobListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobListings.fulfilled, (state, action) => {
        state.loading = false;
        // Sort job listings by created_at date, newer first
        const sortedListings = action.payload.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        state.listings = sortedListings;
        state.error = null;
      })
      .addCase(fetchJobListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.listings = [];
      })
      .addCase(fetchSingleJobListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleJobListing.fulfilled, (state, action) => {
        state.loading = false;
        state.singleListing = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleJobListing.rejected, (state, action) => {
        state.loading = false;
        state.singleListing = {};
        state.error = action.payload;
      })
      .addCase(createJobListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(createJobListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listings.push(action.payload);
        state.listingCreated = true;
        state.error = null;
      })
      .addCase(createJobListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateJobListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateJobListing.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.listings = state.listings.map((listing) =>
          listing.id === action.payload.id ? action.payload : listing
        );
      })
      .addCase(updateJobListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteJobListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteJobListing.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.listings = state.listings.filter(
          (listing) => listing.id !== action.meta.arg
        );
      })
      .addCase(deleteJobListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default jobListingSlice.reducer;
