import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination } from "@mantine/core";
import { setSearchTerm } from "../../store/slices/searchTerm";
import { FaBan, FaSearch, FaCheckCircle } from "react-icons/fa";
import { updateCompany } from "../../store/slices/companies";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { format } from "date-fns";
import EmployerSummary from "./EmployerSummary";
import { exportCsvApi } from "../../api/exportCsvApi";
import { toast } from "react-toastify";

const AllEmployers = () => {
  const searchTerm = useSelector((state) => state.searchTerm);
  const dispatch = useDispatch();

  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [loading, setLoading] = useState(false);

  const [opened, { open, close }] = useDisclosure(false); //control modal

  // pagination property
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const employers = useSelector((state) => state?.company?.companies);

  // csv file exclude columns
  const exclude_columns = [
    "id",
    "user_id",
    "created_at",
    "updated_at",
    "job_listing_id",
    "job_seeker_id",
    "user_id",
    "has_run_tour",
    "is_profile_complete",
    "country_code",
    "__class__",
  ];

  // handle csv export
  const handleExportCsv = async () => {
    try {
      setLoading(true);
      exportCsvApi({
        outputFileName: `employers-${
          new Date().toISOString().split("T")[0]
        }.csv`,
        resource: "companies",
        values: {
          exclude_columns: exclude_columns,
        },
      });
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  // filter by subscription plan
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // handle model content
  const showModalContent = (employer) => {
    setSelectedEmployer(employer);
    open();
  };

  const banOrUnbanEmployer = (employer) => {
    let value;
    if (employer.disabled) {
      value = { disabled: false };
    } else {
      value = { disabled: true };
    }
    dispatch(
      updateCompany({
        value,
        id: employer.id,
      })
    );
  };

  // search filter
  const filteredEmployers = employers.filter((company) => {
    // Check if the company name matches the search term
    const matchesSearchTerm = company.company_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Check if the company matches the selected filter
    let matchesFilter;
    switch (filter) {
      case "all":
        matchesFilter = true;
        break;
      case "premium":
        matchesFilter = company.subscription_plan === true;
        break;
      case "standard":
        matchesFilter = company.subscription_plan === false;
        break;
      default:
        matchesFilter = true;
    }

    // Return true if the company matches both the search term and the filter
    return matchesSearchTerm && matchesFilter;
  });

  // table rows
  const rows = filteredEmployers
    ? filteredEmployers
        .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
        .map((employer) => (
          <Table.Tr
            key={employer?.id}
            className=" border-b  rounded-md"
            onClick={(event) => {
              if (!event.target.closest("[data-ban-icon]")) {
                showModalContent(employer);
              }
            }}
          >
            <Table.Td className="p-4">{employer?.company_name}</Table.Td>
            <Table.Td>{employer?.email}</Table.Td>
            <Table.Td>
              {" "}
              {format(new Date(employer?.created_at), "dd/MM/yyyy")}
            </Table.Td>
            <Table.Td>{`${
              employer?.subscription_plan ? "premium" : "standard"
            }`}</Table.Td>
            <Table.Td
              className="cursor-pointer"
              data-ban-icon="true"
              onMouseDown={(event) => {
                event.stopPropagation();
                banOrUnbanEmployer(employer);
              }}
            >
              {employer?.disabled ? (
                <FaCheckCircle title="enable user" />
              ) : (
                <FaBan title="disable user" />
              )}
            </Table.Td>
          </Table.Tr>
        ))
    : [];

  useEffect(() => {
    dispatch(setSearchTerm(""));
  }, []);
  return (
    <>
      <div className="p-8">
        <section className="border bg-white rounded p-8">
          <div className="flex items-center justify-between mb-4">
            <div className="pl-2 border w-[420px] bg-gray-100 flex gap-3 items-center border-gray-500 rounded">
              <FaSearch />
              <input
                onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                type="text"
                className="flex-1 h-full p-2 focus:outline-none rounded bg-transparent"
                placeholder="search company name..."
              />
            </div>
            <div className="w-[400px] flex items-center gap-5">
              <div>
                Total: <span>{filteredEmployers?.length}</span>
              </div>
              <select
                value={filter}
                onChange={handleFilterChange}
                name="filter"
                className="flex-1 p-2 border border-gray-600"
              >
                <option value="all">All</option>
                <option value="premium">Premium</option>
                <option value="standard">Standard</option>
              </select>
              {employers?.length > 0 && (
                <button
                  disabled={loading}
                  onClick={handleExportCsv}
                  className={`text-white p-2 px-4 font-semibold font-raleway ${
                    loading ? "bg-gray-300" : "bg-black"
                  }
              rounded-md cursor-pointer`}
                >
                  {loading ? "loading..." : "Export CSV"}
                </button>
              )}
            </div>
          </div>

          {/* Table */}
          <div>
            <Table
              // verticalSpacing={"md"}
              className="w-full  bg-white rounded-lg border-red-600"
              striped
              highlightOnHover
            >
              <Table.Thead className="rounded-lg text-gray-800 bg-gold">
                <Table.Tr className="text-left rounded-lg bg-gold">
                  <Table.Th className=" font-semibold p-4 rounded-tl-lg">
                    Company Name
                  </Table.Th>
                  <Table.Th className=" font-semibold">Email</Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Date Registered
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold">
                    Active Plan
                  </Table.Th>
                  <Table.Th className="text-sm font-semibold rounded-tr-lg">
                    Action
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </div>
        </section>
        {/* Pagination */}
        <div className="flex justify-center mt-6">
          <Pagination
            total={Math.ceil(filteredEmployers?.length / rowsPerPage) || 1}
            value={currentPage}
            onChange={setCurrentPage}
            color="#333"
          />
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title="Employer Seeker Details"
        size={"70%"}
      >
        <EmployerSummary {...selectedEmployer} />
      </Modal>
    </>
  );
};

export default AllEmployers;
