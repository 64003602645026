import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPreferencesApi,
  fetchSinglePreferenceApi,
} from "../../api/preferenceApi";

const initialState = {
  preferences: [],
  loading: false,
  error: null,
  preference: {},
};

export const fetchPreferences = createAsyncThunk(
  "preferences/fetchPreferences",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPreferencesApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSinglePreference = createAsyncThunk(
  "preferences/fetchSinglePreference",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchSinglePreferenceApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.preferences = action.payload;
        state.error = null;
      })
      .addCase(fetchPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSinglePreference.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSinglePreference.fulfilled, (state, action) => {
        state.loading = false;
        state.preference = action.payload;
        state.error = null;
      })
      .addCase(fetchSinglePreference.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default preferencesSlice.reducer;
