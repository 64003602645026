import React from "react";
import AdminDashBoardStatGrid from "./AdminDashBoardStatGrid";
import {
  FaGem,
  FaHourglassStart,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

const AdminDashBoard = () => {
  const jobSeekers = useSelector((state) => state?.jobSeeker?.job_seekers);
  const employers = useSelector((state) => state?.company?.companies);
  const listings = useSelector((state) => state?.jobListing?.listings);
  const applications = useSelector((state) => state?.jobApplication.applications);

  return (
    <div className="p-5 flex gap-4 flex-col">
      <AdminDashBoardStatGrid
        title={"Total Employers"}
        Icon={FaGem}
        numbers={employers?.length}
      />
      <AdminDashBoardStatGrid
        title={"Total Job Seekers"}
        Icon={FaGem}
        numbers={jobSeekers?.length}
      />
      <AdminDashBoardStatGrid
        title={"Total Job Listings"}
        Icon={FaGem}
        numbers={listings?.length}
      />
  
      <AdminDashBoardStatGrid
        title={"Total Job Applications"}
        Icon={FaGem}
        numbers={applications?.length}
      />
  
    </div>
  );
};

export default AdminDashBoard;
