import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchNotifications,
  updateNotification,
  deleteNotification,
} from "../store/slices/notifications";
import NotificationCard from "./NotificationCard";

const JobSeekerNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const jobseeker = useSelector((state) => state.jobSeeker.job_seeker);

  // handle read notifications
  const handleRead = (id) => {
    dispatch(updateNotification({ id, values: { is_read: true } }));
  };

  //handle Delete notification
  const handleDelete = (id) => {
    dispatch(deleteNotification(id));
  };

  useEffect(() => {
    dispatch(fetchNotifications({ jobseeker_id: jobseeker.id }));
  }, [dispatch]);

  return (
    <section className="px-8 max-h-[100vh] overflow-auto max-sm:p-2">
      <section>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              {...notification}
              onRead={() => handleRead(notification.id)}
              onDelete={() => handleDelete(notification.id)}
            />
          ))
        ) : (
          <div className="text-3xl font-bold max-sm:text-xl">Notifications Box is Empty</div>
        )}
      </section>
    </section>
  );
};

export default JobSeekerNotifications;
