import getUserType from "../utils/getUser";
import CompanyDocuments from "./CompanyDocuments";
import JobSeekerDocuments from "./JobSeekerDocuments";

const Documents = () => {
  const userType = getUserType();

  return (
    <>
      {userType === "company" ? <CompanyDocuments /> : <JobSeekerDocuments />}
    </>
  );
};

export default Documents;
