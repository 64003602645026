import React from "react";
import Nav from "../components/Nav";
import { Footer } from "../sections";
import { Outlet } from "react-router";
import ScrollToTop from "../components/ScrollToTop";

function PublicLayout() {
  return (
    <main>
      <ScrollToTop/>
      <Nav />
      <section>
        <Outlet />
      </section>
      <section className="bg-black padding-x padding-t pb-8">
        <Footer />
      </section>
    </main>
  );
}

export default PublicLayout;
