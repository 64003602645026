import { useState } from "react";

const useCloudinaryWidget = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState(null);

  const uploadToCloudinary = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setError(null);

      window.cloudinary.openUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
          sources: [
            "local",
            "url",
            "camera",
            "image_search",
            "facebook",
            "dropbox",
          ],
          showAdvancedOptions: true,
          access_mode: "public",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setLoading(false);
            setUrl(result.info.secure_url);
            setFileName(result.info.original_filename);
            resolve({
              url: result.info.secure_url,
              fileName: result.info.original_filename,
            }); // Resolve the Promise with the URL and fileName
          } else if (result && result.event === "close") {
            setLoading(false);
            reject("Upload cancelled by user");
          } else if (error) {
            setLoading(false);
            setError("Failed to upload image");
            reject(error); // Reject the Promise with the error
          }

          setLoading(false);
        },
        (progress) => {
          setProgress(progress);
        }
      );
    });
  };

  return {
    uploadToCloudinary,
    loading,
    progress,
    url,
    error,
    fileName,
    setLoading,
  };
};

export default useCloudinaryWidget;
