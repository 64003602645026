import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleJobSeeker } from "../store/slices/jobSeeker";
import profile_img from "../assets/images/ard_working.jpg";
import { FaDownload } from "react-icons/fa";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { createNotification } from "../store/slices/notifications";
import { updateJobApplication } from "../store/slices/jobApplication";
import { options } from "../utils/ulParser";
import parse from "html-react-parser";

// Logic to either accept or reject an applicant's application
const ApprovalApplication = ({
  onCancel,
  isApproved,
  handleApproveApplication,
  handleDisApproveApplication,
  param,
}) => {
  return (
    <>
      {isApproved ? (
        <div className="text-sm text-gray-500">
          <p>
            You are about to{" "}
            <span className="font-bold text-black mx-2">PROCEED</span> this
            applicant's application
          </p>
          <p className="my-3">
            Note: A follow up mail will be sent to the candidate notifying them
            that their application has been approved for the next stage
          </p>
          <p>
            Kindly reach out to candidates through their various communication
            mediums for further accessment
          </p>

          <div className="flex items-center gap-5 mt-4">
            <button
              onClick={onCancel}
              type="reset"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-gold text-white font-bold py-2 px-4 rounded"
              onClick={() => handleApproveApplication(param)}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-500">
          <p>
            You are about to{" "}
            <span className="font-bold text-red-500 mx-2">REJECT</span> this
            applicant's application
          </p>
          <p className="my-3">
            Note: A follow up mail will be sent to the candidate notifying them
            that their application has been rejected.
          </p>

          <div className="flex items-center gap-5 mt-4">
            <button
              onClick={onCancel}
              type="reset"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDisApproveApplication(param)}
              type="submit"
              className="bg-gold text-white font-bold py-2 px-4 rounded"
            >
              Proceed
            </button>
          </div>
        </div>
      )}
    </>
  );
};

// Main Componenet Page

function JobSeekerSummary() {
  // I want to get all the applications and filter out this user' application
  //  to update the user's application status (Pending, Approved or Rejected)
  const { applications, listing } = useSelector(
    (state) => state?.jobListing?.singleListing
  );
  const [isApproved, setIsApproved] = useState(false);
  const [opened, { open, close }] = useDisclosure(false); //modal controller
  const { id } = useParams(); //jobseeker Id
  const dispatch = useDispatch();
  const { job_seeker, loading } = useSelector((state) => state?.jobSeeker);
  const { company } = useSelector((state) => state?.company.company);

  // get the job_seeker's application from the list of applications
  const jobSeekerApplication = applications.find(
    (application) => application.job_seeker_id === id
  );

  // handle model content
  const handleApproval = () => {
    setIsApproved(true);
    open();
  };

  // handle model content
  const handleDisApproval = () => {
    setIsApproved(false);
    open();
  };

  // handle new notification and update of applicants application
  const handleApproveApplication = (approvalType) => {
    const values = {
      job_seeker_id: id,
      sender_id: listing.company_id,
      meta_data: jobSeekerApplication.id, //the application id
      type:
        approvalType === "accepted"
          ? "job_application_approved"
          : "job_application_rejected",
      message:
        approvalType === "accepted"
          ? `Congratulations!!! we are happy to inform you that <strong className="font-bold text-gray-800">${company.company_name}</strong> has decided to move your application for the position of <strong className="font-bold text-gray-800">${listing?.job_title}</strong> to the next stage`
          : `We regret to inform you that your application for the position of a <strong className="font-bold text-gray-800">${listing?.job_title} </strong> has been rejected by <strong className="font-bold text-gray-800">${company.company_name}</strong>`,
    };
    // send a notification to the user
    dispatch(createNotification(values));
    // update the application status to approved
    dispatch(
      updateJobApplication({
        id: jobSeekerApplication.id,
        values: { status: approvalType },
      })
    );
    close();
  };

  useEffect(() => {
    dispatch(fetchSingleJobSeeker(id));
  }, [id]);

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="flex flex-col p-5 items-center">
        <div className="flex items-center w-2/3 justify-between">
          <div>
            <img
              className="w-32 h-32 rounded-full mt-10 mr-8"
              src={
                job_seeker?.profile_picture
                  ? job_seeker?.profile_picture
                  : profile_img
              }
              alt="Profile"
            />
            <h1 className="text-2xl mt-5">{`${job_seeker?.first_name} ${job_seeker?.last_name}`}</h1>
          </div>
          <div className="flex items-center ">
            <p className="text-sm mr-2">Status:</p>
            <p className="font-bold uppercase">
              {jobSeekerApplication?.status}
            </p>
          </div>
        </div>
        <div className="w-2/3 mt-5">
          <p className="font-bold text-xl">Description</p>
          <div className="border p-4 bg-white rounded mt-3">
            <p className="text-gray-500">
              {parse(job_seeker?.description || "", options)}
            </p>
          </div>
        </div>
        <div className="mt-5 w-2/3 ">
          <p className="font-bold text-xl">Bio</p>
          <div className="border p-4 bg-white rounded mt-3">
            <p>
              <span className="font-bold">Email:</span> {job_seeker?.email}
            </p>
            <p>
              <span className="font-bold">Phone:</span>{" "}
              {job_seeker?.phone_numbers}
            </p>
            <p>
              <span className="font-bold">Location:</span>{" "}
              {`${job_seeker?.city}, ${job_seeker?.state}, ${job_seeker?.country}`}
            </p>
          </div>
        </div>
        <div className="mt-10 w-2/3">
          <h2 className="text-xl font-bold mb-5">Documents</h2>
          <div className="p-4 bg-white rounded border">
            <a
              className="underline text-blue-500"
              href={`https://docs.google.com/viewer?url=${jobSeekerApplication?.resume_url}&embedded=true`}
              target="_blank"
              rel="noreferrer"
            >
              View CV
            </a>
            <a
              className="underline text-blue-500 ml-5"
              href={`https://docs.google.com/viewer?url=${jobSeekerApplication?.cover_letter}&embedded=true`}
              target="_blank"
              rel="noreferrer"
            >
              View Cover Letter
            </a>
            <div className="mt-5">
              {job_seeker.portfolio_website && (
                <a
                  className="underline text-blue-500"
                  href={job_seeker.portfolio_website}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Portfolio
                </a>
              )}
            </div>
            <div className="flex gap-5 mt-4">
              <button className="flex items-center bg-gold text-white font-bold py-2 px-4 rounded ">
                <FaDownload className="mr-2" />
                <a
                  className=""
                  href={jobSeekerApplication?.resume_url}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Download Resume
                </a>
              </button>
              <button className="flex items-center bg-gold text-white font-bold py-2 px-4 rounded ">
                <FaDownload className="mr-2" />
                <a
                  className=""
                  href={jobSeekerApplication?.cover_letter}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Download Cover Letter
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-5 w-2/3">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-4"
            onClick={handleDisApproval}
          >
            Cancel Application
          </button>
          <button
            onClick={handleApproval}
            className="bg-gold text-white font-bold py-2 px-4 rounded"
          >
            Proceed with Application
          </button>
        </div>
      </div>
      <Modal opened={opened} onClose={close} title="Applicant Approval">
        <ApprovalApplication
          onCancel={close}
          isApproved={isApproved}
          handleApproveApplication={() => handleApproveApplication("accepted")}
          handleDisApproveApplication={() =>
            handleApproveApplication("rejected")
          }
        />
      </Modal>
    </>
  );
}

export default JobSeekerSummary;
