import api from "./interceptor";
import { BASE_URL } from "../constants";

// Fetch all documents
export const fetchDocumentApi = async ({
  company_id = null,
  jobseeker_id = null,
  token,
}) => {
  try {
    const params = {};
    if (company_id) {
      params.company_id = company_id;
    }
    if (jobseeker_id) {
      params.jobseeker_id = jobseeker_id;
    }
    const response = await api.get(`${BASE_URL}/documents/`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch single document
export const fetchSingleDocumentApi = async (id) => {
  try {
    const response = await api.get(`${BASE_URL}/documents/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a new document
export const createDocumentApi = async (values) => {
  try {
    const response = api.post(`${BASE_URL}/documents/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update a document
export const updateDocumentApi = async (id, values) => {
  try {
    const response = api.put(`${BASE_URL}/documents/${id}`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Delete a document
export const deleteDocumentApi = async (id) => {
  try {
    const response = await api.delete(`${BASE_URL}/documents/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
