import React from "react";
import { FaTrash } from "react-icons/fa";
import { formatDistanceToNow } from "date-fns";
import { labelBackground } from "../utils/labelColors";
import parse from "html-react-parser";

const NotificationCard = ({
  message,
  type,
  created_at: date,
  is_read,
  onRead,
  onDelete,
  customClass,
}) => {
  const timeAgo = formatDistanceToNow(new Date(date), { addSuffix: true });

  const label_bg = labelBackground(type);
  return (
    <div
      className={`border border-bottom mb-4 overflow-hidden p-4 max-w-[700px] min-h-[100px] max-sm:w-full rounded ${
        is_read ? "bg-gray-200" : "bg-white"
      } shadow ${customClass}`}
    >
      <div className="flex text-gray-500 max-sm:flex-col">
        <div className="flex-1 mr-4 max-sm:mr-0 flex-wrap">
          <div className="flex items-center">
            <span
              className={`capitalize text-sm text-gray-800 font-semibold ${label_bg} py-1 px-2 rounded rounded-sm`}
            >
              {type.replace(/_/g, " ")}
            </span>
            <span className="h-[8px] w-[8px] rounded-full bg-gray-500 mx-4 "></span>
            <p
              onClick={onRead}
              className={`${
                is_read
                  ? "text-gray-500"
                  : "text-blue-600 underline cursor-pointer"
              }`}
            >{`${is_read ? "read" : "mark as read"}`}</p>
          </div>
          <p className="mt-2 overflow-auto break-words">{parse(message)}</p>
        </div>
        <div className="flex flex-col items-end gap-4 max-sm:flex-row max-sm:justify-between">
          <span className="text-sm text-gray-400">{timeAgo}</span>
          <FaTrash
            className="cursor-pointer hover:text-gray-700"
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
