import getUserType from "../utils/getUser";
import CompanyProfileDetails from "./CompanyProfileDetails";
import JobSeekerProfileDetails from "./JobSeekerProfileDetails";

const ProfileDetails = () => {
  const userType = getUserType();
  return (
    <>
      {userType === "company" ? (
        <CompanyProfileDetails />
      ) : (
        <JobSeekerProfileDetails />
      )}
    </>
  );
};

export default ProfileDetails;
