// import React from "react";
import DashboardStatsGrid from "./DashboardStatsGrid";
import TransactionCharts from "./TransactionCharts";
import ApplicantProfileChart from "./ApplicantProfileChart";
import {
  FaGem,
  FaHourglassStart,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import useFetchChartData from "../hooks/useChartData";

const JobSeekerDashboard = () => {
  const { applications } = useSelector((state) => state.jobSeeker);
  const chartData = useFetchChartData(applications);

  const applicationAccepted = applications?.filter(
    (application) => application.status === "accepted"
  );

  const applicationRejected = applications?.filter(
    (application) => application.status === "rejected"
  );

  const applicationPending = applications?.filter(
    (application) => application.status === "pending"
  );

  const data = [
    {
      title: "Total Applications",
      Icon: FaGem,
      numbers: applications?.length,
    },
    {
      title: "Total Application Accepted",
      Icon: FaCheckCircle,
      numbers: applicationAccepted?.length,
    },
    {
      title: "Total Application Rejected",
      Icon: FaTimesCircle,
      numbers: applicationRejected?.length,
    },
    {
      title: "Total Application Pending",
      Icon: FaHourglassStart,
      numbers: applicationPending?.length,
    },
  ];

  // pie-chart data
  const pieChartData = [
    { name: "Accepted", value: applicationAccepted?.length },
    { name: "Rejected", value: applicationRejected?.length },
    { name: "Pending", value: applicationPending?.length },
    ,
  ];
  return (
    <div className="p-5 flex gap-4 flex-col max-sm:p-2  max-sm:w-full">
      <DashboardStatsGrid data={data} />
      <div className="flex flex-row gap-4 w-full max-sm:flex-col">
        <TransactionCharts chartsData={chartData} />
        <ApplicantProfileChart pieChartData={pieChartData} />
      </div>
    </div>
  );
};

export default JobSeekerDashboard;
