import React, { useState, useEffect, useRef } from "react";
import Countdown from "react-countdown";
import OTPInput from "react-otp-input";
import { FaExclamationTriangle } from "react-icons/fa";

// Show Modal when the user clicks on the Change Password button
const OtpModal = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  requestOtp,
}) => {
  const [initialDate, setInitialDate] = useState(null);
  const [countdownKey, setCountdownKey] = useState(0);
  const [otp, setOtp] = useState("");
  const otpRef = useRef(null);

  // count down memo
  const CountdownMemo = React.memo(({ countdownKey, renderer, date }) => (
    <Countdown date={date} renderer={renderer} key={countdownKey} />
  ));

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <button
          className="text-blue-500 hover:text-blue-700"
          onClick={requestOtp}
        >
          request a new OTP
        </button>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  // Set the initial date when the component is first mounted
  useEffect(() => {
    setInitialDate(Date.now() + 60 * 1000);
  }, []);

  return (
    <main className=" p-2">
      <p className="mt-8 text-center text-2xl semibold">
        Kindly provide the One Time Password (OTP) sent to your mail
      </p>
      <div className="bg-gray-100 w-1/5 min-w-[360px] mx-auto p-4 rounded mt-12 h-64  shadow-lg flex flex-col justify-between">
        <OTPInput
          ref={otpRef}
          containerStyle={{
            marginTop: "1rem",
          }}
          value={otp}
          onChange={(otp) => {
            setOtp(otp);
            setFieldValue("otp", otp);
            if (otp.length === 6) {
              // if the length of the otp is equal to the required number of inputs
              handleSubmit(); // submit the form
            }
          }}
          numInputs={6}
          renderSeparator={<span>-</span>}
          inputStyle={{
            width: "2rem",
            height: "2rem",
            margin: "0 .5rem",
            fontSize: "1.5rem",
            borderRadius: "4px",
            border: "1px solid gray",
          }}
          renderInput={(props) => <input {...props} />}
        />

        <div className="text-3x text-center w-full">
          <CountdownMemo
            date={initialDate}
            renderer={renderer}
            key={countdownKey}
          />
        </div>

        <button
          disabled={otp.length < 6}
          onClick={handleSubmit}
          type="submit"
          class={`w-full semi-bold ${
            otp.length < 6 ? "bg-gray-300" : "bg-gold"
          } py-3 text-center text-white rounded-md`}
        >
          {`${isSubmitting ? "Sending OTP" : "Send OTP"}`}
        </button>
      </div>
      <p className="text-center mt-4">
        NB: <span>OPTs are valid for 60 seconds only</span>
      </p>
      <p className="text-center flex items-center justify-center">
        <FaExclamationTriangle className="text-yellow-500 mr-2" />
        Please don't refresh this page
      </p>
    </main>
  );
};

export default OtpModal;
