import React from "react";
import getUserType from "../utils/getUser";
import CompanyListings from "./CompanyListings";
import JobSeekerApplications from "./JobSeekerApplications";

const Applications = () => {
  const userType = getUserType();

  return (
    <>
      {userType === "company" ? <CompanyListings /> : <JobSeekerApplications />}
    </>
  );
};

export default Applications;
