import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreferences } from "../store/slices/preferences";
import { DropdownCheckbox } from "./customFormikEle";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { CountrySelect, StateSelect } from "react-country-state-city";
import {
  fetchJobSeekerPreferences,
  updateJobSeekerPreferences,
  updateJobSeeker,
} from "../store/slices/jobSeeker";
import { defaultCountry } from "../constants";

const JobSeekerPreferenceSettings = () => {
  // Handle Dropdown checkbox state
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]); // for the dropdown checkbox
  const [countryid, setCountryid] = useState(
    defaultCountry ? defaultCountry.id : 0
  );
  const [stateid, setstateid] = useState(0);
  // initilaize the country, state and city objects
  const [countryObject, setCountryObject] = useState("");
  const [stateObject, setStateObject] = useState("");
  const {
    job_seeker,
    loading,
    preferences: current_preferences,
  } = useSelector((state) => state?.jobSeeker);
  const preferences_data = useSelector(
    (state) => state?.preferences?.preferences
  );
  const searchTerm = useSelector((state) => state.searchTerm);

  const preferences = preferences_data.map((preference) => ({
    key: preference.name,
    value: preference.id,
  }));

  const filteredPreferences = preferences.filter((preference) =>
    preference.key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formSchema = Yup.object().shape({
    preference_ids: Yup.array()
      .max(3, "You can select up to 3 options only")
      .required("Required"),
  });

  // All onSubmits

  const onSubmitPreferences = (values, { setSubmitting, resetForm }) => {
    if (values.preference_ids.length < 1) {
      toast.error("Please select at least one preference");
      return;
    }
    dispatch(
      updateJobSeekerPreferences({
        value: values.preference_ids,
        id: job_seeker.id,
        resetForm,
      })
    );
    setIsOpen(false);
    setSubmitting(false);
  };

  // Submit Preffered Vacancy Location
  const onSubmitPrefferedVacancyLocation = ({ setSubmitting }) => {
    if (countryid === 0 || stateid === 0) {
      toast.error("Please select a country and state");
      return;
    }
    const value = {
      prefferred_vacancy_state: ` ${stateObject}, ${countryObject}.`,
    };
    dispatch(
      updateJobSeeker({
        value,
        id: job_seeker.id,
      })
    );
    console.log(value);
    setSubmitting(false);
  };

  // fetch all preferences
  useEffect(() => {
    dispatch(fetchPreferences());
  }, []);

  // fetch jobseeker's preferences
  useEffect(() => {
    dispatch(fetchJobSeekerPreferences(job_seeker.id));
  }, [job_seeker.id]);

  return (
    <>
      <div className="flex gap-10 flex-wrap max-sm:flex-col max-sm:w-full">
        <Formik
          initialValues={{
            preference_ids: [],
          }}
          onSubmit={onSubmitPreferences}
          validationSchema={formSchema}
          validate={(values) => {
            try {
              formSchema.validateSync(values, { abortEarly: false });
            } catch (error) {
              error.inner.forEach((err) => {
                toast.error(err.message);
              });
            }
          }}
        >
          {() => (
            <Form className="max-sm:w-full">
              <div className="bg-white inline-block p-4 rounded shadow max-sm:w-full">
                <p>Select Industry Prefrences (Maximum of 3 preferences)</p>
                <div className="flex gap-5 items-start my-2 max-sm:w-full max-sm:flex-col">
                  <div className="w-[400px] h-full max-sm:w-full">
                    <DropdownCheckbox
                      label="Select Three (3) Options only"
                      name="preference_ids"
                      options={filteredPreferences}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      toggleDropdown={toggleDropdown}
                      search={true}
                      isOpen={isOpen}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-gold text-white font-bold rounded px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <p>You current preferences are:</p>
                <ul>
                  {current_preferences.map((preference) => (
                    <li key={preference.id}>{preference.name}</li>
                  ))}
                </ul>
              </div>
            </Form>
          )}
        </Formik>

        {/*  */}
        <Formik
          initialValues={{
            preferences: [],
          }}
          onSubmit={onSubmitPreferences}
          validationSchema={formSchema}
          validate={(values) => {
            try {
              formSchema.validateSync(values, { abortEarly: false });
            } catch (error) {
              error.inner.forEach((err) => {
                toast.error(err.message);
              });
            }
          }}
        >
          {() => (
            <Form className="max-sm:w-full">
              <div className="bg-white inline-block p-4 rounded shadow max-sm:w-full">
                <p>
                  Select Job Position Preferences (Maximum of 3 preferences)
                </p>
                <div className="flex gap-5 items-start my-2 max-sm:flex-col">
                  <div className="w-[400px] h-full max-sm:w-full">
                    <DropdownCheckbox
                      label="Select Three (3) Options only"
                      name="preferences"
                      options={preferences}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-gold text-white font-bold rounded px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <p>You current preferences are:</p>
                <ul>
                  <li>List 1</li>
                  <li>List 2</li>
                  <li>List 3</li>
                </ul>
              </div>
            </Form>
          )}
        </Formik>

        {/*  */}
        <Formik
          initialValues={{
            preffered_vacancy_state: "",
          }}
          onSubmit={onSubmitPrefferedVacancyLocation}
        >
          {() => (
            <Form className="max-sm:w-full">
              <div className="bg-white inline-block p-4 rounded shadow">
                <p>Select preferred job vacancy country and state</p>
                <div className="flex gap-5 items-start my-2 max-sm:w-full max-sm:flex-col">
                  <div className="flex gap-10  justify justify-between w-[400px] max-sm:w-full">
                    <div className="w-full">
                      <CountrySelect
                        defaultValue={defaultCountry}
                        inputClassName="focus:outline-gold"
                        autoComplete="false"
                        containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md"
                        onChange={(e) => {
                          setCountryid(e.id);
                          setCountryObject(e.name);
                        }}
                        placeHolder={"Select Country"}
                      />

                      <StateSelect
                        inputClassName="focus:outline-gold"
                        containerClassName="w-full text-gray-800 mb-4 border border-gray-400 rounded-md"
                        autoComplete="false"
                        countryid={countryid}
                        onChange={(e) => {
                          setstateid(e.id);
                          setStateObject(e.name);
                        }}
                        placeHolder="Select State"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bg-gold text-white font-bold rounded px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <p>
                  Current preffered Vacancy location:{" "}
                  <span>{job_seeker?.prefferred_vacancy_state}</span>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default JobSeekerPreferenceSettings;
